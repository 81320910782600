import React from "react";
import styled from "styled-components";
import A from "../A";
import publicPath from "../../helpers/publicPath";

const LogoWrapper = styled.div`
  flex: none;
  width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: ${(props) => props.height};
  min-height: ${(props) => props.height};
`;

const Img = styled.img`
  width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: ${(props) => props.height};
  min-height: ${(props) => props.height};
`;

const Logo = React.memo(({ width, alt, img, link = null, height = null }) => {
  return (
    <LogoWrapper width={width} height={height}>
      {link && (
        <A to={link}>
          <Img src={publicPath(img)} alt={alt} width={width} height={height} />
        </A>
      )}
      {!link && (
        <Img src={publicPath(img)} alt={alt} width={width} height={height} />
      )}
    </LogoWrapper>
  );
});

export default Logo;
