import styled from "styled-components";
import publicPath from "../../helpers/publicPath";
import palette from "../../styles/palette";
import IconTooth from "./IconTooth";

const Quantity = styled.div`
  font-size: 1.3em;
`;

const WrapperProducts = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 0.2em;
  align-items: center;
  justify-content: center;
  color: ${palette.primary.contrastText};
  background: radial-gradient(circle at top left, #0f7534, #0a5124);
  border-radius: 3px;
  filter: drop-shadow(2px 4px 6px black);
`;

const Header = styled.div`
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: linear-gradient(#0a5124, #073b1a);
  width: 100%;
  border-bottom: 5px double green;
  padding: 0.4rem 0;
`;

const Content = styled.div`
  flex: 1;
  margin: 0.5rem 0;
  padding: 0 0.3rem;
  & > img {
    border: 2px solid #3c8554;
    border-style: inset;
  }
`;

const Footer = styled.div`
  flex: none;
  width: 100%;
  text-align: center;
  padding-bottom: 5%;
  & > button {
    font-size: 1.3rem;
    width: 90%;
  }
`;

const IMAGE_SIZE = 100;
function Product({ product, children }) {
  const src = publicPath(`tile/shop/${product.img}`);
  return (
    <WrapperProducts>
      <Header>
        <IconTooth />
        <Quantity>{product.teeth}</Quantity>
      </Header>
      <Content>
        <img
          src={src}
          alt={product.value}
          width={`${IMAGE_SIZE}px`}
          height={`${IMAGE_SIZE}px`}
        />
      </Content>
      {children && <Footer>{children}</Footer>}
    </WrapperProducts>
  );
}

export default Product;
