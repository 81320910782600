import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import publicPath from "../../helpers/publicPath";
import palette from "../../styles/palette";
import { RARITY } from "enefel/item";
import isDev from "../../helpers/devDetect";

const SIZE = 32;

const Wrapper = styled.div`
  padding: 0.5rem;
  background: linear-gradient(${palette.primary.main}, ${palette.primary.dark});
  border: 1px solid white;
  border-radius: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const Title = styled.div``;

const Quantity = styled.div`
  font-size: 0.8em;
`;

const Description = styled.div`
  padding: 0.5rem;
  padding-bottom: 1rem;
  font-size: 0.8rem;
  font-style: italic;
  white-space: pre-line;
  text-align: left;
`;

const colorRarity = {
  [RARITY.COMMUN]: "#D8D9D9",
  [RARITY.UNCOMMUN]: "#91D04F",
  [RARITY.RARE]: "#03B0EF",
  [RARITY.EPIC]: "#FE00FE",
  [RARITY.LEGENDARY]: "#FF9900",
};
// https://stackoverflow.com/a/50301296/2175806
const Item = styled.div`
  background: url("${publicPath(`tile/store/items.png`)}") -${(props) =>
      SIZE * props.x}px -${(props) => SIZE * props.y}px;
  left: ${SIZE * 2}px;
  width: ${SIZE}px;
  height: ${SIZE}px;
  outline: 2px solid ${(props) => colorRarity[props.rarity]};
  border-radius: 2px;
  /* transform: scale(2.5);
  transform-origin: left top; */
`;
const IconItem = React.memo(({ playerItem, item, onClick, children }) => {
  const { t } = useTranslation("item");

  return (
    <Wrapper rarity={item.rarity} onClick={onClick}>
      <Header>
        <Item x={item.x} y={item.y} rarity={item.rarity} />
        <Title>{t("item:" + item.id, "__" + item.id)}</Title>
        {item.maxQuantity > 1 && playerItem && (
          <Quantity>
            ({playerItem.quantity}/{item.maxQuantity})
          </Quantity>
        )}
      </Header>
      <Description>
        {t("item:" + item.id + " description", "__" + item.id + " description")}
        {isDev() && playerItem && (
          <>
            <br />
            <br />
            <br />
            <span>{playerItem.id}</span>
          </>
        )}
      </Description>
      <div>{children}</div>
    </Wrapper>
  );
});

export default IconItem;
