import { getRandomNumber } from "enefel/random";
import React from "react";
import styled from "styled-components/macro";
import { TILE_SIZE } from "../Game/Stadium/Stadium";
import IconDugout from "../Icon/dugout/IconDugout";
import alea from "seedrandom/lib/alea";

export const DugoutWrapper = styled.div`
  background: black;
  height: ${(props) => props.size * 2 + "px"};
  position: relative;
  width: ${(props) => props.width + "px"};
`;

const TileStyle = styled.div`
  display: inline-block;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  text-align: center;
  z-index: 0;
  transform: ${(props) => {
    return props.rot === 1
      ? "rotateZ(0deg)"
      : props.rot === 2
      ? "rotateZ(90deg)"
      : props.rot === 3
      ? "rotateZ(180deg)"
      : "rotateZ(-90deg)";
  }};
`;

const TilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  &::after {
    box-shadow: inset 3px 3px 13px 5px #000000;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const DisplayDugout = React.memo(({ team, width, height, side }) => {
  const r = team ? new alea(`${team.id}-${side}`) : new alea(`empty-${side}`);
  let seed = side;
  function random() {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }
  const VARIATION = 5;
  let tiles = [];
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      let tile = Math.ceil((random() * 10) % VARIATION);

      tiles.push(
        <TileStyle
          data-dugout-position={x + "-" + y}
          key={"tile-dugout-" + x + "-" + y}
          size={TILE_SIZE + "px"}
          rot={getRandomNumber(1, 4, r)}
        >
          <IconDugout name={"stone" + tile} />
        </TileStyle>
      );
    }
  }
  return (
    <TilesWrapper>
      {/* {team?.rerollCoach > 0 && (
        <RerollCoach side={side} quantity={team?.rerollCoach} />
      )}   */}
      {tiles}
    </TilesWrapper>
  );
});

export default DisplayDugout;
