import React from "react";
import { useTranslation } from "react-i18next";

const KO = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      {t("t:KOTitle", "__KOTitle")}
      <br />
      {t("t:KODescription", "__KODescription")}
    </>
  );
});
export default KO;
