import { useQuery } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import { isGameRunning } from "enefel";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { GET_GAME_ITEMS } from "../../../query/GameQueries";
import QueryError from "../../Error/QueryError";
import IconStore from "../../Icon/store/IconStore";
import Loading from "../../Loading";
import { RootContext } from "../../RootContext";
import { currentGameState } from "../atoms/gameState";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import StorePopup from "./StorePopup";

const StoreWrapper = styled.div`
  left: calc(50% - 40px);
  position: absolute;
  text-align: center;
  top: 35%;
  width: 20px;
`;

const ButtonWrapper = styled.span`
  cursor: pointer;
`;

const Store = React.memo(({ team }) => {
  const { t } = useTranslation();
  const { currentPlayer } = useContext(RootContext);
  const game = useRecoilValue(currentGameState);

  const { loading, error, data } = useQuery(GET_GAME_ITEMS, {
    variables: {
      id: game.id,
      playerId: currentPlayer?.id || null,
    },
  });
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBuy = () => {
    handleClose();
    handleOpenSnackbar();
  };

  if (!isGameRunning(game)) {
    return null;
  }

  if (!currentPlayer) {
    return null;
  }
  if (loading) return <Loading />;
  if (error) return <QueryError error={error} />;
  const items = data.game.items;

  return (
    <StoreWrapper>
      <ButtonWrapper onClick={handleClickOpen}>
        <IconStore level={game.storeSize} />
      </ButtonWrapper>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
        onClose={handleClose}
        aria-labelledby="game store"
        open={open}
        maxWidth={false}
      >
        <StorePopup
          team={team}
          items={items}
          handleClose={handleClose}
          handleBuy={handleBuy}
        />
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("t:You bought an item", "__You bought an item")}
        </Alert>
      </Snackbar>
    </StoreWrapper>
  );
});
export default Store;
