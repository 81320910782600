import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FiLogOut } from "react-icons/fi";
import { ImUser } from "react-icons/im";
import styled from "styled-components/macro";
import { paths } from "../../path";
import A from "../A";
import CustomTooltip from "../Interface/Tooltip";
import PlayerLinkFromId from "../Player/PlayerLinkFromId";
import { RootContext } from "../RootContext";
import ToothDisplay from "../User/Tooth/ToothDisplay";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > :first-child {
    color: white;
    font-size: 1.8em;
    opacity: 0.7;
  }
`;

const MenuSignin = React.memo(
  ({ user, onLogoutClick, onClick = null, isActive = true }) => {
    const { t } = useTranslation();
    const { currentPlayerId } = useContext(RootContext);

    return (
      <>
        <ToothDisplay tooth={user.tooth} />
        <A
          to={paths.myUser}
          big={true}
          colorContrast={"primary"}
          onClick={onClick}
          tabIndex={isActive ? null : -1}
        >
          <Wrapper>
            <span>
              <ImUser />
            </span>
            <span>{user.username}</span>
          </Wrapper>
        </A>
        {currentPlayerId && (
          <PlayerLinkFromId
            playerId={currentPlayerId}
            big={true}
            colorContrast={"primary"}
            isActive={isActive}
            onClick={onClick}
          />
        )}
        {!currentPlayerId && (
          <A
            to={paths.myUser}
            big={true}
            colorContrast={"error"}
            onClick={onClick}
            tabIndex={isActive ? null : -1}
          >
            {t("t:noPlayerWarning", "__noPlayerWarning")}
          </A>
        )}
        <A
          to={paths.login}
          big={true}
          colorContrast={"primary"}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            onLogoutClick();
          }}
          tabIndex={isActive ? null : -1}
        >
          <CustomTooltip placement="top" title={t("t:logout", "__Logout")}>
            <span>
              <FiLogOut size={"1.5em"} />
            </span>
          </CustomTooltip>
        </A>
      </>
    );
  }
);

export default MenuSignin;
