import { Nav } from "./Nav";
import { paths } from "../../path";
import { RootContext } from "../RootContext";
import { useApolloClient } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import A from "../A";
import MenuGuest from "./MenuGuest";
import MenuSignin from "./MenuSignin";
import React, { useContext } from "react";
import { NavLogin } from "./Nav";
import styled from "styled-components";
import Unread from "../Team/Chat/Unread";
import Donation from "../Interface/Donation";
import SubMenu from "./SubMenu";

export const ExternalLink = styled.a`
  align-items: baseline;
  color: ${(props) => (props.color ? props.color : "white")};
  display: inline-flex;
  font-weight: bold;
  opacity: 0.8;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.3s ease;

  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: underline;
  }
`;
export const Align = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

const Menu = React.memo(
  ({ user, onClick = null, isActive = true, isHamb = false }) => {
    const { setUserToken, currentPlayerId, currentPlayer } =
      useContext(RootContext);
    const { t } = useTranslation();
    const history = useHistory();

    const client = useApolloClient();

    function signOut() {
      setUserToken(null);
      client.clearStore();
      history.push(paths.login);
    }

    return (
      <>
        <Nav>
          <A
            to={paths.players}
            big={true}
            colorContrast={"primary"}
            onClick={onClick}
            tabIndex={isActive ? null : -1}
          >
            {t("t:players", "__Players")}
          </A>
          <A
            to={paths.teams}
            big={true}
            colorContrast={"primary"}
            onClick={onClick}
            tabIndex={isActive ? null : -1}
          >
            {t("t:teams", "__Teams")}
          </A>
          <A
            to={paths.games}
            big={true}
            colorContrast={"primary"}
            onClick={onClick}
            tabIndex={isActive ? null : -1}
          >
            {t("t:games", "__Games")}
          </A>
          <A
            to={paths.standings}
            big={true}
            colorContrast={"primary"}
            onClick={onClick}
            tabIndex={isActive ? null : -1}
          >
            {t("t:standings", "__standings")}
          </A>
          {isHamb && (
            <>
              <A
                to={paths.skills}
                big={true}
                colorContrast={"primary"}
                onClick={onClick}
                tabIndex={isActive ? null : -1}
              >
                {t("t:Skills", "__Skills")}
              </A>
              <A
                to={paths.careers}
                big={true}
                colorContrast={"primary"}
                onClick={onClick}
                tabIndex={isActive ? null : -1}
              >
                {t("t:Careers", "__Careers")}
              </A>
              <A
                to={paths.hallOfFame}
                big={true}
                colorContrast={"primary"}
                onClick={onClick}
                tabIndex={isActive ? null : -1}
              >
                {t("t:hall of fame", "__hall of fame")}
              </A>
              {currentPlayerId && (
                <A
                  to={paths.chats}
                  big={true}
                  colorContrast={"primary"}
                  onClick={onClick}
                  tabIndex={isActive ? null : -1}
                >
                  <Align>
                    <span>{t("t:Chats", "__Chats")}</span>
                    {currentPlayer &&
                      currentPlayer.chats &&
                      currentPlayer.chats.some(
                        (chat) => chat.hasNewMessages
                      ) && <Unread />}
                  </Align>
                </A>
              )}
              {/* <ExternalLink
                href={t("t:rule url", "__rule url")}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                {t("t:wiki", "__wiki")}
              </ExternalLink>{" "} */}
            </>
          )}

          {!isHamb && <SubMenu currentPlayer={currentPlayer} />}
        </Nav>
        <NavLogin>
          <Donation />
          {user ? (
            <MenuSignin
              user={user}
              onLogoutClick={signOut}
              isActive={isActive}
              onClick={onClick}
            />
          ) : (
            <MenuGuest isActive={isActive} onClick={onClick} />
          )}
        </NavLogin>
      </>
    );
  }
);

export default Menu;
