import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import IconItem from "../../Icon/IconItem";
import { useMutation } from "@apollo/client";
import { BUY_GAME_ITEMS } from "../../../query/GameQueries";
import ButtonConfirm from "../../Interface/ButtonConfirm";
import { RootContext } from "../../RootContext";
import IconCoin from "../../Icon/IconCoin";
import styled from "styled-components";

const Center = styled.div`
  align-items: center;
  display: flex;
  gap: 1em;
  justify-content: space-between;
`;

const Item = React.memo(({ item, player, onBuy }) => {
  const { t } = useTranslation();
  const { updateCurrentPlayer } = useContext(RootContext);

  const [buyItem, { loading, error }] = useMutation(BUY_GAME_ITEMS, {
    variables: {
      playerId: player.id,
      itemId: item.id,
      price: item.price,
    },
  });

  const handleClickItem = (player) => {
    buyItem().then(({ data }) => {
      let findPlayer = data.buyItem.team1.players.find(
        (p) => p.id === player.id
      );
      if (!findPlayer) {
        findPlayer = data.buyItem.team2.players.find((p) => p.id === player.id);
      }
      if (findPlayer) {
        updateCurrentPlayer(findPlayer);
      }
      onBuy();
    });
  };

  return (
    <IconItem item={item}>
      <ButtonConfirm
        disabled={!(player && player.po >= item.price)}
        error={error}
        loading={loading}
        onAction={() => handleClickItem(player)}
        txt={
          <Center>
            <span>{t("t:Buy for", "__Buy for ")}</span>
            <IconCoin value={item.price} />
          </Center>
        }
        txtConfirm={t("t:Sure?", "__Sure?")}
        warningStyle={false}
      />
    </IconItem>
  );
});
export default Item;
