import React from "react";

const Day = ({ children, withHours = false }) => {
  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  if (withHours) {
    options = {
      ...options,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
  }
  return <span>{new Date(children).toLocaleString(undefined, options)}</span>;
};

export default Day;
