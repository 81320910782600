import React from "react";
import Moment from "react-moment";
import { getFromStore, STORE_KEYS } from "../../storage/Storage";
import CustomTooltip from "../Interface/Tooltip";
import Day from "./Day";

const DateFrom = ({ children, add, title = null }) => {
  if (!children) {
    return null;
  }
  const locale = getFromStore(STORE_KEYS.LANGUAGE) || "en";

  return (
    <>
      <CustomTooltip placement="top" title={<Day withHours>{children}</Day>}>
        <div>
          {title && title + ": "}
          <Moment fromNow add={add} locale={locale}>
            {children}
          </Moment>
        </div>
      </CustomTooltip>
    </>
  );
};

export default DateFrom;
