import React from "react";
import styled from "styled-components";
import IconCoin from "./IconCoin";
import CustomTooltip from "../Interface/Tooltip";
import { useTranslation } from "react-i18next";

const Center = styled.div`
  display: flex;
  align-items: center;

  & > * {
    &:first-child {
      margin-right: 0.2rem;
    }
  }
`;

const IconCoinBig = React.memo(({ po = 0 }) => {
  const { t } = useTranslation();
  return (
    <Center>
      <CustomTooltip placement="top" title={t("t:Coin", "__Coin")}>
        <span>
          <IconCoin />
        </span>
      </CustomTooltip>{" "}
      <span>{po}</span>
    </Center>
  );
});

export default IconCoinBig;
