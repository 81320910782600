import React, { useEffect, useState } from "react";
import { isOnField } from "enefel";
import { TILE_SIZE } from "../Stadium";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { currentGameState } from "../../atoms/gameState";
import CustomTooltip from "../../../Interface/Tooltip";
import { complement } from "polished";
import { hasStatusTacleZone } from "enefel/status";

const TackleZoneButtonWrapper = styled.div`
  background-color: ${(props) => "#" + props.color};
  border: 1px solid ${(props) => "#" + props.border};
  cursor: pointer;
  height: ${(props) => props.size * 0.6 + "px"};
  opacity: ${(props) => (props.isShow ? 1 : 0.3)};
  width: ${(props) => props.size * 0.6 + "px"};
`;

export const ShowTackleButton = React.memo(
  ({ onChangeShowTZ, side, isShow, color, border }) => {
    const { t } = useTranslation();
    const [showTZ, setShowTZ] = useState(isShow);

    useEffect(() => {
      onChangeShowTZ(showTZ, side);
    }, [showTZ, side, onChangeShowTZ]);

    const onClick = () => {
      setShowTZ(!showTZ);
    };
    const title = showTZ
      ? t("t:HideTackleZones", "__HideTackleZones")
      : t("t:ShowTackleZones", "__ShowTackleZones");

    return (
      <CustomTooltip placement="top" title={title}>
        <TackleZoneButtonWrapper
          border={border}
          color={color}
          isShow={isShow}
          onClick={onClick}
          size={TILE_SIZE}
        ></TackleZoneButtonWrapper>
      </CustomTooltip>
    );
  }
);

const TackleZoneWrapper = styled.div`
  background-color: ${(props) => "#" + props.color};
  /* outline: 1px solid ${(props) => complement("#" + props.color)}; */
  height: ${(props) => props.size + "px"};
  left: ${(props) => props.x * props.size + "px"};
  opacity: 0.3;
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  transition: top 0.2s ease, left 0.2s ease;
  width: ${(props) => props.size + "px"};
  z-index: 0;
`;
const TackeZone = React.memo(({ player, side, color }) => {
  const game = useRecoilValue(currentGameState);
  const displayTZ = () => {
    const tz = [];
    for (let y = player.y - 1; y <= player.y + 1; ++y) {
      for (let x = player.x - 1; x <= player.x + 1; ++x) {
        if (
          !(y === player.y && x === player.x) &&
          isOnField(x, y, game.stadium_width, game.stadium_height)
        ) {
          tz.push(
            <TackleZoneWrapper
              key={x + "-" + y + "-" + player.id}
              x={x}
              y={y}
              size={TILE_SIZE}
              color={color}
              data-tz-position={x + "-" + y}
              data-tz-side={side}
            />
          );
        }
      }
    }
    return tz;
  };
  return displayTZ();
});

const TackeZones = React.memo(({ players, side, color }) => {
  return players.map((player) => {
    return hasStatusTacleZone(player) ? (
      <TackeZone key={player.id} player={player} side={side} color={color} />
    ) : null;
  });
});
export default TackeZones;
