import React from "react";
import styled from "styled-components";
import { TILE_SIZE } from "../../Game/Stadium/Stadium";
import publicPath from "../../../helpers/publicPath";

// TODO: can vary to brown
const Wrapper = styled.div`
  display: inline-flex;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  background: ${(props) =>
    `url("${publicPath(`tile/dugout/${props.name}.svg`)}")`};
  background-size: ${(props) => props.size} ${(props) => props.size};
`;
const IconDugout = React.memo(({ name, size = TILE_SIZE + "px" }) => {
  return <Wrapper size={size} name={name}></Wrapper>;
});

export default IconDugout;
