import "./wdyr";
import "./i18n";
import "moment/locale/fr";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { paths } from "./path";
import { RecoilRoot } from "recoil";
import { setPageTitle } from "./helpers/browser";
// TODO: cause refresh problem
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import * as serviceWorker from "./serviceWorker";
import apolloClient from "./apollo";
import React, { Suspense, lazy } from "react";
import Layout from "./component/Layout";
import Loading from "./component/Loading";
import PrivateRoute from "./component/PrivateRoute";
import RootContext from "./component/RootContext";
import theme from "./styles/theme";
import GlobalStyle from "./styles/global";
import InjuryReport from "./component/InjuryReport/InjuryReport";
import Transactions from "./component/Transaction/Transactions";
import HallOfFame from "./component/HallOfFame/HallOfFame";
import { createRoot } from "react-dom/client";

import Store from "./component/Store/StoreShow";
import { ThemeProvider } from "@mui/material/styles";
import Charts from "./component/Chart/Charts";
import Migration from "./component/Migration";

const AvatarsDetault = lazy(() => import("./component/Career/AvatarsDetault"));
const Avatars = lazy(() => import("./component/Career/Avatars"));
const Careers = lazy(() => import("./component/Career/Careers"));
const ChangePassword = lazy(() => import("./component/Auth/ChangePassword"));
const Donate = lazy(() => import("./component/Donate/Donate"));
const ForgotPassword = lazy(() => import("./component/Auth/ForgotPassword"));
const GameIndex = lazy(() => import("./component/Game/GameIndex"));
const GameShow = lazy(() => import("./component/Game/GameShow"));
const Home = lazy(() => import("./component/Home/Home"));
const Login = lazy(() => import("./component/Auth/Login"));
const PlayerResume = lazy(() => import("./component/Player/PlayerResume"));
const PlayerCreate = lazy(() => import("./component/Player/PlayerCreate"));
const PlayerIndex = lazy(() => import("./component/Player/PlayerIndex"));
const PlayerShow = lazy(() => import("./component/Player/PlayerShow"));
const Signup = lazy(() => import("./component/Auth/Signup"));
const SkillIndex = lazy(() => import("./component/Skill/SkillIndex"));
const StandingIndex = lazy(() => import("./component/Standing/StandingIndex"));
const Stats = lazy(() => import("./component/Stats/Stats"));
const TeamCreate = lazy(() => import("./component/Team/TeamCreate"));
const TeamIndex = lazy(() => import("./component/Team/TeamIndex"));
const TeamShow = lazy(() => import("./component/Team/TeamShow"));
const TeamStadium = lazy(() => import("./component/Stadium/TeamStadium"));
const UserShow = lazy(() => import("./component/User/UserShow"));
const Chats = lazy(() => import("./component/Team/Chat/ChatsPlayer"));
const Chat = lazy(() => import("./component/Team/Chat/Chat"));
const Cards = lazy(() => import("./component/Card/Cards"));
const UserCards = lazy(() => import("./component/Card/UserCards"));
const Version = lazy(() => import("./component/Version"));
const PlayersLast = lazy(() => import("./component/Player/PlayersLast"));

setPageTitle();
const Root = () => {
  const hasMigration = false;
  return (
    <React.StrictMode>
      <ApolloProvider client={apolloClient}>
        <RootContext>
          <GlobalStyle />
          <Router>
            <RecoilRoot>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Layout hasMigration={hasMigration}>
                    {hasMigration ? (
                      <Migration />
                    ) : (
                      <Suspense fallback={<Loading />}>
                        <Switch>
                          <Route
                            path={paths.lastPlayers}
                            exact
                            component={PlayersLast}
                          />
                          <Route
                            path={paths.version}
                            exact
                            component={Version}
                          />
                          <Route path={paths.charts} exact component={Charts} />
                          <Route path={paths.cards} exact component={Cards} />
                          <Route
                            path={paths.avatars}
                            exact
                            component={Avatars}
                          />
                          <Route
                            path={paths.avatarsDefault}
                            exact
                            component={AvatarsDetault}
                          />
                          <Route
                            path={paths.stadium + "/:teamId"}
                            exact
                            component={TeamStadium}
                          />
                          <Route
                            path={paths.standings}
                            exact
                            component={StandingIndex}
                          />
                          <Route
                            path={paths.injuryReport}
                            exact
                            component={InjuryReport}
                          />
                          <Route
                            path={paths.transactions}
                            exact
                            component={Transactions}
                          />
                          <Route
                            path={paths.hallOfFame}
                            exact
                            component={HallOfFame}
                          />
                          <Route path={paths.chats} exact component={Chats} />
                          <Route path={paths.stats} exact component={Stats} />
                          <Route
                            path={paths.careers}
                            exact
                            component={Careers}
                          />
                          <Route path={paths.index} exact component={Home} />
                          <Route path={paths.donate} exact component={Donate} />

                          <Route path={paths.signup} exact component={Signup} />
                          <Route path={paths.login} exact component={Login} />
                          <Route
                            path={paths.changePassword + "/:token"}
                            exact
                            component={ChangePassword}
                          />
                          <Route
                            path={paths.forgotPassword}
                            exact
                            component={ForgotPassword}
                          />
                          <Route
                            path={paths.resetPassword}
                            exact
                            render={() => <ForgotPassword reset={true} />}
                          />
                          <Route
                            path={paths.players}
                            exact
                            component={PlayerIndex}
                          />
                          <Route
                            path={paths.playerResume}
                            exact
                            component={PlayerResume}
                          />
                          <Route
                            path={paths.games}
                            exact
                            component={GameIndex}
                          />
                          <Route
                            path={paths.gamesTab}
                            exact
                            component={GameIndex}
                          />
                          <Route
                            path={paths.gameShow + "/:id"}
                            exact
                            component={GameShow}
                          />
                          <Route
                            path={paths.playerShow + "/:id"}
                            exact
                            component={PlayerShow}
                          />
                          <Route
                            path={paths.teams}
                            exact
                            component={TeamIndex}
                          />
                          <Route
                            path={
                              paths.teamShow +
                              "/:id" +
                              paths.teamChat +
                              "/:chatId"
                            }
                            exact
                            component={Chat}
                          />
                          <Route
                            path={paths.chat + "/:chatId"}
                            exact
                            component={Chat}
                          />
                          <Route
                            path={paths.teamShow + "/:id"}
                            exact
                            component={TeamShow}
                          />
                          <Route
                            path={paths.skills}
                            exact
                            component={SkillIndex}
                          />
                          <Route path={paths.store} exact component={Store} />

                          <PrivateRoute
                            path={paths.userCards}
                            exact
                            component={UserCards}
                          />
                          <PrivateRoute
                            path={paths.myUser}
                            exact
                            component={UserShow}
                          />
                          <PrivateRoute
                            path={paths.playerNew}
                            exact
                            component={PlayerCreate}
                          />
                          <PrivateRoute
                            path={paths.teamNew}
                            exact
                            component={TeamCreate}
                          />
                          <Route component={Home} />
                        </Switch>
                      </Suspense>
                    )}
                  </Layout>
                </ThemeProvider>
              </StyledEngineProvider>
            </RecoilRoot>
          </Router>
        </RootContext>
      </ApolloProvider>
    </React.StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Suspense fallback={null}>
    <Root />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
