import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
} from "@apollo/client";

import { getFromStore, STORE_KEYS } from "./storage/Storage";

const httpLink = new HttpLink({
  uri: "/graphql",
});

const cache = new InMemoryCache();

const request = (operation) => {
  const token = getFromStore(STORE_KEYS.TOKEN);
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV === "development",
  link: ApolloLink.from([requestLink, httpLink]),
  cache,
});

export default client;
