import React from "react";
import { useTranslation } from "react-i18next";

const Retirement = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      {t("t:RetirementTitle", "__RetirementTitle")}
      <br />
      {t("t:RetirementDescription", "__RetirementDescription")}
    </>
  );
});
export default Retirement;
