import palette from "../styles/palette";
import styled from "styled-components";
import { Select } from "@mui/material";

export const SelectStyled = styled(Select)(({ theme }) => ({
  background: palette.primary.main,
  color: palette.primary.contrastText,
  fontWeight: "bold",
  opacity: 0.8,
  textDecoration: "none",
  textTransform: "uppercase",
  paddingLeft: "0.5em",

  "&:hover, &:focus": {
    opacity: 1,
    textDecoration: "underline",
  },

  "& svg": {
    fill: palette.primary.contrastText,
  },
}));
