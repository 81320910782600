import { storeBadges } from "enefel/store";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Item from "./Item";

const Wrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
`;

const StoreBadges = memo(({ tooth = 0, onBuy, loading, purchases }) => {
  const { t } = useTranslation("badge");
  const availableBadges = Object.entries(storeBadges);
  return (
    <>
      <h2>{t("t:Teams", "__Teams")}</h2>
      <Wrapper>
        {availableBadges.map(([key, badge]) => {
          if (badge.isUnique && purchases.find((p) => p.itemKey === key)) {
            return null;
          }
          const disabled = loading || +tooth < +badge.price;
          return (
            <Item
              disabled={disabled}
              itemKey={key}
              key={`item-badge-${key}`}
              loading={loading}
              onBuy={onBuy}
              price={badge.price}
              title={t(`badge:${key}`, `__${key}`)}
              description={t(`badge:${key} description`, `__${key}`)}
              type={"badge"}
            />
          );
        })}
      </Wrapper>
    </>
  );
});

export default StoreBadges;
