import { memo } from "react";
import styled from "styled-components";

import publicPath from "../../../helpers/publicPath";

const Wrapper = styled.div`
  padding: 0.2rem;
`;
const Image = styled.div`
  text-align: center;
`;
const Title = styled.div`
  font-weight: bold;
  margin: 0.2rem 0;
`;

const DetailInducement = memo(({ icon, title, description }) => {
  return (
    <Wrapper>
      {icon && (
        <Image>
          <img
            alt={title}
            loading={"lazy"}
            src={publicPath(`inducement/${icon}`)}
          />
        </Image>
      )}
      <Title>{title}</Title>
      <div>{description}</div>
    </Wrapper>
  );
});

export default DetailInducement;
