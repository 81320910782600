import { useQuery } from "@apollo/client";
import { GAME_HISTORY_INFO, p } from "enefel";
import React from "react";
import styled from "styled-components";
import { GET_GAME_HISTORY } from "../../../../../query/GameQueries";
import IconBlood from "../../../../Icon/blood/IconBlood";

import { TILE_SIZE } from "../../Stadium";

const BloodPositionWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${(props) => props.size + "px"};
  justify-content: center;
  left: ${(props) => props.x * props.size + "px"};
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  width: ${(props) => props.size + "px"};
  z-index: 0;
`;
const Blood = React.memo(({ gameId }) => {
  const { loading, data } = useQuery(GET_GAME_HISTORY, {
    variables: {
      gameId: gameId,
      playerId: null,
    },
  });
  if (loading) {
    return null;
  }
  const blood = [];
  data.gameHistory.forEach((h) => {
    const infos = h.info.split(";");
    const hasInjury = infos.find((i) => {
      let data = i.split(" ");
      if (!data) {
        data = [];
      }
      const type = data[0];
      return type === GAME_HISTORY_INFO.INJURY;
    });
    if (hasInjury) {
      infos.some((i) => {
        let details = i.split(" ");
        if (!details) {
          details = [];
        }
        const type = details[0];
        if (type === GAME_HISTORY_INFO.MOVE && details[4] && details[5]) {
          blood.push(p(details[4], details[5]));
        }
        return false;
      });
    }
  });

  return blood.map((b, i) => (
    <BloodPositionWrapper x={b.x} y={b.y} size={TILE_SIZE} key={i}>
      <IconBlood gameId={gameId} x={b.x} y={b.y} />
    </BloodPositionWrapper>
  ));
});

export default Blood;
