export const ACTION_LIST = {
  BLOCK: "block",
  CHAINSAW: "chainsaw",
  FOUL: "foul",
  FUMBLEROOSKIE: "fumblerooskie",
  HYPNOTIC_GAZE: "hypnotic-gaze",
  JUMP: "jump",
  MOVE: "move",
  PASS: "pass",
  SPY: "spy",
  THROW_TEAM_MATE: "throw-team-mate",
  VOMIT: "vomit",
};
