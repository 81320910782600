import React from "react";
import MuiAlert from '@mui/material/Alert';

function Alert(props) {
  return (
    <>
      <MuiAlert elevation={6} variant="filled" {...props} />
      <br />
    </>
  );
}

const Error = React.memo(({ children }) => {
  return children && children !== {} ? (
    <Alert severity="error">{children}</Alert>
  ) : null;
});
export default Error;
