import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { RootContext } from "./RootContext";
import { paths } from "../path";

const PrivateRoute = React.memo(
  ({ component: Component, authenticated, ...rest }) => {
    const { token } = useContext(RootContext);
    return (
      <Route
        {...rest}
        render={(props) => {
          return token ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: paths.login, state: { from: props.location } }}
            />
          );
        }}
      />
    );
  }
);

export default PrivateRoute;
