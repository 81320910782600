import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import publicPath from "../../helpers/publicPath";

const size = "20px";

const Center = styled.div`
  display: flex;

  & > * {
    &:first-child {
      margin-right: ${(props) => props.mr};
    }
  }
`;

const ImgWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  position: relative;
  align-items: center;
`;

const Img = styled.img`
  width: ${size};
  min-width: ${size};
  max-width: ${size};
  height: ${size};
  min-height: ${size};
  max-height: ${size};
`;

const Text = styled.span`
  align-items: center;
  display: flex;
  font-size: 0.8em;
  height: ${size};
  justify-content: center;
  position: absolute;
  width: ${size};
  font-weight: bold;
  color: white;
  text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000,
    -1px -1px #000, 1px -1px #000, -1px 1px #000;
`;

const IconCoin = React.memo(({ value = null, mr = "0.5rem" }) => {
  const { t } = useTranslation();
  return (
    <Center mr={mr}>
      <ImgWrapper>
        {value != null && <Text>{value}</Text>}
        <Img src={publicPath("coin.svg")} alt={t("t:Coin", "__Coin")} />
      </ImgWrapper>
    </Center>
  );
});

export default IconCoin;
