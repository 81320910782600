import React from "react";

import styled from "styled-components";
import publicPath from "../../helpers/publicPath";

const titleUrl = publicPath("interface/title.jpg");
const contentUrl = publicPath("interface/grass.jpg");
const contentBottomUrl = publicPath("interface/grass-bottom.jpg");
const headerHeight = "44px";
const headerHeightSmall = "25px";
// const contentHeight = "86px";
// const contentBottomHeight = "12px";
const width = "399px";

export const BoxHeader = styled.h2`
  background-image: url(${titleUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-size: ${(props) => (props.isSmall ? "1em" : "1.4em")};
  height: ${(props) => (props.isSmall ? headerHeightSmall : headerHeight)};
  line-height: ${(props) => (props.isSmall ? headerHeightSmall : headerHeight)};
  max-width: ${(props) => (props.hasMax ? width : null)};
  overflow: hidden;
  padding: 0 20px;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  text-shadow: 1px 2px 3px black;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;

  &:before {
    background-image: url(${titleUrl});
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    content: " ";
    height: 100%;
    left: 0;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 10px, transparent);
    position: absolute;
    top: 0;
    width: 20px;
    z-index: 1;
  }

  &:after {
    background-image: url(${titleUrl});
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    content: " ";
    height: 100%;
    mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 1) 10px, transparent);
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
    z-index: 1;
  }
`;

const BoxContent = styled.div`
  background-image: url(${contentUrl});
  background-position: center;
  background-repeat: repeat-y;
  color: white;
  max-width: ${(props) => (props.hasMax ? width : null)};
  overflow: hidden;
  padding: ${(prop) => prop.withPadding + "rem"} 10px;
  position: relative;
  text-align: center;
  width: 100%;

  &:before {
    background-image: url(${contentUrl});
    background-position: left;
    background-repeat: repeat-y;
    content: " ";
    height: 100%;
    left: 0;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 10px, transparent);
    position: absolute;
    top: 0;
    width: 10px;
    z-index: 1;
  }

  &:after {
    background-image: url(${contentUrl});
    background-position: right;
    background-repeat: repeat-y;
    content: " ";
    height: 100%;
    mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 1) 10px, transparent);
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    z-index: 1;
  }
`;

const BoxBottom = styled.div`
  background-image: url(${contentBottomUrl});
  background-position: bottom;
  background-repeat: repeat-y;
  height: 5px;
  color: white;
  max-width: ${(props) => (props.hasMax ? width : null)};
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  &:before {
    background-image: url(${contentBottomUrl});
    background-position: left bottom;
    background-repeat: repeat-y;
    content: " ";
    height: 100%;
    left: 0;
    mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1) 30px, transparent);
    position: absolute;
    bottom: 0;
    width: 30px;
    z-index: 1;
  }

  &:after {
    background-image: url(${contentBottomUrl});
    background-position: right bottom;
    background-repeat: repeat-y;
    content: " ";
    height: 100%;
    mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 1) 30px, transparent);
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    z-index: 1;
  }
`;

const Box = React.memo(
  ({ header, children, withPadding = 1, isSmall = false, hasMax = true }) => {
    return (
      <>
        <BoxHeader hasMax={hasMax} isSmall={isSmall}>
          {header}
        </BoxHeader>
        <BoxContent hasMax={hasMax} withPadding={withPadding}>
          {children}
        </BoxContent>
        <BoxBottom hasMax={hasMax}></BoxBottom>
      </>
    );
  }
);

export default Box;
