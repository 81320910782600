import { paths } from "../../path";
import A from "../A";
import IconPlayer from "../Icon/IconPlayer";
import React from "react";
import styled from "styled-components";
import PlayerMorale from "./PlayerMorale";
import { gap } from "../../styles/polyfill";
import { PLAYER_STATUS } from "enefel/status";
import RaceCareer from "../Race/RaceCareer";
import { Flex } from "../styles";
import { getCareerKeyFromPlayer } from "enefel/race";
import PlayerIsCoach from "./PlayerIsCoach";

const PlayerWrapper = styled.span`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  line-height: 0.8em;
  position: relative;
  ${gap("0.5em")}
`;
const PlayerName = styled.span`
  line-height: 1em;
`;

const PlayerLink = React.memo(
  ({
    big = false,
    color = null,
    colorContrast = null,
    horizontal = false,
    isActive = true,
    onClick = null,
    player,
    side = 1,
    size = "24px",
    withIcon = true,
    withName = true,
    withMorale = false,
    status = PLAYER_STATUS.STANDING,
    withRaceCareer = false,
    withIsCoach = false,
    withMetaIcon = true,
  }) => {
    if (!player) {
      return null;
    }
    const isConnected =
      player.connectedAt &&
      new Date() - new Date(player.connectedAt) < 10 * 60 * 1000;
    let name = withName ? <PlayerName>{player.name}</PlayerName> : null;

    return (
      <A
        to={`${paths.playerShow}/${player.id}`}
        big={big}
        colorContrast={colorContrast}
        onClick={onClick}
        tabIndex={isActive ? null : -1}
      >
        <PlayerWrapper horizontal={horizontal}>
          {withIcon && (
            <div>
              <IconPlayer
                side={side}
                status={status}
                size={size}
                name={player.icon_id}
                color={color ? color : player.team ? player.team.color1 : null}
                state={player.state}
                isConnected={isConnected}
                isRookie={player.isRookie}
                player={player}
                withMetaIcon={withMetaIcon}
              />
              {withMorale && <PlayerMorale player={player} />}
            </div>
          )}
          {withRaceCareer && withName ? (
            <Flex dir={"column"} align={"top"} gap={0.25}>
              <Flex align={"center"}>
                {name}{" "}
                {withIsCoach ? (
                  <PlayerIsCoach player={player} team={player.team} />
                ) : null}
              </Flex>
              <RaceCareer careerId={getCareerKeyFromPlayer(player)} />
            </Flex>
          ) : (
            name
          )}
        </PlayerWrapper>
      </A>
    );
  }
);

export default PlayerLink;
