import { METEO } from "enefel/meteo";
import { TILE_SIZE } from "../Game/Stadium/Stadium";
import React from "react";
import { lazy } from "react";
const AutumnalChill = lazy(() => import("./effects/AutumnalChill"));
const StrongWinds = lazy(() => import("./effects/StongWinds"));
const PouringRain = lazy(() => import("./effects/PouringRain"));
const AutumnLeafStrewnPitch = lazy(() =>
  import("./effects/AutumnLeafStrewnPitch")
);

const MeteoEffect = React.memo(({ value, stadium_height, stadium_width }) => {
  if (value === METEO.STRONG_WINDS) {
    return (
      <StrongWinds
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
      />
    );
  }
  if (value === METEO.POURING_RAIN) {
    return (
      <PouringRain
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
      />
    );
  }
  if (value === METEO.AUTUMN_LEAF_STREWN_PITCH) {
    return (
      <AutumnLeafStrewnPitch
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
      />
    );
  }
  if (value === METEO.MORNING_DEW) {
    return (
      <AutumnalChill
        opacity={0.15}
        zIndex={0}
        isAnimated={false}
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
      />
    );
  }
  if (value === METEO.MISTY_MORNING) {
    return (
      <AutumnalChill
        opacity={0.2}
        zIndex={999}
        isAnimated={true}
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
        speed={200}
      />
    );
  }
  if (value === METEO.AUTUMNAL_CHILL) {
    return (
      <AutumnalChill
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
      />
    );
  }
  if (value === METEO.COLD_WINDS) {
    return (
      <StrongWinds
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
      />
    );
  }
  if (value === METEO.FREEZING) {
    return (
      <AutumnalChill
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
        opacity={0.25}
        zIndex={0}
        isAnimated={false}
      />
    );
  }
  if (value === METEO.BLIZZARD) {
    return (
      <StrongWinds
        width={TILE_SIZE * stadium_width + "px"}
        height={TILE_SIZE * stadium_height + "px"}
        rotation={-10}
        speed={15}
        animation={"snow"}
        timing={"linear"}
      />
    );
  }
  return null;
});

export default MeteoEffect;
