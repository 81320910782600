import React from "react";
import styled from "styled-components";
import Col from "../Interface/Col";
import Copyright from "./Copyright";
import Facebook from "./Facebook";
import Discord from "./Discord";

const FooterWrapper = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  a {
    color: white;
    margin: 0 0.5em;
  }

  img {
    width: 20px;
    height: 20px;
  }

  & > *:first-child {
    margin-right: 1em;
  }
`;

const Footer = () => {
  return (
    <Col withBorder={false}>
      <FooterWrapper>
        <Copyright />
        <Facebook />
        <Discord />
      </FooterWrapper>
    </Col>
  );
};
export default Footer;
