import React from "react";
import Skill from "./Skill";
import { getCareerFromKey } from "enefel/race";
import { getSkillDisplayedWithCarrer, isSpecialSkill } from "enefel/skill";
import SpecialSkills from "./SpecialSkills";

const Skills = React.memo(
  ({
    playerId = null,
    careerId = null,
    skills = [],
    separator = <br />,
    empty = "None",
    hide = getSkillDisplayedWithCarrer(), // hide big guy, specialist etc...
  }) => {
    let raceSkills = [];
    if (careerId) {
      const career = getCareerFromKey(careerId);
      raceSkills = career ? career.skills : [];
    }

    const list = skills
      .filter((s) => !isSpecialSkill(s))
      .map((s) => {
        let info = null;
        if (Array.isArray(s)) {
          info = s[1];
          s = s[0];
        }
        const key = `${s.skill_id ? s.skill_id : s}-${playerId}-${s.info}`;
        const isNew =
          careerId && raceSkills !== undefined
            ? !raceSkills.some(
                (r) =>
                  r === s.skill_id || (Array.isArray(r) && r[0] === s.skill_id)
              )
            : false;

        return (
          <Skill key={key} skill={s} isNew={isNew} info={info || s.info} />
        );
      });
    const listSkills =
      list && list.length
        ? list.reduce((prev, curr, i) => {
            return [
              prev,
              <React.Fragment key={`${i}-separator-skill-${playerId}`}>
                {separator}
              </React.Fragment>,
              curr,
            ];
          })
        : empty;

    return (
      <>
        {listSkills && <div>{listSkills}</div>}
        <div>
          <SpecialSkills
            empty={null}
            playerId={playerId}
            careerId={careerId}
            separator={separator}
            skills={skills}
            hide={hide}
          />
        </div>
      </>
    );
  }
);

export default Skills;
