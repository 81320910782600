import React from "react";
import styled from "styled-components";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const StyledChartContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

const UsersChart = ({ users }) => {
  // Group users by month using the createdAt and updatedAt fields
  const usersByMonth = users.reduce((acc, user) => {
    const createdAtMonth = new Date(user.createdAt).toLocaleString("fr-FR", {
      month: "long",
      year: "numeric",
    });
    const updatedAtMonth = new Date(user.updatedAt).toLocaleString("fr-FR", {
      month: "long",
      year: "numeric",
    });
    if (createdAtMonth === updatedAtMonth) {
      acc[createdAtMonth] = {
        new_users: (acc[createdAtMonth]?.new_users || 0) + 1,
        inactive_users: acc[createdAtMonth]?.inactive_users || 0,
      };
    } else {
      acc[createdAtMonth] = {
        new_users: (acc[createdAtMonth]?.new_users || 0) + 1,
        inactive_users: (acc[updatedAtMonth]?.inactive_users || 0) + 1,
      };
    }
    return acc;
  }, {});

  // Convert the grouped data to an array of objects with month, new_users, and inactive_users keys
  const chartData = Object.entries(usersByMonth).map(([month, data]) => ({
    month,
    new_users: data.new_users,
    inactive_users: data.inactive_users,
  }));

  // Sort the data by month using a custom comparison function
  chartData.sort((a, b) => {
    const months = [
      "janvier",
      "février",
      "mars",
      "avril",
      "mai",
      "juin",
      "juillet",
      "août",
      "septembre",
      "octobre",
      "novembre",
      "décembre",
    ];
    const aMonth = a.month.split(" ")[0];
    const bMonth = b.month.split(" ")[0];
    const aYear = a.month.split(" ")[1];
    const bYear = b.month.split(" ")[1];
    if (aYear !== bYear) {
      return aYear.localeCompare(bYear);
    } else {
      return months.indexOf(aMonth) - months.indexOf(bMonth);
    }
  });

  return (
    <StyledChartContainer>
      <LineChart width={chartData.length * 50} height={400} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" interval={0} tick={{ angle: -45 }} height={80} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="new_users"
          name="Nouveaux joueurs"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="inactive_users"
          name="Joueurs inactifs"
          stroke="#82ca9d"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </StyledChartContainer>
  );
};

export default UsersChart;
