import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import styled from "styled-components";
import CustomTooltip from "./Tooltip";

export const TipIcon = styled(AiOutlineInfoCircle)`
  align-items: center;
  cursor: help;
  display: flex;
  font-size: 0.8em;
`;

const Info = React.memo(({ children }) => {
  return (
    <CustomTooltip placement="top" title={children}>
      <span>
        <TipIcon fontSize="small" />
      </span>
    </CustomTooltip>
  );
});

export default Info;
