import React from "react";
import { useRecoilValue } from "recoil";
import InducementIcon from "../Icon/inducement/Inducement";
import { currentGameState } from "../Game/atoms/gameState";
import { getGameInducements } from "enefel/inducement";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  min-height: 41px;
`;

const Inducement = React.memo(({ team }) => {
  const game = useRecoilValue(currentGameState);
  const inducements = game.inducements;
  if (!inducements) {
    return <Wrapper>&nbsp;</Wrapper>;
  }

  const [teamId, inducementList] = getGameInducements(game);
  if (teamId !== team.id) {
    return <Wrapper>&nbsp;</Wrapper>;
  }
  return (
    <Wrapper>
      {inducementList.map((i, index) => (
        <InducementIcon key={`inducement-${i}-${index}`} name={i} />
      ))}
    </Wrapper>
  );
});

export default Inducement;
