import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import publicPath from "../../helpers/publicPath";
import { SKILL_NAMES, hasSkill } from "enefel/skill";
import Tentacle from "../Game/Stadium/PlayerPosition/Weapon/Tentacle";
import FoulAppearance from "../Game/Stadium/PlayerPosition/Weapon/FoulAppearance";
import DisturbingPresence from "../Game/Stadium/PlayerPosition/Weapon/DisturbingPresence";

export const IconSize = 35;

const getShadow = (st) => {
  let value = 50;

  if (st >= 6) {
    value = 80;
  } else if (st === 5) {
    value = 75;
  } else if (st === 4) {
    value = 70;
  }
  return value;
};

const Wrapper = styled.div`
  height: ${(props) => props.size};
  position: relative;
  width: ${(props) => props.size};
  &::before {
    background-color: ${(props) =>
      props.isInjured === 2 ? "rgba(200, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.7)"};
    border-radius: 50%;
    bottom: ${(props) => (props.isInjured === 0 ? "-2%" : "23%")};
    content: "";
    height: 20%;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: ${(props) =>
      props.isInjured === 0 ? `${getShadow(props.st)}%` : "70%"};

    filter: hue-rotate(45deg);
    z-index: -1;
  }
`;

const Canvas = styled.canvas`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
`;

function IconPlayerSprite({ color, x, y, name, size, player }) {
  const canvasRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    const img = new Image();
    img.src = publicPath(`./tile/player/${name}.gif`);
    img.onload = () => {
      let decalageX = x;
      let decalageY = y;
      const isSmallSize = img.width < 3 * IconSize;
      if (isSmallSize && x === 2) {
        decalageX = 1;
      }
      canvas.width = IconSize;
      canvas.height = IconSize;

      // 2. Appliquer rotation de -90 degrés si x = 1 ou 2
      if (isSmallSize && (x === 1 || x === 2)) {
        ctx.translate(IconSize / 2, IconSize / 2);
        ctx.rotate((-90 * Math.PI) / 180);
        ctx.translate(-IconSize / 2, -IconSize / 2);
      }

      // 3. Appliquer symétrie horizontale si x = 2
      if (isSmallSize && x === 2) {
        ctx.scale(-1, 1);
        ctx.translate(-IconSize, 0);
      }

      const xPos = decalageX * IconSize;
      const yPos = decalageY * IconSize;
      ctx.drawImage(img, -xPos, -yPos);
      const imageData = ctx.getImageData(0, 0, IconSize, IconSize);
      const data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        if (r > 150 && g < 95 && b < 100) {
          const hexColor = color.substring(1);
          const red = parseInt(hexColor.substring(0, 2), 16);
          const green = parseInt(hexColor.substring(2, 4), 16);
          const blue = parseInt(hexColor.substring(4, 6), 16);
          data[i] = red;
          data[i + 1] = green;
          data[i + 2] = blue;
        }
      }
      ctx.putImageData(imageData, 0, 0);
    };
  }, [color, name, x, y]);

  return (
    <Wrapper
      size={size}
      isInjured={x}
      className="playerIcon"
      st={player?.ST || 4}
    >
      <Canvas ref={canvasRef} />
      {player && hasSkill(player, SKILL_NAMES.TENTACLES) && (
        <Tentacle player={player} />
      )}
      {player && hasSkill(player, SKILL_NAMES.FOUL_APPEARANCE) && (
        <FoulAppearance player={player} />
      )}
      {player && hasSkill(player, SKILL_NAMES.DISTURBING_PRESENCE) && (
        <DisturbingPresence player={player} />
      )}
    </Wrapper>
  );
}

export default IconPlayerSprite;
