import { memo, useEffect, useState } from "react";

import Confetti from "react-confetti";
import { colorShade } from "enefel/color";
import { TILE_SIZE } from "../Stadium";
import { getTime, TYPE_TIMER } from "enefel/time";
import { GAME_STATUS } from "enefel/game";

const ConfettiTD = memo(({ game, color1, color2 }) => {
  const [party, setParty] = useState(false);
  const [color, setColor] = useState(null);

  useEffect(() => {
    if (
      game.last_td != null &&
      game.date_kickoff &&
      game.status === GAME_STATUS.TD
    ) {
      const dateKickOff = new Date(game.date_kickoff);
      let dateTD = new Date(dateKickOff);
      dateTD.setHours(dateTD.getHours() - getTime(TYPE_TIMER.TD) / 3600);
      let dateEndConfetti = new Date(dateTD);
      dateEndConfetti.setHours(dateEndConfetti.getHours() + 1);

      if (new Date() < dateEndConfetti) {
        setColor(game.last_td === 1 ? color1 : color2);
      }
    }
  }, [
    game.last_td,
    game.date_kickoff,
    game.status,
    color1,
    color2,
    setParty,
    setColor,
  ]);

  useEffect(() => {
    if (color !== null) {
      setParty(true);
    }
  }, [color]);

  return (
    <>
      <Confetti
        width={(game.stadium_width + 2) * TILE_SIZE}
        height={(game.stadium_height + 2) * TILE_SIZE}
        colors={[
          `#${color}`,
          colorShade(`#${color}`, -50),
          colorShade(`#${color}`, -25),
          colorShade(`#${color}`, 25),
          colorShade(`#${color}`, 50),
        ]}
        style={{ pointerEvents: "none" }}
        numberOfPieces={party ? 500 : 0}
        recycle={false}
        onConfettiComplete={(confetti) => {
          setParty(false);
          confetti.reset();
        }}
      />
    </>
  );
});

export default ConfettiTD;
