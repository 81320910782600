import React, { lazy, Suspense } from "react";
import styled from "styled-components/macro";

import { TILE_SIZE } from "../Game/Stadium/Stadium";

import { getCareerFromIcon } from "enefel/race";
import {
  isActive,
  isBlocked,
  isDead,
  isInactive,
  isRetirement,
  PLAYER_STATE,
} from "enefel/state";
import { PLAYER_STATUS } from "enefel/status";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import { MdLock } from "react-icons/md";
import Blocked from "../Game/Stadium/PlayerPosition/Tooltip/Blocked";
import Dead from "../Game/Stadium/PlayerPosition/Tooltip/Dead";
import Retirement from "../Game/Stadium/PlayerPosition/Tooltip/Retirement";
import Rookie from "../Game/Stadium/PlayerPosition/Tooltip/Rookie";
import IconPlayerSprite from "./IconPlayerSprite";
import TooltipPlayer from "./TooltipPlayer";

const CustomTooltip = lazy(() => import("../Interface/Tooltip"));

const HalflingLineman1 = lazy(() =>
  import("./player/halfling/halfling-lineman1")
);
const HalflingLineman1_1 = lazy(() =>
  import("./player/halfling/halfling-lineman1_1")
);
const HalflingLineman1_2 = lazy(() =>
  import("./player/halfling/halfling-lineman1_2")
);
const HalflingHefty1 = lazy(() => import("./player/halfling/halfling-hefty1"));
const HalflingHefty1_1 = lazy(() =>
  import("./player/halfling/halfling-hefty1_1")
);
const HalflingHefty1_2 = lazy(() =>
  import("./player/halfling/halfling-hefty1_2")
);
const HalflingCatcher1 = lazy(() =>
  import("./player/halfling/halfling-catcher1")
);
const HalflingCatcher1_1 = lazy(() =>
  import("./player/halfling/halfling-catcher1_1")
);
const HalflingCatcher1_2 = lazy(() =>
  import("./player/halfling/halfling-catcher1_2")
);
const HalflingTreeman1 = lazy(() =>
  import("./player/halfling/halfling-treeman1")
);
const HalflingTreeman1_1 = lazy(() =>
  import("./player/halfling/halfling-treeman1_1")
);
const HalflingTreeman1_2 = lazy(() =>
  import("./player/halfling/halfling-treeman1_2")
);
const StaffCoachReroll = lazy(() => import("./player/staff/coach-reroll"));
const StaffSeller = lazy(() => import("./player/staff/seller"));
const StaffHmc = lazy(() => import("./player/staff/hmc"));
const StaffSpy = lazy(() => import("./player/staff/spy"));
const StaffXxx = lazy(() => import("./player/staff/xxx"));
const StaffApo = lazy(() => import("./player/staff/apo"));
const StaffBribe = lazy(() => import("./player/staff/bribe"));

const LizardmenChameleon1 = lazy(() =>
  import("./player/lizardmen/lizardmen-chameleon1")
);
const LizardmenChameleon1_1 = lazy(() =>
  import("./player/lizardmen/lizardmen-chameleon1_1")
);
const LizardmenChameleon1_2 = lazy(() =>
  import("./player/lizardmen/lizardmen-chameleon1_2")
);

const KhorneLineman1 = lazy(() => import("./player/khorne/khorne-lineman1"));
const KhorneKhorngor1 = lazy(() => import("./player/khorne/khorne-khorngor1"));
const KhorneBloodseeker1 = lazy(() =>
  import("./player/khorne/khorne-bloodseeker1")
);
const KhorneBloodspawn1 = lazy(() =>
  import("./player/khorne/khorne-bloodspawn1")
);
const NurgleRotter1 = lazy(() => import("./player/nurgle/nurgle-rotter1"));
const NurglePestigor1 = lazy(() => import("./player/nurgle/nurgle-pestigor1"));
const NurgleBloater1 = lazy(() => import("./player/nurgle/nurgle-bloater1"));
const NurgleRotspawn1 = lazy(() => import("./player/nurgle/nurgle-rotspawn1"));
const SnotlingTroll1 = lazy(() => import("./player/snotling/snotling-troll1"));
const SnotlingWagon1 = lazy(() => import("./player/snotling/snotling-wagon1"));
const SnotlingRunner1 = lazy(() =>
  import("./player/snotling/snotling-runner1")
);
const SnotlingLineman1 = lazy(() =>
  import("./player/snotling/snotling-lineman1")
);
const NecromanticZombie1 = lazy(() =>
  import("./player/necromantic/necromantic-zombie1")
);
const NecromanticGhoul1 = lazy(() =>
  import("./player/necromantic/necromantic-ghoul1")
);
const NecromanticWraith1 = lazy(() =>
  import("./player/necromantic/necromantic-wraith1")
);
const NecromanticWerewolf1 = lazy(() =>
  import("./player/necromantic/necromantic-werewolf1")
);
const NecromanticGolem1 = lazy(() =>
  import("./player/necromantic/necromantic-golem1")
);

const GobelinTroll1 = lazy(() => import("./player/gobelin/gobelin-troll1"));
const GobelinLineman1 = lazy(() => import("./player/gobelin/gobelin-lineman1"));

const KhorneLineman1_1 = lazy(() =>
  import("./player/khorne/khorne-lineman1_1")
);
const KhorneKhorngor1_1 = lazy(() =>
  import("./player/khorne/khorne-khorngor1_1")
);
const KhorneBloodseeker1_1 = lazy(() =>
  import("./player/khorne/khorne-bloodseeker1_1")
);
const KhorneBloodspawn1_1 = lazy(() =>
  import("./player/khorne/khorne-bloodspawn1_1")
);
const NurgleRotter1_1 = lazy(() => import("./player/nurgle/nurgle-rotter1_1"));
const NurglePestigor1_1 = lazy(() =>
  import("./player/nurgle/nurgle-pestigor1_1")
);
const NurgleBloater1_1 = lazy(() =>
  import("./player/nurgle/nurgle-bloater1_1")
);
const NurgleRotspawn1_1 = lazy(() =>
  import("./player/nurgle/nurgle-rotspawn1_1")
);
const SnotlingTroll1_1 = lazy(() =>
  import("./player/snotling/snotling-troll1_1")
);
const SnotlingWagon1_1 = lazy(() =>
  import("./player/snotling/snotling-wagon1_1")
);
const SnotlingRunner1_1 = lazy(() =>
  import("./player/snotling/snotling-runner1_1")
);
const SnotlingLineman1_1 = lazy(() =>
  import("./player/snotling/snotling-lineman1_1")
);
const NecromanticZombie1_1 = lazy(() =>
  import("./player/necromantic/necromantic-zombie1_1")
);
const NecromanticGhoul1_1 = lazy(() =>
  import("./player/necromantic/necromantic-ghoul1_1")
);
const NecromanticWraith1_1 = lazy(() =>
  import("./player/necromantic/necromantic-wraith1_1")
);
const NecromanticWerewolf1_1 = lazy(() =>
  import("./player/necromantic/necromantic-werewolf1_1")
);
const NecromanticGolem1_1 = lazy(() =>
  import("./player/necromantic/necromantic-golem1_1")
);

const GobelinTroll1_1 = lazy(() => import("./player/gobelin/gobelin-troll1_1"));
const GobelinLineman1_1 = lazy(() =>
  import("./player/gobelin/gobelin-lineman1_1")
);

const KhorneLineman1_2 = lazy(() =>
  import("./player/khorne/khorne-lineman1_2")
);
const KhorneKhorngor1_2 = lazy(() =>
  import("./player/khorne/khorne-khorngor1_2")
);
const KhorneBloodseeker1_2 = lazy(() =>
  import("./player/khorne/khorne-bloodseeker1_2")
);
const KhorneBloodspawn1_2 = lazy(() =>
  import("./player/khorne/khorne-bloodspawn1_2")
);
const NurgleRotter1_2 = lazy(() => import("./player/nurgle/nurgle-rotter1_2"));
const NurglePestigor1_2 = lazy(() =>
  import("./player/nurgle/nurgle-pestigor1_2")
);
const NurgleBloater1_2 = lazy(() =>
  import("./player/nurgle/nurgle-bloater1_2")
);
const NurgleRotspawn1_2 = lazy(() =>
  import("./player/nurgle/nurgle-rotspawn1_2")
);
const SnotlingTroll1_2 = lazy(() =>
  import("./player/snotling/snotling-troll1_2")
);
const SnotlingWagon1_2 = lazy(() =>
  import("./player/snotling/snotling-wagon1_2")
);
const SnotlingRunner1_2 = lazy(() =>
  import("./player/snotling/snotling-runner1_2")
);
const SnotlingLineman1_2 = lazy(() =>
  import("./player/snotling/snotling-lineman1_2")
);
const NecromanticZombie1_2 = lazy(() =>
  import("./player/necromantic/necromantic-zombie1_2")
);
const NecromanticGhoul1_2 = lazy(() =>
  import("./player/necromantic/necromantic-ghoul1_2")
);
const NecromanticWraith1_2 = lazy(() =>
  import("./player/necromantic/necromantic-wraith1_2")
);
const NecromanticWerewolf1_2 = lazy(() =>
  import("./player/necromantic/necromantic-werewolf1_2")
);
const NecromanticGolem1_2 = lazy(() =>
  import("./player/necromantic/necromantic-golem1_2")
);

const GobelinTroll1_2 = lazy(() => import("./player/gobelin/gobelin-troll1_2"));
const GobelinLineman1_2 = lazy(() =>
  import("./player/gobelin/gobelin-lineman1_2")
);

const icons = {
  GobelinLineman1_1,
  GobelinLineman1_2,
  GobelinLineman1,
  GobelinTroll1_1,
  GobelinTroll1_2,
  GobelinTroll1,
  HalflingCatcher1_1,
  HalflingCatcher1_2,
  HalflingCatcher1,
  HalflingHefty1_1,
  HalflingHefty1_2,
  HalflingHefty1,
  HalflingLineman1_1,
  HalflingLineman1_2,
  HalflingLineman1,
  HalflingTreeman1_1,
  HalflingTreeman1_2,
  HalflingTreeman1,
  KhorneBloodseeker1_1,
  KhorneBloodseeker1_2,
  KhorneBloodseeker1,
  KhorneBloodspawn1_1,
  KhorneBloodspawn1_2,
  KhorneBloodspawn1,
  KhorneKhorngor1_1,
  KhorneKhorngor1_2,
  KhorneKhorngor1,
  KhorneLineman1_1,
  KhorneLineman1_2,
  KhorneLineman1,
  LizardmenChameleon1_1,
  LizardmenChameleon1_2,
  LizardmenChameleon1,
  NecromanticGhoul1_1,
  NecromanticGhoul1_2,
  NecromanticGhoul1,
  NecromanticGolem1_1,
  NecromanticGolem1_2,
  NecromanticGolem1,
  NecromanticWerewolf1_1,
  NecromanticWerewolf1_2,
  NecromanticWerewolf1,
  NecromanticWraith1_1,
  NecromanticWraith1_2,
  NecromanticWraith1,
  NecromanticZombie1_1,
  NecromanticZombie1_2,
  NecromanticZombie1,
  NurgleBloater1_1,
  NurgleBloater1_2,
  NurgleBloater1,
  NurglePestigor1_1,
  NurglePestigor1_2,
  NurglePestigor1,
  NurgleRotspawn1_1,
  NurgleRotspawn1_2,
  NurgleRotspawn1,
  NurgleRotter1_1,
  NurgleRotter1_2,
  NurgleRotter1,
  SnotlingLineman1_1,
  SnotlingLineman1_2,
  SnotlingLineman1,
  SnotlingRunner1_1,
  SnotlingRunner1_2,
  SnotlingRunner1,
  SnotlingTroll1_1,
  SnotlingTroll1_2,
  SnotlingTroll1,
  SnotlingWagon1_1,
  SnotlingWagon1_2,
  SnotlingWagon1,
  StaffApo,
  StaffCoachReroll,
  StaffHmc,
  StaffSeller,
  StaffSpy,
  StaffXxx,
  StaffBribe,
};

const InactiveWrapper = styled.div`
  color: red;
  font-size: 1em;
  font-weight: bold;
  height: 10px;
  left: 7%;
  position: absolute;
  top: 7%;
  width: 10px;
  z-index: 1;
`;

const DeadWrapper = styled.div`
  bottom: 5px;
  font-size: 0.8em;
  height: 16px;
  position: absolute;
  right: 0;
  width: 16px;
  z-index: 1;
  filter: drop-shadow(2px 2px 3px black);
`;

const LockWrapper = styled.div`
  bottom: 0;
  height: 15px;
  position: absolute;
  right: 0;
  width: 16px;
  z-index: 1;

  & > svg {
    font-size: 1em;
  }
`;

const IsConnected = styled.div`
  animation: pulse 2s infinite;
  background: #4cff4c;
  border-radius: 50%;
  bottom: 7%;
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  height: 5px;
  position: absolute;
  right: 7%;
  transform: scale(1);
  width: 5px;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 5px rgba(51, 217, 178, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
  }
`;

const IsRookie = styled.div`
  bottom: 2%;
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  color: #4cff4c;
  font-size: 10px;
  height: 10px;
  left: 7%;
  position: absolute;
  transform: scale(1);
  width: 10px;
  z-index: 1;
`;

const Player = styled.div`
  position: relative;
  display: inline-flex;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  min-height: ${(props) => props.size};
  min-width: ${(props) => props.size};
  transform: ${(props) => (props.side === 2 ? "scaleX(-1)" : null)};
  .armor {
    fill: ${(props) => "#" + props.color};
  }
  .armor-dark {
    fill: ${(props) => props.color && darken(0.15, "#" + props.color)};
  }

  svg {
    filter: ${(props) =>
      props.status === PLAYER_STATUS.STUNNED
        ? "drop-shadow(0px 2px 3px red)"
        : null};
  }
`;

function getIconSpriteX(name, status) {
  if (status === PLAYER_STATUS.KNOCKED_DOWN) {
    return 1;
  } else if (
    status === PLAYER_STATUS.STUNNED ||
    status === PLAYER_STATUS.KO ||
    status === PLAYER_STATUS.CASUALTY
  ) {
    return 2;
  }
  return 0;
}
function getIconName(name, status) {
  let icon = null;
  let status_suffix = "";
  if (status === PLAYER_STATUS.KNOCKED_DOWN) {
    status_suffix = "_1";
  } else if (
    status === PLAYER_STATUS.STUNNED ||
    status === PLAYER_STATUS.KO ||
    status === PLAYER_STATUS.CASUALTY
  ) {
    status_suffix = "_2";
  }
  icon = name + status_suffix;
  icon = icon.charAt(0).toUpperCase() + icon.slice(1);
  return icon.replace(/-./g, (x) => x.toUpperCase()[1]);
}

const IconPlayer = React.memo(
  ({
    name,
    size = TILE_SIZE + "px",
    side = 1,
    status = PLAYER_STATUS.STANDING,
    color = "fe0000",
    isConnected = false,
    state = PLAYER_STATE.ACTIVE,
    isRookie = false,
    player = null,
    displayTooltip = true,
    withMetaIcon = true,
  }) => {
    color ??= "fe0000";
    const { t } = useTranslation();
    const icon = getIconName(name, status);
    let hasSprite = false;

    let iconSpriteX = null;

    let IconComponentName = null;
    const career = getCareerFromIcon(name);

    if (career && career.hasSprite) {
      iconSpriteX = getIconSpriteX(name, status);
      if (iconSpriteX !== null) {
        hasSprite = true;
      }
    } else {
      if (!icon || !icons[icon]) {
        return null;
      }
      IconComponentName = icons[icon];
    }

    return (
      <Suspense fallback={null}>
        <Player size={size} side={side} color={color} status={status}>
          {/* <Effect /> */}
          <CustomTooltip
            placement="top"
            title={
              player && displayTooltip ? (
                <TooltipPlayer player={player} />
              ) : null
            }
          >
            <span>
              {hasSprite ? (
                <IconPlayerSprite
                  color={`#${color}`}
                  x={iconSpriteX}
                  y={0}
                  name={name}
                  size={size}
                  player={player}
                />
              ) : (
                <IconComponentName width="100%" height="100%" />
              )}
            </span>
          </CustomTooltip>
          {isConnected && isActive(state) && <IsConnected />}
          {isRookie && (
            <CustomTooltip placement="top" title={<Rookie />}>
              <IsRookie>R</IsRookie>
            </CustomTooltip>
          )}
          {withMetaIcon && isInactive(state) && (
            <CustomTooltip
              placement="top"
              title={t("t:Inactive", "__Inactive")}
            >
              <InactiveWrapper>z</InactiveWrapper>
            </CustomTooltip>
          )}
          {withMetaIcon && isDead(state) && (
            <CustomTooltip placement="top" title={<Dead />}>
              <DeadWrapper>💀</DeadWrapper>
            </CustomTooltip>
          )}
          {withMetaIcon && isRetirement(state) && (
            <CustomTooltip placement="top" title={<Retirement />}>
              <DeadWrapper>☀️</DeadWrapper>
            </CustomTooltip>
          )}
          {withMetaIcon && isBlocked(state) && (
            <CustomTooltip placement="top" title={<Blocked />}>
              <LockWrapper>
                <MdLock fontSize="small" />
              </LockWrapper>
            </CustomTooltip>
          )}
        </Player>
      </Suspense>
    );
  }
);

export default IconPlayer;
