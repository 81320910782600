import React from "react";
import styled from "styled-components";
import CustomTooltip from "../Interface/Tooltip";
import { useTranslation } from "react-i18next";
import { getColorFromMoral, getMalusFromMoral } from "enefel/moral";

const MoraleDescription = React.memo(({ morale }) => {
  const { t } = useTranslation();
  const malus = getMalusFromMoral(morale);
  return (
    <div>
      <div>
        {t("t:Moral", "__Moral")}: {morale}%
      </div>
      {malus > 0 && (
        <div>
          {t("t:Time malus Detail", "__Time malus Detail")}: +{malus}%
        </div>
      )}
    </div>
  );
});
const MoraleWrapper = styled.div`
  background-color: black;
  height: 3px;
  width: 100%;
  border-radius: 5px;
`;
const Morale = styled.div`
  width: ${(props) => `${props.morale}%`};
  background-color: ${(props) => getColorFromMoral(props.morale)};
  height: 2px;
  border-radius: 5px;
`;
const PlayerMorale = React.memo(({ player }) => {
  return (
    <CustomTooltip
      placement="top"
      title={<MoraleDescription morale={player.moral} />}
    >
      <MoraleWrapper>
        <Morale morale={player.moral}></Morale>
      </MoraleWrapper>
    </CustomTooltip>
  );
});

export default PlayerMorale;
