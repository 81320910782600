import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import QueryError from "../Error/QueryError";
import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import { gap } from "../../styles/polyfill";

const WarningButtons = styled.div`
  display: flex;
  justify-content: space-between;
  ${gap("0.5em")}
`;
const ButtonConfirm = React.memo(
  ({
    disabled = false,
    error,
    loading,
    onAction,
    txt,
    txtConfirm,
    warningStyle = true,
  }) => {
    const [hasConfim, setHasConfirm] = useState(false);
    const { t } = useTranslation();

    function handleConfirm(val) {
      if (disabled) {
        return;
      }
      setHasConfirm(val);
    }

    return (
      <>
        {error && <QueryError error={error} />}
        {loading ? (
          <Loading />
        ) : hasConfim ? (
          <WarningButtons>
            <Button onClick={() => handleConfirm(false)}>
              {t("t:Cancel", "__Cancel")}
            </Button>
            <Button
              onClick={() => {
                handleConfirm(false);
                onAction();
              }}
              warning={true}
            >
              {txtConfirm}
            </Button>
          </WarningButtons>
        ) : (
          <Button
            disabled={disabled}
            onClick={() => handleConfirm(true)}
            warning={warningStyle}
          >
            {txt}
          </Button>
        )}
      </>
    );
  }
);

export default ButtonConfirm;
