import { PLAYER_STAT } from "enefel";

export const getGameStatsField = (t) => {
  return {
    [t("stat:categoryGeneral", "__categoryGeneral")]: [
      PLAYER_STAT.TD,
      PLAYER_STAT.TURNOVER,
      PLAYER_STAT.EXPERIENCE,
    ],
    [t("stat:categoryMove", "__categoryMove")]: [
      PLAYER_STAT.MOVE,
      PLAYER_STAT.DODGE_SUCCESS,
      PLAYER_STAT.DODGE_FAIL,
      PLAYER_STAT.GO_FOR_IT_1_SUCCESS,
      PLAYER_STAT.GO_FOR_IT_1_FAIL,
    ],
    [t("stat:categoryBall", "__categoryBall")]: [
      PLAYER_STAT.TAKE_BALL_SUCCESS,
      PLAYER_STAT.TAKE_BALL_FAIL,
      PLAYER_STAT.HANDING_OFF_SUCCESS,
      PLAYER_STAT.HANDING_OFF_FAIL,
      PLAYER_STAT.INTERCEPTION_SUCCESS,
      PLAYER_STAT.INTERCEPTION_FAIL,
    ],
    [t("stat:categoryBlock", "__categoryBlock")]: [
      PLAYER_STAT.BLITZ,
      PLAYER_STAT.BLOCK_M3D,
      PLAYER_STAT.BLOCK_M2D,
      PLAYER_STAT.BLOCK_1D,
      PLAYER_STAT.BLOCK_2D,
      PLAYER_STAT.BLOCK_3D,
      PLAYER_STAT.BLOCK_ARMOR_SUCCESS,
      PLAYER_STAT.INJURY_CASUALTY,
      PLAYER_STAT.INJURY_KNOCKED_DOWN,
      PLAYER_STAT.INJURY_KO,
      PLAYER_STAT.RECEIVE_BLOCK_ARMOR_SUCCESS,
      PLAYER_STAT.RECEIVE_INJURY_CASUALTY,
      PLAYER_STAT.RECEIVE_INJURY_KNOCKED_DOWN,
      PLAYER_STAT.RECEIVE_INJURY_KO,
    ],
    [t("stat:categoryPass", "__categoryPass")]: [
      PLAYER_STAT.PASS_PERFECT_COMPLETED,
      PLAYER_STAT.PASS_FAIL,
      PLAYER_STAT.PASS_FUMBLE,
      PLAYER_STAT.PASS_INTERCEPTED,
    ],
  };
};

export const getEndGameStatsField = (t) => {
  return {
    [t("stat:categorySuccess", "__categorySuccess")]: [
      PLAYER_STAT.EXPERIENCE,
      PLAYER_STAT.TD,
      PLAYER_STAT.PASS_PERFECT_COMPLETED,
      PLAYER_STAT.INJURY_KO,
      PLAYER_STAT.INJURY_CASUALTY,
    ],
    [t("stat:categoryFail", "__categoryFail")]: [
      PLAYER_STAT.TURNOVER,
      PLAYER_STAT.DODGE_FAIL,
      PLAYER_STAT.TAKE_BALL_FAIL,
      PLAYER_STAT.PASS_FAIL,
      PLAYER_STAT.RECEIVE_INJURY_KO,
      PLAYER_STAT.RECEIVE_INJURY_CASUALTY,
    ],
  };
};
