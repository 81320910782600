import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Interface/Button";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import {
  CAPTURE_PAYMENT_PAYPAL,
  CREATE_ORDER_PAYPAL,
} from "../../query/PaypalQueries";
import { GET_ME } from "../../query/UserQueries";
import { RootContext } from "../RootContext";
import Loading from "../Loading";

const Center = styled.div`
  text-align: center;
  margin: 0 2em;
`;

function Buy({ product, onClose, onBuy }) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const { setUser } = useContext(RootContext);

  const [
    createOrderPaypal,
    { loading: loadingCreateOrder, error: errorCreateOrder },
  ] = useMutation(CREATE_ORDER_PAYPAL);

  const [
    capturePaymentPaypal,
    { loading: loadingCapturePayment, error: errorCapturePayment },
  ] = useMutation(CAPTURE_PAYMENT_PAYPAL, {
    update(cache, { data: { capturePaymentPaypal } }) {
      const teeth = capturePaymentPaypal.tooth;
      const { me } = cache.readQuery({
        query: GET_ME,
      });
      const copyme = { ...me };
      copyme.tooth = teeth;
      setUser(me);
    },
  });

  const [{ isPending }] = usePayPalScriptReducer();

  const paypalbuttonTransactionProps = {
    style: { layout: "vertical" },
    createOrder(data, actions) {
      const order = {
        purchase_units: [
          {
            description: product.description,
            amount: {
              value: product.value,
            },
          },
        ],
      };

      return createOrderPaypal({ variables: { price: product.value } }).then(
        () => {
          return actions.order.create(order);
        }
      );
    },
    async onApprove(data, actions) {
      setError(false);

      return capturePaymentPaypal({
        variables: { orderId: data.orderID },
      }).then(({ data }) => {
        onBuy();
      });
    },
    onCancel() {},
    onError(err) {
      setError(err);
    },
  };

  return (
    <div>
      {(loadingCreateOrder || loadingCapturePayment) && <Loading />}
      {(errorCreateOrder || errorCapturePayment) && (
        <Center>{errorCreateOrder || errorCapturePayment}</Center>
      )}
      {error && <Center>{error}</Center>}
      <Center>
        <div>
          {isPending ? (
            <h2>
              {t(
                "t:shop Load Smart Payment Button",
                "__shop Load Smart Payment Button"
              )}
            </h2>
          ) : null}
          <PayPalButtons {...paypalbuttonTransactionProps} />
        </div>
      </Center>

      <Center>
        <Button onClick={onClose}>
          {t("t:choose another option", "__choose another option")}
        </Button>
      </Center>
    </div>
  );
}

export default Buy;
