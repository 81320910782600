import React from "react";
import DisplayPitch from "../../../../Stadium/DisplayPitch";

const Pitch = React.memo(
  ({ game, pitchIndicator }) => {
    return (
      <DisplayPitch
        meteo={game.meteo}
        height={game.stadium_height}
        pitchIndicator={pitchIndicator}
        width={game.stadium_width}
        types={game.stadium_type}
        states={game.stadium_state}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.game.stadium_state === nextProps.game.stadium_state &&
      prevProps.game.id === nextProps.game.id &&
      prevProps.pitchIndicator === nextProps.pitchIndicator
    );
  }
);

export default Pitch;
