import React from "react";
import styled from "styled-components";
import CustomTooltip from "../Interface/Tooltip";
import { useTranslation } from "react-i18next";
import { getMaxGfi, PLAYER_CARAC } from "enefel/player";
import { getMalusFromMoral } from "enefel/moral";
import PlayerCharac from "./PlayerCharac";
import { getNextRange, getNextRangeCoach } from "enefel/improvement";
import { BB2020Carac } from "enefel/player";
import { FaRegSmileBeam } from "react-icons/fa";
import { AiOutlineSmile } from "react-icons/ai";
import { HiOutlineFaceSmile } from "react-icons/hi2";
import { CgSmileNeutral } from "react-icons/cg";
import {
  PiSmileyNervousFill,
  PiSmileySadFill,
  PiSmileyAngryFill,
} from "react-icons/pi";

export const PopIcon = ({ value }) => {
  let icon;
  if (value >= 3) {
    icon = <FaRegSmileBeam />;
  } else if (value === 2) {
    icon = <AiOutlineSmile />;
  } else if (value === 1) {
    icon = <HiOutlineFaceSmile />;
  } else if (value === 0) {
    icon = <CgSmileNeutral />;
  } else if (value === -1) {
    icon = <PiSmileyNervousFill />;
  } else if (value === -2) {
    icon = <PiSmileySadFill />;
  } else {
    icon = <PiSmileyAngryFill />;
  }

  return (
    <CustomTooltip placement="top" title={value}>
      <span>{icon}</span>
    </CustomTooltip>
  );
};

const PlayerStatWrapper = styled.div`
  align-items: stretch;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.5em;
  text-align: center;

  h4 {
    color: ${(props) =>
      props.special ? "blue" : props.colorCarac ? props.colorCarac : null};
  }
`;

const PlayerStat = React.memo(
  ({
    name,
    player,
    prop,
    currentValue,
    special,
    tooltipText,
    value = null,
    colorCarac = "red",
  }) => {
    const displayValue =
      value !== null ? (
        BB2020Carac(value, prop)
      ) : (
        <PlayerCharac player={player} prop={prop} color={colorCarac} />
      );

    return (
      <PlayerStatWrapper special={special} colorCarac={colorCarac}>
        {!tooltipText && <h4 color={colorCarac}>{name}</h4>}
        {tooltipText && (
          <CustomTooltip placement="top" title={tooltipText}>
            <h4 color={colorCarac}>{name}</h4>
          </CustomTooltip>
        )}
        <div>
          {prop === "pop" && (
            <PlayerStatWrapper special={special} colorCarac={colorCarac}>
              <PopIcon value={value} />
            </PlayerStatWrapper>
          )}
          {prop === "pop" ? null : currentValue ===
              (value === null ? player[prop] : value) ||
            currentValue == null ? (
            displayValue
          ) : (
            <>
              {BB2020Carac(currentValue, prop)}/{displayValue}
            </>
          )}
        </div>
      </PlayerStatWrapper>
    );
  }
);

const PlayerStatsWrapper = styled.div`
  margin: 0 0 0.5em;
  white-space: nowrap;
  text-align: ${(props) => (props.centered ? "center" : null)};
`;
const PlayerStats = React.memo(
  ({
    player,
    current = true,
    gfi = false,
    centered = false,
    forPlayer = true,
    SPPCoach = true,
    colorCarac = undefined,
  }) => {
    const { t } = useTranslation();
    const timeMalus = getMalusFromMoral(player.moral);
    return (
      <div>
        <PlayerStatsWrapper centered={centered}>
          <PlayerStat
            colorCarac={colorCarac}
            tooltipText={t("t:MA Detail", "__MA Detail")}
            name={t("t:MA", "__MA")}
            player={player}
            prop={PLAYER_CARAC.MA}
            currentValue={current ? player.current_MA : null}
          />
          {gfi && (
            <PlayerStat
              colorCarac={colorCarac}
              tooltipText={t("t:GFI Detail", "__GFI Detail")}
              name={t("t:GFI", "__GFI")}
              value={getMaxGfi(player)}
              currentValue={current ? player.current_gfi : null}
            />
          )}
          <PlayerStat
            colorCarac={colorCarac}
            tooltipText={t("t:ST Detail", "__ST Detail")}
            name={t("t:ST", "__ST")}
            player={player}
            prop={PLAYER_CARAC.ST}
            currentValue={current ? player.current_ST : null}
          />
          <PlayerStat
            colorCarac={colorCarac}
            tooltipText={t("t:AG Detail", "__AG Detail")}
            name={t("t:AG", "__AG")}
            player={player}
            prop={PLAYER_CARAC.AG}
            currentValue={current ? player.current_AG : null}
          />
          <PlayerStat
            colorCarac={colorCarac}
            tooltipText={t("t:AV Detail", "__AV Detail")}
            name={t("t:AV", "__AV")}
            player={player}
            prop={PLAYER_CARAC.AV}
            currentValue={current ? player.current_AV : null}
          />
        </PlayerStatsWrapper>

        {forPlayer && (
          <PlayerStatsWrapper centered={centered}>
            <PlayerStat
              tooltipText={`${t("t:SSP Detail", "__SSP Detail")}: ${
                player.experience
              }/${getNextRange(player.experience)}`}
              name={t("t:SSP", "__SSP")}
              value={player.experience || 0}
              special={true}
            />
            {player.experienceCoach > 0 && SPPCoach && (
              <PlayerStat
                tooltipText={`${t("t:SPPCoach Detail", "__SPPCoach Detail")}: ${
                  player.experienceCoach
                }/${getNextRangeCoach(player.experienceCoach)}`}
                name={t("t:SPPCoach", "__SPPCoach")}
                value={player.experienceCoach || 0}
                special={true}
              />
            )}
            <PlayerStat
              tooltipText={t("t:POP Detail", "__POP Detail")}
              name={t("t:pop", "__pop")}
              value={player.pop || 0}
              special={true}
              prop={"pop"}
            />
            {timeMalus > 0 && (
              <PlayerStat
                tooltipText={t("t:Time malus Detail", "__Time malus Detail")}
                name={t("t:TIME", "__TIME")}
                value={`+${timeMalus}%`}
                special={true}
              />
            )}
          </PlayerStatsWrapper>
        )}
      </div>
    );
  }
);
export default PlayerStats;
