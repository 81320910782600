import { hasStatusFall, hasStatusInjury } from "enefel/status";
import React from "react";
import styled from "styled-components";
import publicPath from "../../../../../helpers/publicPath";
// import { getRandomNumber } from "enefel/random";

const Wrapper = styled.div`
  background-image: ${`url("${publicPath(
    `tile/weapon/disturbingPresence.gif`
  )}")`};
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  height: 300%;
  width: 300%;
  filter: hue-rotate(45deg);
  z-index: -1;
  pointer-events: none;
  opacity: 0.5;
  transform: translate(-33%, 41%);
`;

const DisturbingPresence = React.memo(({ player }) => {
  if (!player) {
    return null;
  }
  const isFall = hasStatusFall(player) || hasStatusInjury(player);
  if (isFall) {
    return null;
  }
  return null;
  // return <Wrapper />;
});

export default DisturbingPresence;
