import { atom, selector } from "recoil";

export const focusState = atom({
  key: "focus-state",
  default: null, // mouse over a tile
});

export const defaultMouseState = { 
  highlights: null, // a list of title (for mouvement replay)
  focusedPlayerId: null, // a player to highlight
};

export const mouseState = atom({
  key: "mouse-state",
  default: defaultMouseState,
});

export const HighlightsState = selector({
  key: "game-state-highlights",
  get: ({ get }) => {
    const state = get(mouseState);
    return state.highlights;
  },
});

export const FocusedPlayerIdState = selector({
  key: "game-state-focusedPlayerId",
  get: ({ get }) => {
    const state = get(mouseState);
    return state.focusedPlayerId;
  },
});
