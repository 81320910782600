import React from "react";
import styled from "styled-components";
import { ReactComponent as PushIcon } from "./push.svg";
import { TILE_SIZE } from "../../Game/Stadium/Stadium";

const Push = styled.div`
  position: absolute;
  top: ${(props) => (props.top != null ? props.top + "px" : null)};
  left: ${(props) => (props.left != null ? props.left + "px" : null)};
  right: ${(props) => (props.right != null ? props.right + "px" : null)};
  bottom: ${(props) => (props.bottom != null ? props.bottom + "px" : null)};
  height: ${(props) => props.size};
  opacity: 1;
  transform: ${(props) => props.rotation};
  width: ${(props) => props.size};
  filter: ${(props) => (props.isFrenzy ? "invert(1)" : null)};
`;
const offset = -90;

const IconPush = React.memo(
  ({ direction, size = TILE_SIZE, isFrenzy = false }) => {
    const iconSize = TILE_SIZE / 1.5;
    let deg, left, top, bottom, right;
    switch (direction) {
      case 1:
        deg = -45;
        bottom = 0;
        right = 0;
        break;
      case 2:
        deg = 0;
        bottom = 0;
        break;
      case 3:
        deg = 45;
        bottom = 0;
        left = 0;
        break;
      case 4:
        deg = -90;
        right = 0;
        break;
      case 5:
        deg = 90;
        left = 0;
        break;
      case 6:
        deg = -135;
        top = 0;
        right = 0;
        break;
      case 7:
        deg = 180;
        top = 0;
        break;
      case 8:
        deg = 135;
        top = 0;
        left = 0;
        break;

      default:
        deg = 0;
        break;
    }
    return (
      <Push
        isFrenzy={isFrenzy}
        size={iconSize + "px"}
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        rotation={`rotate(${deg + offset}deg)`}
      >
        <PushIcon width="100%" height="100%" />
      </Push>
    );
  }
);

export default IconPush;
