import React from "react";
import styled from "styled-components";
import { TILE_SIZE } from "../../Stadium";

const Line = styled.div`
  border: none;
  background: repeating-linear-gradient(
    45deg,
    ${(props) => props.color + "11"},
    ${(props) => props.color + "11"} 6px,
    ${(props) => props.color + "55"} 6px,
    ${(props) => props.color + "55"} 12px
  );
  height: ${(props) => (props.height != null ? props.height : 0)};
  left: ${(props) => (props.left != null ? props.left : null)};
  position: absolute;
  top: 0px;
  width: ${(props) => (props.width != null ? props.width : 0)};
  pointer-events: none;
`;

const TDZones = React.memo(
  ({ stadium_height, stadium_width, color1, color2 }) => {
    return (
      <>
        <Line
          left={0}
          width={TILE_SIZE + "px"}
          height={TILE_SIZE * stadium_height + "px"}
          color={"#" + color1}
        />

        <Line
          left={TILE_SIZE * (stadium_width - 1) + "px"}
          width={TILE_SIZE + "px"}
          height={TILE_SIZE * stadium_height + "px"}
          color={"#" + color2}
        />
      </>
    );
  }
);

export default TDZones;
