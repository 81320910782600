import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { paths } from "../../path";
import bp from "../../styles/breakpoint";
import Logo from "../Icon/Logo";
import { RootContext } from "../RootContext";
import ChangeLanguage from "./ChangeLanguage";
import Hamburger from "./Hamburger";
import Menu from "./Menu";

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  text-align: right;
  margin-right: 1em;

  @media screen and (max-width: ${bp.md}) {
    display: none;
  }
`;

const FixedPart = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${bp.md}) {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
  }

  & > * {
    &:first-child {
      margin-right: 1rem;
    }
  }
`;

const Header = React.memo(({ hasMigration = false }) => {
  const { user } = useContext(RootContext);
  const { t } = useTranslation();
  return (
    <>
      {!hasMigration && <Hamburger user={user} />}
      <Logo
        alt={t("t:EnefelPresentation", "__EnefelPresentation")}
        link={paths.index}
        img={"logo.svg"}
        width={"80px"}
        height={"80px"}
      />
      {!hasMigration && (
        <Actions>
          <Menu user={user} />
        </Actions>
      )}
      <FixedPart>
        <ChangeLanguage />
      </FixedPart>
    </>
  );
});
export default Header;
