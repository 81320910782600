import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Skills from "../Skill/Skills";

const Wrapper = styled.div`
  align-items: center;
  display: inline-flex;
  gap: 0.3em;
  white-space: nowrap;
`;

const Name = styled.div``;

const Career = React.memo(({ careerId, skills }) => {
  const { t } = useTranslation("race");
  return (
    <Wrapper>
      {skills && skills.length > 0 && (
        <Skills skills={skills} empty={null} hide={[]}></Skills>
      )}
      <Name>{t("race:" + careerId, "__" + careerId)}</Name>
    </Wrapper>
  );
});

export default Career;
