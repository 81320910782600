import React from "react";
import Countdown from "../../Date/Countdown";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import palette from "../../../styles/palette";
import CustomTooltip from "../../Interface/Tooltip";
import { TILE_SIZE } from "./Stadium";

const WrapperActionDate = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.inactif ? palette.secondary.main : palette.primary.main};
  color: white;
  cursor: default;
  display: flex;
  flex-direction: column;
  height: ${(props) => props.size + "px"};
  justify-content: center;
  width: ${(props) => props.size + "px"};
  filter: drop-shadow(1px 1px 2px black);
`;

const WrapperContent = styled.div`
  align-items: center;
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;
`;

const ActionDate = React.memo(
  ({ date, display, onComplete, reroll = null }) => {
    const { t } = useTranslation();
    let title, icon;
    switch (display) {
      case "foul":
        title = t("t:Foul", "__Foul");
        icon = t("t:FoulAbrev", "__FoulAbrev");
        break;
      case "pass":
        title = t("t:Pass", "__Pass");
        icon = t("t:PassAbrev", "__PassAbrev");
        break;
      case "transmission":
        title = t("t:Transmission", "__Transmission");
        icon = t("t:TransmissionAbrev", "__TransmissionAbrev");
        break;
      case "blitz":
        title = t("t:Blitz", "__Blitz");
        icon = t("t:BlitzAbrev", "__BlitzAbrev");
        break;
      case "reroll":
        title = t("t:Reroll", "__Reroll");
        icon = t("t:RerollSmall", "__RerollSmall");
        break;
      default:
        break;
    }
    return (
      <CustomTooltip
        placement="top"
        title={
          <WrapperContent>
            <span>{title}</span>
            {date && reroll !== 0 && (
              <Countdown date={date} onComplete={onComplete} />
            )}
          </WrapperContent>
        }
      >
        <WrapperActionDate
          inactif={reroll === 0 ? true : date ? true : false}
          size={TILE_SIZE * 0.6}
        >
          {icon}
          {reroll != null ? reroll : null}
        </WrapperActionDate>
      </CustomTooltip>
    );
  }
);

const Wrapper = styled.div`
  display: flex;
  gap: 0.5em;
`;
const TeamActions = React.memo(({ reroll, team, onComplete }) => {
  return (
    <Wrapper>
      <ActionDate
        date={team.date_next_foul}
        display={"foul"}
        onComplet={onComplete}
      />

      <ActionDate
        date={team.date_next_pass}
        display={"pass"}
        onComplet={onComplete}
      />

      <ActionDate
        date={team.date_next_transmission}
        display={"transmission"}
        onComplet={onComplete}
      />

      <ActionDate
        date={team.date_next_blitz}
        display={"blitz"}
        onComplet={onComplete}
      />

      <ActionDate
        date={team.date_next_reroll}
        display={"reroll"}
        onComplet={onComplete}
        reroll={reroll}
      />
    </Wrapper>
  );
});

export default TeamActions;
