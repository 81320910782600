import gql from "graphql-tag";

export const CREATE_PLAYER_CHAT = gql`
  mutation createPlayerChat($players: [ID]) {
    createPlayerChat(players: $players) {
      id
      players {
        id
        name
        icon_id
        teamId
        connectedAt
      }
    }
  }
`;

export const CREATE_CHAT = gql`
  mutation createChat(
    $playerId: ID
    $name: String
    $description: String
    $isPrivate: Boolean
  ) {
    createChat(
      playerId: $playerId
      name: $name
      description: $description
      isPrivate: $isPrivate
    ) {
      id
      name
      description
      isPrivate
    }
  }
`;

export const UPDATE_CHAT = gql`
  mutation updateChat(
    $playerId: ID
    $chatId: ID
    $name: String
    $description: String
    $isPrivate: Boolean
  ) {
    updateChat(
      playerId: $playerId
      chatId: $chatId
      name: $name
      description: $description
      isPrivate: $isPrivate
    ) {
      id
      name
      description
      isPrivate
    }
  }
`;

export const DELETE_CHAT = gql`
  mutation deleteChat($playerId: ID, $chatId: ID) {
    deleteChat(playerId: $playerId, chatId: $chatId) {
      id
    }
  }
`;

export const POST_MESSAGE = gql`
  mutation postMessage($playerId: ID, $chatId: ID, $message: String) {
    postMessage(playerId: $playerId, chatId: $chatId, message: $message) {
      id
      name
      description
      isPrivate
      teamId
      gameId
      team {
        id
        name
        coach_id
        coach {
          id
        }
      }
      messages {
        id
        message
        createdAt
        player {
          id
          name
          icon_id
          teamId
          connectedAt
        }
      }
    }
  }
`;

export const CHATS = gql`
  query chats($playerId: ID, $teamId: ID, $gameId: ID) {
    chats(playerId: $playerId, teamId: $teamId, gameId: $gameId) {
      id
      name
      hasNewMessages
      description
      isPrivate
    }
  }
`;

export const GET_CHAT_MESSAGES = gql`
  query chat($playerId: ID, $chatId: ID) {
    chat(playerId: $playerId, chatId: $chatId) {
      id
      name
      description
      isPrivate
      teamId
      gameId
      team {
        id
        name
        coach_id
        coach {
          id
        }
      }
      messages {
        id
        message
        createdAt
        player {
          id
          name
          icon_id
          teamId
          connectedAt
        }
      }
    }
  }
`;

export const PLAYER_CHATS = gql`
  query playerChats($playerId: ID!) {
    playerChats(playerId: $playerId) {
      id
      chats {
        id
        hasNewMessages
        players {
          id
          name
          icon_id
          teamId
          connectedAt
        }
      }
    }
  }
`;
