// https://medium.freecodecamp.org/how-to-protect-your-routes-with-react-context-717670c4713a
// https://medium.com/@ni3t/reacts-usestate-and-context-for-auth-routing-78347da1d6f
import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  setInStore,
  getCurrentPlayerStore,
  getFromStore,
  setCurrentPlayerStore,
  STORE_KEYS,
} from "../storage/Storage";
import { GET_ME } from "../query/UserQueries";
import { ACTIVE_PLAYER } from "../query/PlayerQueries";
import { PLAYER_CHATS } from "../query/ChatQueries";
export const RootContext = React.createContext();

const RootContextComponent = ({ children }) => {
  const prevToken = getFromStore(STORE_KEYS.TOKEN);

  const [token, setToken] = useState(prevToken);
  const [user, setUser] = useState();
  const [currentPlayer, setCurrentPlayer] = useState();
  const [getProfile] = useLazyQuery(GET_ME, {
    onCompleted: ({ me }) => {
      if (!user || me.email !== user.email || me.id !== user.id) {
        setUser(me);
      }
    },
    onError: () => {
      setUserToken(null);
    },
  });
  const [activePlayer, { loading }] = useMutation(ACTIVE_PLAYER);
  const [playerChats, { data: dataChat }] = useLazyQuery(PLAYER_CHATS);

  const updateCurrentPlayer = useCallback(
    (player) => {
      if (player) {
        activePlayer({
          variables: {
            playerId: player.id,
          },
        }).then((res) => {
          setCurrentPlayer(res.data.activePlayer);
          setCurrentPlayerStore(res.data.activePlayer);
        });

        playerChats({
          variables: { playerId: player.id },
        });
      } else {
        setCurrentPlayer(player);
        setCurrentPlayerStore(player);
      }
    },
    [activePlayer, playerChats]
  );

  useEffect(() => {
    const savedPlayer = getCurrentPlayerStore();
    if (!currentPlayer && savedPlayer && !loading) {
      updateCurrentPlayer(savedPlayer);
    }
  }, [updateCurrentPlayer, currentPlayer, loading]);

  useEffect(() => {
    if (token) {
      getProfile();
    }
  }, [token, getProfile]);

  useEffect(() => {
    if (currentPlayer && dataChat) {
      setCurrentPlayer({ ...currentPlayer, chats: dataChat.playerChats.chats });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChat]);

  // Call in login in logout
  const setUserToken = (newToken) => {
    setToken(newToken);
    setInStore("token", newToken);
    if (!newToken) {
      setUser(null);
      saveCurrentPlayer(null);
    }
  };

  const saveCurrentPlayer = (player) => {
    updateCurrentPlayer(player);
  };

  const defaultContext = {
    setUser,
    user,
    token,
    setUserToken,
    currentPlayer: currentPlayer,
    currentPlayerId: currentPlayer?.id,
    saveCurrentPlayer,
    updateCurrentPlayer,
  };
  return (
    <RootContext.Provider value={defaultContext}>
      {children}
    </RootContext.Provider>
  );
};

export default RootContextComponent;
