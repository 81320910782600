import React from "react";
import CountdownExternal, { zeroPad } from "react-countdown";
import styled from "styled-components";

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return null;
  } else {
    return (
      <span>
        {days === 0 ? "" : days + "d "}
        {days === 0 && hours === 0 ? "" : zeroPad(hours) + "h "}
        {days === 0 && hours === 0 && minutes === 0
          ? ""
          : zeroPad(minutes) + "m "}
        {days === 0 && hours === 0 && minutes === 0 && seconds === 0
          ? ""
          : zeroPad(seconds) + "s "}
      </span>
    );
  }
};

const Wrapper = styled.span`
  white-space: nowrap;
`;
const Countdown = React.memo(({ date, onComplete }) => {
  if (!date) {
    return null;
  }
  return (
    <Wrapper>
      <CountdownExternal
        date={date}
        renderer={renderer}
        onComplete={onComplete}
      />
    </Wrapper>
  );
});

export default Countdown;
