import React from "react";
import styled from "styled-components";

export const ButtonStyle = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  background-color: #538fbe;
  padding: 2px 7px;
  position: relative;
  border: 1px solid #2d6898;
  background: linear-gradient(rgb(73, 132, 180) 0%, rgb(97, 155, 203) 100%);
  cursor: pointer;
  border-radius: 5px;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.5);
  filter: hue-rotate(
      ${(props) =>
        props.warning ? "-225deg" : props.critical ? "278deg" : "0deg"}
    )
    grayscale(${(props) => (props.disabled ? "1" : "0")});
  box-shadow: 0px 3px 0px #2b638f, 0px 3px 15px rgba(0, 0, 0, 0.4),
    inset 0px 1px 0px rgba(255, 255, 255, 0.3),
    inset 0px 0px 3px rgba(255, 255, 255, 0.5);
  visibility: ${(props) => props.visibility};
  transition: all 0.1s ease-in-out;

  ${(props) => props.disabled} {
    &:active {
      background: linear-gradient(rgb(88, 154, 204) 0%, rgb(90, 150, 199) 100%);
      box-shadow: 0px 1px 0px #2b638f, 0px 1px 6px rgba(0, 0, 0, 0.4),
        inset 0px 1px 0px rgba(255, 255, 255, 0.3),
        inset 0px 0px 3px rgba(255, 255, 255, 0.5);
      transform: translate(0, 2px);
    }

    &:hover {
      filter: brightness(1.2);
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
  }

  & > * {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Button = React.memo(
  ({
    className,
    children,
    critical = false,
    disabled = false,
    onClick,
    type = "button",
    visibility = "visible",
    warning = false,
  }) => {
    if (critical) {
      warning = false;
    }
    return (
      <ButtonStyle
        className={className}
        critical={critical}
        disabled={disabled}
        onClick={onClick ? onClick.bind(this) : null}
        type={type}
        visibility={visibility}
        warning={warning}
      >
        {children}
      </ButtonStyle>
    );
  }
);

export default Button;
