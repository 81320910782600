import { AVATAR_LIST } from "enefel/avatars";
import { getCareers } from "enefel/career";
import memoize from "lodash/memoize";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import IconPlayer from "../Icon/IconPlayer";

import { useQuery } from "@apollo/client";
import { getCareerFromPlayer, getPlayersByState } from "enefel/race";
import { AVATAR_PRICE, STORE_ITEM_TYPE } from "enefel/store";
import { GET_MY_PLAYERS } from "../../query/UserQueries";
import QueryError from "../Error/QueryError";
import Checkbox, { Label } from "../Interface/checkbox";
import CustomTooltip from "../Interface/Tooltip";
import Loading from "../Loading";
import PlayerLink from "../Player/PlayerLink";
import Item from "./Item";

const Wrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
`;

const WrapperIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin-left: -${(props) => (props.porc ? props.porc : 0)}px;
  }
`;
const TooltipAvatar = memo(({ data, icons }) => {
  const { t } = useTranslation("badges");
  return (
    <CustomTooltip
      placement="top"
      title={
        <div>
          <div>
            <h3 style={{ color: "white" }}>
              {t(`badge:${data.rarity}`, `__${data.rarity}`)}
            </h3>
            <div>
              {t(
                `badge:${data.rarity} description`,
                `__${data.rarity} description`
              )}
            </div>
          </div>
          <br />
          <div>
            <h3 style={{ color: "white" }}>
              {t(
                "t:Avatar available for careers",
                "__Avatar available for careers"
              )}
            </h3>
            <br />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {icons}
            </div>
          </div>
        </div>
      }
    >
      <WrapperIcons>
        {t(`badge:${data.rarity}`, `__${data.rarity}`)}
      </WrapperIcons>
    </CustomTooltip>
  );
});

const getCareersFromType = memoize((type) => {
  const careers = getCareers();
  return Object.entries(careers)
    .filter(([key, values]) => {
      return values.avatars.includes(type);
    })
    .map(([key, values]) => {
      const icon = careers[key].icons[0];
      return <IconPlayer name={icon} key={"icon" + values.file} />;
    });
});

const Presentation = styled.div`
  margin: 1em 1em;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  padding: 0.6em;
  border: 1px solid green;
`;

const PlayersSelection = memo(
  ({ players, avatarPlayers, onToogleAvatarPlayers }) => {
    const { t } = useTranslation();
    if (players.length === 0) {
      return null;
    }
    return (
      <Presentation>
        <h3>
          {t(
            `t:display avatars available for your users`,
            `__display avatars available for your users`
          )}
        </h3>
        <br />
        {players.map((player) => {
          const isOn = avatarPlayers.find((p) => p.id === player.id);

          return (
            <Label key={"check" + player.id} direction={"column"}>
              <PlayerLink
                key={player.id}
                player={player}
                color={player.team ? player.team.color1 : null}
                size={"36px"}
              />

              <Checkbox
                isOn={!!isOn}
                handleToggle={() => {
                  onToogleAvatarPlayers(player.id);
                }}
              />
            </Label>
          );
        })}
      </Presentation>
    );
  }
);

const StoreAvatars = memo(({ tooth = 0, onBuy, loading, purchases }) => {
  const { t } = useTranslation();

  const [avatarPlayers, setAvatarPlayers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [typeToDisplay, setTypeToDisplay] = useState([]);
  const { loading: loadingPlayers, error, data } = useQuery(GET_MY_PLAYERS);
  const handleToogleAvatarPlayers = (playerId) => {
    const exist = avatarPlayers.find((p) => p.id === playerId);
    if (exist) {
      const newValue = avatarPlayers.filter((p) => p.id !== playerId);

      setAvatarPlayers(newValue);
    } else {
      const newValue = [
        ...avatarPlayers,
        players.find((p) => p.id === playerId),
      ];
      setAvatarPlayers(newValue);
    }
  };

  useEffect(() => {
    let type = [];
    for (const player of avatarPlayers) {
      const career = getCareerFromPlayer(player);
      type = [...type, ...career.avatars];
    }
    setTypeToDisplay(type);
  }, [avatarPlayers]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const userPlayers = getPlayersByState(
      data.me ? data.me.players : []
    ).actives;
    setPlayers(userPlayers);
    setAvatarPlayers(userPlayers);
  }, [data]);
  if (loadingPlayers) return <Loading />;
  if (error) return <QueryError error={error} />;
  const avatars = AVATAR_LIST;
  let total = 0;
  const displays = Object.entries(avatars).map(([type, avatarData]) => {
    return avatarData
      .filter((data) => {
        // Remove avatar already bougth
        const alreadyBuy = purchases.find(
          (p) => p.type === STORE_ITEM_TYPE.AVATAR && data.file === p.itemKey
        );
        return !alreadyBuy;
      })
      .map((data) => {
        const price = AVATAR_PRICE[data.rarity];
        const disabled = loading || +tooth < price;
        const icons = getCareersFromType(type);
        const hide = !(
          typeToDisplay.length === 0 || typeToDisplay.includes(type)
        );
        if (!hide) {
          total += 1;
        }
        return (
          <Item
            disabled={disabled}
            extention={""}
            hide={hide}
            itemKey={data.file}
            key={`item-avatar-${data.file}`}
            loading={loading}
            onBuy={onBuy}
            price={price}
            title={<TooltipAvatar data={data} icons={icons} />}
            type={`avatar/${type}`}
          />
        );
      });
  });

  return (
    <>
      <h2>
        <span>{t("t:Avatars", "__Avatars")}</span>
      </h2>
      <PlayersSelection
        players={players}
        avatarPlayers={avatarPlayers}
        onToogleAvatarPlayers={handleToogleAvatarPlayers}
      />
      <Wrapper>{displays}</Wrapper>
      {total === 0 && (
        <div>{t("t:no avatar available", "__no avatar available")}</div>
      )}
    </>
  );
});

export default StoreAvatars;
