import React from "react";
import { useTranslation } from "react-i18next";

const Blocked = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      {t("t:BlockedTitle", "__BlockedTitle")}
      <br />
      {t("t:BlockedDescription", "__BlockedDescription")}
    </>
  );
});
export default Blocked;
