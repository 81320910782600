import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import { RootContext } from "../../RootContext";
import Item from "./Item";
import IconStore from "../../Icon/store/IconStore";
import Box from "../../Interface/Box";
import Col from "../../Interface/Col";
import styled from "styled-components";
import IconCoinBig from "../../Icon/IconCoinBig";
import { currentGameState } from "../atoms/gameState";
import { useRecoilValue } from "recoil";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Items = styled.div`
  & > * {
    margin: 0.5rem;
  }
`;

const Welcome = styled.div`
  padding: 1rem;
  quotes: "“" "”" "‘" "’";
  &::before {
    content: open-quote;
  }
  &::after {
    content: close-quote;
  }
`;

const StorePopup = React.memo(({ team, items, handleClose, handleBuy }) => {
  const { t } = useTranslation();
  const { currentPlayer } = useContext(RootContext);
  const game = useRecoilValue(currentGameState);

  return (
    <Col>
      <Box header={t("t:shop title", "__shop title")}>
        <Center>
          <IconStore level={game.storeSize} />

          <Welcome>
            {t("t:shop seller welcome", "__shop seller welcome")}
          </Welcome>

          {currentPlayer.gameId === team.game_id && (
            <IconCoinBig po={currentPlayer.po} />
          )}
          {currentPlayer.gameId !== team.game_id && (
            <div>
              {t(
                "t:Show not available for no player",
                "__Show not available no player"
              )}
            </div>
          )}
          <Items>
            {items.map((item, i) => (
              <Item
                key={i}
                item={item}
                player={currentPlayer}
                onBuy={handleBuy}
              />
            ))}
          </Items>
        </Center>
      </Box>
    </Col>
  );
});
export default StorePopup;
