import React from "react";
import styled from "styled-components";
const Dot = styled.div`
  height: 8px;
  width: 8px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
`;

const Unread = () => {
  return <Dot />;
};

export default Unread;
