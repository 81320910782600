import React from "react";
import { useTranslation } from "react-i18next";
import DiceOrder from "./DiceOrder";
import DiceOrderSortable from "./DiceOrderSortable";
import styled from "styled-components";

const DiceOrderWrapper = styled.div`
  margin: 0.5em 0;
`;

const DiceOrderBlock = React.memo(
  ({
    attackerChoose,
    hasDauntless,
    hasFrenzy,
    order,
    orderFrenzy,
    handleChangeOrder,
    handleChangeOrderFrenzy,
    attacker,
    defender,
  }) => {
    const { t } = useTranslation();

    return (
      <>
        <DiceOrderWrapper>
          {t("t:DiceOrder", "__DiceOrder")}
          {attackerChoose || hasDauntless ? (
            <>
              {" "}
              ({t("t:DragAndDrop", "__DragAndDrop")})
              <DiceOrderSortable
                order={order}
                onChange={handleChangeOrder}
                attacker={attacker}
                defender={defender}
              />
              {!attackerChoose && hasDauntless && (
                <div>{t("t:DauntlessAvailable", "__DauntlessAvailable")}</div>
              )}
            </>
          ) : (
            <DiceOrder
              order={order}
              onChange={handleChangeOrder}
              attacker={attacker}
              defender={defender}
            />
          )}
        </DiceOrderWrapper>

        {hasFrenzy && (
          <DiceOrderWrapper>
            {t("t:DiceOrderFrenzy", "__DiceOrderFrenzy")}
            <DiceOrderSortable
              order={orderFrenzy}
              onChange={handleChangeOrderFrenzy}
              attacker={attacker}
              defender={defender}
            />
          </DiceOrderWrapper>
        )}
      </>
    );
  }
);

export default DiceOrderBlock;
