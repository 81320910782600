import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import publicPath from "../../../helpers/publicPath";
import CustomTooltip from "../../Interface/Tooltip";
import Seller from "../../Team/Staff/Seller";

const WrapperImage = styled.div`
  padding: 0.2rem;
`;
const Image = styled.div`
  text-align: center;
`;
const Title = styled.div`
  font-weight: bold;
  margin: 0.2rem 0;
`;
const DetailShop = memo(({ icon, title, description }) => {
  return (
    <WrapperImage>
      {icon && (
        <Image>
          <img
            alt={title}
            loading={"lazy"}
            src={publicPath(`tile/store/${icon}`)}
          />
        </Image>
      )}
      <Title>{title}</Title>
      <div>{description}</div>
    </WrapperImage>
  );
});
const Wrapper = styled.div`
  position: relative;
  height: ${(props) => props.h}px;
  width: ${(props) => props.w}px;
`;

const WrapperSeller = styled.div`
  position: absolute;
  top: -5px;
  left: 23px;
`;

const Tile = styled.div.attrs((props) => ({
  style: {
    background: `url("${publicPath(`tile/store/store.png`)}")`,
  },
}))`
  position: absolute;
  top: 0;
  left: 0;
  height: ${(props) => props.h}px;
  width: ${(props) => props.w}px;
  background-size: ${(props) => props.w}px ${(props) => props.h}px;
  clip-path: ${(props) => props.clip};
`;

const IconStore = React.memo(({ level = 0 }) => {
  const { t } = useTranslation();
  if (!level) return null;
  const H = 46;
  const W = 81;
  const TH = H / 2;
  const TW = W / 3;

  const getTiles = () => {
    const tiles = [];
    for (let y = 0; y < 2; y++) {
      for (let x = 0; x < 3; x++) {
        if (x === 1 && y === 0) {
          tiles.push(
            <WrapperSeller key={`store-seller`}>
              <Seller />
            </WrapperSeller>
          );
        } else {
          if (
            (x === 1 && y === 1 && level >= 1) ||
            (x === 0 && y === 1 && level >= 2) ||
            (x === 2 && y === 1 && level >= 3) ||
            (x === 0 && y === 0 && level >= 4) ||
            level >= 5
          ) {
            tiles.push(
              <Tile
                key={`store-${x}${y}`}
                h={H}
                w={W}
                clip={`polygon(${TW * x}px ${TH * y}px, ${TW * (x + 1)}px ${
                  TH * y
                }px, ${TW * (x + 1)}px ${TH * (y + 1)}px, ${TW * x}px ${
                  TH * (y + 1)
                }px)`}
              />
            );
          }
        }
      }
    }
    return tiles;
  };
  return (
    <CustomTooltip
      placement="top"
      title={
        <DetailShop
          title={t(`t:store`, `__store`)}
          description={t(`t:store description`, `__store description`)}
          icon={`seller.jpeg`}
        />
      }
    >
      <Wrapper h={H} w={W}>
        {getTiles()}
      </Wrapper>
    </CustomTooltip>
  );
});

export default IconStore;
