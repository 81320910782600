import React from "react";
import { useTranslation } from "react-i18next";

const Casuality = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      {t("t:CasualityTitle", "__CasualityTitle")}
      <br />
      {t("t:CasualityDescription", "__CasualityDescription")}
    </>
  );
});
export default Casuality;
