import gql from "graphql-tag";
import {
  PlayerRaw,
  UserBadgeRaw,
  UserCardsRaw,
  UserItemRaw,
} from "./Fragments";

export const GET_USERS = gql`
  {
    users {
      id
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_USER = gql`
  mutation register($username: String!, $email: String!, $password: String!) {
    register(username: $username, email: $email, password: $password) {
      id
      email
      username
    }
  }
`;

export const CHANGE_MAIL_INACTIVE = gql`
  mutation changeMailInactive($active: Boolean) {
    changeMailInactive(active: $active)
  }
`;

export const OPEN_BOOSTER = gql`
  mutation openBooster($itemId: String) {
    openBooster(itemId: $itemId) {
      id
      items {
        ...userItemRaw
      }
      cards {
        ...userCardsRaw
      }
    }
  }
  ${UserItemRaw}
  ${UserCardsRaw}
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!, $token: String!) {
    changePassword(password: $password, token: $token)
  }
`;

export const SEND_RESET_PASSWORD = gql`
  mutation sendResetPassword($email: String!) {
    sendResetPassword(email: $email)
  }
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const SET_BADGE_VISIBILITY = gql`
  mutation setBadgeVisibility($badgeId: String, $isVisible: Boolean) {
    setBadgeVisibility(badgeId: $badgeId, isVisible: $isVisible) {
      ...userBadgeRaw
    }
  }
  ${UserBadgeRaw}
`;

export const GET_DEBUG = gql`
  {
    me {
      id
      players {
        skills {
          skill_id
          date_next
          info
          active
          duration
          data
        }
      }
    }
  }
`;
export const GET_ME = gql`
  {
    me {
      id
      email
      username
      mail_inactive
      isAdmin
      pc
      pcTotal
      tooth
      badges {
        ...userBadgeRaw
      }
      items {
        ...userItemRaw
      }
    }
  }
  ${UserBadgeRaw}
  ${UserItemRaw}
`;

export const GET_MY_PLAYERS = gql`
  {
    me {
      id
      isAdmin
      canCreatePlayer
      badges {
        ...userBadgeRaw
      }
      players {
        ...playerRaw
        team {
          id
          name
          color1
          color2
          coach_id
        }
        chats {
          id
          hasNewMessages
          players {
            id
            name
            icon_id
            teamId
            connectedAt
          }
        }
      }
    }
  }
  ${UserBadgeRaw}
  ${PlayerRaw}
`;

export const CAN_I_CREATE_PLAYER = gql`
  {
    me {
      id
      canCreatePlayer
    }
  }
`;
