import gql from "graphql-tag";
import { PlayerRaw, TeamRaw, StatRaw, PlayerItemRaw } from "./Fragments";

export const USE_ITEM = gql`
  mutation useItem($playerId: ID, $itemId: ID) {
    useItem(playerId: $playerId, itemId: $itemId) {
      ...playerRaw
      itemResult
      items {
        ...playerItemRaw
      }
    }
  }
  ${PlayerItemRaw}
  ${PlayerRaw}
`;

export const CHANGE_AVATAR = gql`
  mutation changeAvatar($playerId: ID, $purchaseId: ID) {
    changeAvatar(playerId: $playerId, purchaseId: $purchaseId) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const EQUIP_ITEM = gql`
  mutation equipItem($playerId: ID, $itemId: ID) {
    equipItem(playerId: $playerId, itemId: $itemId) {
      ...playerRaw
      itemResult
      items {
        ...playerItemRaw
      }
    }
  }
  ${PlayerItemRaw}
  ${PlayerRaw}
`;

export const CHANGE_CAN_REROLL = gql`
  mutation canReroll($playerId: ID, $value: Boolean) {
    canReroll(playerId: $playerId, value: $value) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const CHANGE_CAN_REROLL_REBOUND = gql`
  mutation canRerollRebound($playerId: ID, $value: Boolean) {
    canRerollRebound(playerId: $playerId, value: $value) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const CHANGE_CAN_REROLL_NO_TO = gql`
  mutation canRerollNoTo($playerId: ID, $value: Boolean) {
    canRerollNoTo(playerId: $playerId, value: $value) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const CHANGE_SKILL_ACTIVATION = gql`
  mutation changeSkillActivation(
    $playerId: ID!
    $skillId: String!
    $value: String!
  ) {
    changeSkillActivation(
      playerId: $playerId
      skillId: $skillId
      value: $value
    ) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const CHANGE_SKILL_TARGET_PLAYERS = gql`
  mutation changeSkillTargetPlayers(
    $playerId: ID!
    $skillId: String!
    $targetPlayers: [ID]
  ) {
    changeSkillTargetPlayers(
      playerId: $playerId
      skillId: $skillId
      targetPlayers: $targetPlayers
    ) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const PAY_PLAYER = gql`
  mutation payPlayer($coachId: ID, $playerId: ID, $amount: Int) {
    payPlayer(coachId: $coachId, playerId: $playerId, amount: $amount) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const GET_PLAYER_REWARDS = gql`
  query playerRewards($playerId: ID!) {
    playerRewards(playerId: $playerId) {
      id
      name
      league {
        id
        name
        season
      }
    }
  }
`;

export const ACTIVE_PLAYER = gql`
  mutation activePlayer($playerId: ID) {
    activePlayer(playerId: $playerId) {
      ...playerRaw
      chats {
        id
        hasNewMessages
        players {
          id
          name
          icon_id
          teamId
          connectedAt
        }
      }
    }
  }
  ${PlayerRaw}
`;

export const CHOOSE_SKILL = gql`
  mutation chooseSkill($playerId: ID!, $skillId: String, $rollNumber: Int) {
    chooseSkill(
      playerId: $playerId
      skillId: $skillId
      rollNumber: $rollNumber
    ) {
      ...playerRaw
      improvements {
        roll_number
        d1
        d2
        skill_id
      }
      coachImprovements {
        roll_number
        d1
        d2
        skill_id
      }
    }
  }
  ${PlayerRaw}
`;

export const CHOOSE_COACH_SKILL = gql`
  mutation chooseCoachSkill(
    $playerId: ID!
    $skillId: String
    $rollNumber: Int
  ) {
    chooseCoachSkill(
      playerId: $playerId
      skillId: $skillId
      rollNumber: $rollNumber
    ) {
      ...playerRaw
      improvements {
        roll_number
        d1
        d2
        skill_id
      }
      coachImprovements {
        roll_number
        d1
        d2
        skill_id
      }
    }
  }
  ${PlayerRaw}
`;

export const CREATE_PLAYER = gql`
  mutation createPlayer($name: String!, $raceId: ID!) {
    createPlayer(name: $name, raceId: $raceId) {
      ...playerRaw
      user {
        id
        # Can we avoid this repetition for cache?
        players {
          id
        }
      }
    }
  }
  ${PlayerRaw}
`;

export const RETIREMENT_END = gql`
  mutation retirementEnd($playerId: ID!) {
    retirementEnd(playerId: $playerId) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const RETIREMENT = gql`
  mutation retirement($playerId: ID!) {
    retirement(playerId: $playerId) {
      ...playerRaw
    }
  }
  ${PlayerRaw}
`;

export const PLAYER_IMPROVEMENTS = gql`
  query playerImprovements($id: ID!) {
    player(id: $id) {
      id
      improvements {
        roll_number
        d1
        d2
        skill_id
      }
    }
  }
`;

export const COACH_IMPROVEMENTS = gql`
  query coachImprovements($id: ID!) {
    player(id: $id) {
      id
      coachImprovements {
        roll_number
        d1
        d2
        skill_id
      }
    }
  }
`;

// TODO: one query for create/join/delete?
export const CAN_PLAYER_CREATE_GAME = gql`
  query canCreateGame($id: ID!) {
    player(id: $id) {
      id
      canCreateGame
    }
  }
`;

export const CAN_PLAYER_JOIN_GAME = gql`
  query canJoinGame($id: ID!) {
    player(id: $id) {
      id
      canJoinGame
    }
  }
`;

export const CAN_PLAYER_CREATE_TEAM = gql`
  query canCreateTeam($id: ID!) {
    player(id: $id) {
      id
      canCreateTeam
    }
  }
`;

export const GET_PLAYER = gql`
  query getPlayer($id: ID!) {
    player(id: $id) {
      ...playerRaw
      team {
        ...teamRaw
      }
    }
  }
  ${PlayerRaw}
  ${TeamRaw}
`;

export const GET_PLAYER_WITH_STAT = gql`
  query getPlayerWithStat($id: ID!) {
    player(id: $id) {
      ...playerRaw
      team {
        ...teamRaw
      }
      stats {
        ...statRaw
        team {
          ...teamRaw
        }
        game {
          id
          league {
            id
            season
          }
        }
      }
    }
  }
  ${StatRaw}
  ${PlayerRaw}
  ${TeamRaw}
`;
export const GET_PLAYER_APPLICATIONS = gql`
  query getPlayer($id: ID!) {
    player(id: $id) {
      id
      postulations {
        team_id
        player_id
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PLAYER_ITEMS = gql`
  query getPlayer($id: ID!) {
    player(id: $id) {
      id
      items {
        ...playerItemRaw
      }
    }
  }
  ${PlayerItemRaw}
`;

export const GET_PLAYERS = gql`
  query getPlayers(
    $race: String
    $season: Int
    $onlyCoach: Boolean
    $state: Int
    $order: String
    $total: Int
  ) {
    players(
      race: $race
      season: $season
      onlyCoach: $onlyCoach
      state: $state
      order: $order
      total: $total
    ) {
      ...playerRaw
      team {
        ...teamRaw
      }
    }
  }
  ${PlayerRaw}
  ${TeamRaw}
`;

export const GET_PLAYERS_STATS = gql`
  query getPlayers(
    $race: String
    $season: Int
    $onlyCoach: Boolean
    $state: Int
  ) {
    players(
      race: $race
      season: $season
      onlyCoach: $onlyCoach
      state: $state
    ) {
      ...playerRaw
      team {
        ...teamRaw
      }
      stats {
        ...statRaw
        team {
          ...teamRaw
        }
        game {
          id
          league {
            id
            season
          }
        }
      }
    }
  }
  ${PlayerRaw}
  ${StatRaw}
  ${TeamRaw}
`;

export const GET_PLAYER_HISTORY = gql`
  query playerHistory($id: ID) {
    playerHistory(id: $id) {
      id
      type
      info
      createdAt
      player_id
    }
  }
`;
