import React from "react";
import styled from "styled-components";
import { TILE_SIZE } from "../../Game/Stadium/Stadium";
import publicPath from "../../../helpers/publicPath";
import { getRandomNumber } from "enefel/random";
import alea from "seedrandom/lib/alea";

const Wrapper = styled.div`
  display: inline-flex;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  background: ${(props) => `url("${publicPath(`blood/${props.name}.svg`)}")`};
  background-size: ${(props) => props.size} ${(props) => props.size};
  transform: ${(props) => `rotate(${props.rotation}deg) scale(${props.scale})`};
  opacity: ${(props) => `0.${props.opacity}`};
`;
const IconBlood = React.memo(({ x, y, gameId, size = TILE_SIZE + "px" }) => {
  const r = new alea(`${gameId}-${x}-${y}`);

  const image = getRandomNumber(1, 3, r);
  const rotation = getRandomNumber(0, 360, r);
  const opacity = getRandomNumber(3, 6, r);
  const scale = getRandomNumber(80, 120, r);

  return (
    <Wrapper
      name={`blood${image}`}
      opacity={opacity}
      rotation={rotation}
      scale={scale / 100}
      size={size}
    ></Wrapper>
  );
});

export default IconBlood;
