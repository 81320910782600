import gql from "graphql-tag";
import { PlayerRaw, TeamRaw } from "./Fragments";

export const CREATE_NEW_SEASON = gql`
  mutation createNewSeason($currentSeason: Int) {
    createNewSeason(currentSeason: $currentSeason)
  }
`;

export const CHANGE_TEAM_CHAMPIONSHIP = gql`
  mutation changeTeamChampionship($teamId: ID, $canChampionship: Boolean) {
    changeTeamChampionship(teamId: $teamId, canChampionship: $canChampionship) {
      id
      canChampionship
    }
  }
`;

export const GET_STANDINGS = gql`
  query standings($season: Int) {
    standings(season: $season) {
      id
      name
      season
      isLastSeason
      teams {
        ...teamRaw
        l
        pt
        t
        td
        tdr
        w
        players {
          ...playerRaw
        }
      }
    }
  }
  ${TeamRaw}
  ${PlayerRaw}
`;

export const GET_SCHEDULES = gql`
  query schedules($leagueIds: [ID]) {
    schedules(leagueIds: $leagueIds) {
      id
      date
      team1 {
        ...teamRaw
      }
      team2 {
        ...teamRaw
      }
      leagueId
      league {
        id
        name
      }
    }
  }
  ${TeamRaw}
`;

export const GET_CURRENT_SEASON = gql`
  {
    currentSeason {
      id
      truceDate
      isTruce
      playoff
    }
  }
`;
