import { paths } from "../../path";
import { useTranslation } from "react-i18next";
import A from "../A";
import React from "react";

export default function MenuGuest({ onClick = null, isActive = true }) {
  const { t } = useTranslation("t");
  return (
    <A
      to={paths.login}
      big={true}
      colorContrast={"primary"}
      tabIndex={isActive ? null : -1}
      onClick={onClick}
    >
      {t("t:signIn", "__Sign_in")}
    </A>
  );
}
