import styled from "styled-components";

export const Flex = styled.div`
  align-items: ${(props) => (props.align ? props.align : "center")};
  display: flex;
  flex-direction: ${(props) => (props.dir ? props.dir : "row")};
  flex-wrap: ${(props) => (props.wrap === true ? "wrap" : "nowrap")};
  gap: ${(props) => (props.gap ? props.gap : "0.5em")};
  justify-content: ${(props) => (props.justify ? props.justify : "initial")};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : "0")};
`;

export const InlineFlex = styled.span`
  align-items: ${(props) => (props.align ? props.align : "center")};
  display: inline-flex;
  flex-direction: ${(props) => (props.dir ? props.dir : "row")};
  flex-wrap: ${(props) => (props.wrap === true ? "wrap" : "nowrap")};
  gap: ${(props) => (props.gap ? props.gap : "0.5em")};
  justify-content: ${(props) => (props.justify ? props.justify : "initial")};
`;

export const LabelStyle = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;

  & > * {
    &:first-child {
      margin-right: 1rem;
    }
  }
`;
