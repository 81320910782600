import React from "react";
import { useTable, useSortBy } from "react-table";
import ReactTableContent from "./ReactTableContent";

const ReactTable = ({
  columns,
  data,
  separators,
  hidezero = false,
  hasHeader = true,
}) => {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      sortTypes: React.useMemo(
        () => ({
          caseInsensitive: (a, b, id) => {
            const valueA = a.values[id].toLowerCase();
            const valueB = b.values[id].toLowerCase();
            return valueB > valueA ? 1 : valueB < valueA ? -1 : 0;
          },
        }),
        []
      ),
    },
    // useFilters,
    useSortBy
  );

  return (
    <ReactTableContent
      getTableBodyProps={getTableBodyProps}
      getTableProps={getTableProps}
      headerGroups={headerGroups}
      hidezero={hidezero}
      prepareRow={prepareRow}
      rows={rows}
      separators={separators}
      hasHeader={hasHeader}
    />
  );
};

export default ReactTable;
