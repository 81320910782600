import React from "react";
import PassTemplate from "./Field/Pass";
import MoveAction from "./Field/Move";
import JumpAction from "./Field/Jump";
import { canMove, canBlock, canPass, canThrowTeamMate } from "enefel";
import Block from "./Field/Block";
import { useRecoilValue } from "recoil";
import {
  selectedPlayerState,
  actionState,
  currentGameState,
  isHistoryState,
} from "../atoms/gameState";

import { ACTION_LIST } from "./shared";
import ThrowTeamMateTemplate from "./Field/ThrowTeamMate";
import { GAME_STATUS } from "enefel/game";

const FieldAction = React.memo(
  ({ currentPlayerTeam, currentPlayer, players }) => {
    const action = useRecoilValue(actionState);
    const game = useRecoilValue(currentGameState);
    const selectedPlayer = useRecoilValue(selectedPlayerState);
    const isHistory = useRecoilValue(isHistoryState);
    const { stadium_height, stadium_width, ball_player_id, status } = game;
    if (!currentPlayer || status !== GAME_STATUS.ONGOING || isHistory) {
      return null;
    }
    return (
      <>
        {action === ACTION_LIST.MOVE && canMove(currentPlayer) && (
          <MoveAction
            player={currentPlayer}
            players={players}
            stadium_height={stadium_height}
            stadium_width={stadium_width}
            x={currentPlayer.x}
            y={currentPlayer.y}
          />
        )}
        {action === ACTION_LIST.JUMP &&
          canMove(currentPlayer, 2) &&
          currentPlayer.can_jump && (
            <JumpAction
              player={currentPlayer}
              players={players}
              stadium_height={stadium_height}
              stadium_width={stadium_width}
              x={currentPlayer.x}
              y={currentPlayer.y}
              game={game}
            />
          )}
        {action === ACTION_LIST.THROW_TEAM_MATE &&
          canThrowTeamMate(
            currentPlayer,
            selectedPlayer,
            currentPlayerTeam
          ) && (
            <ThrowTeamMateTemplate
              game={game}
              x1={currentPlayer.x}
              y1={currentPlayer.y}
              playerFrom={currentPlayer}
              players={players}
            />
          )}
        {action === ACTION_LIST.PASS &&
          canPass(currentPlayer, currentPlayerTeam, ball_player_id) && (
            <PassTemplate
              game={game}
              x1={currentPlayer.x}
              y1={currentPlayer.y}
              playerFrom={currentPlayer}
              players={players}
            />
          )}
        {action === ACTION_LIST.BLOCK &&
          canBlock(
            currentPlayer,
            selectedPlayer,
            currentPlayerTeam,
            game,
            false
          ) && (
            <Block
              attacker={currentPlayer}
              defender={selectedPlayer}
              players={players}
            />
          )}
      </>
    );
  }
);
export default FieldAction;
