import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";

const NoWrap = styled.div`
  align-items: center;
  display: flex;
  white-space: nowrap;
`;

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <NoWrap>
      {t("t:Enefel by", "__Enefel by")}
      <a
        href="https://www.linkedin.com/in/augen/?locale=en_US"
        target={"_blank"}
        rel="noopener noreferrer"
      >
        Manest
      </a>{" "}
      with ♥{" "}
    </NoWrap>
  );
};
export default Copyright;
