import { darken } from "polished";
import { FocusedPlayerIdState } from "../../atoms/mouseState";
import {
  gameState,
  actionState,
  selectedPlayerState,
  currentGameState,
} from "../../atoms/gameState";
import { IconBall } from "../../../Icon/IconBall";
import { getRandomNumber } from "enefel/random";
import { getHalfStadium } from "enefel/pitch";
import { hasStatusOnField, PLAYER_STATUS } from "enefel/status";
import { RootContext } from "../../../RootContext";
import { TILE_SIZE } from "../Stadium";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";

import IconPlayer from "../../../Icon/IconPlayer";
import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import PlayerMarker from "./Marker/PlayerMarker";
import alea from "seedrandom/lib/alea";
import { ACTION_LIST } from "../../Action/shared";
import ActionMarker from "./Marker/ActionMarker";
import SkillsMarker from "./Marker/SkillsMarker";
import { isSetUp } from "enefel";
import { SetupTileWrapper } from "./SetUp";
import { useMutation } from "@apollo/client";
import { SET_UP_POSITION_DUGOUT } from "../../../../query/TeamQueries";
import QueryError from "../../../Error/QueryError";
import { hasSkill, SKILL_NAMES } from "enefel/skill";
import ChainsawWeapon from "./Weapon/ChainsawWeapon";
import { useMediaQuery } from "@mui/material";
import breakpoint from "../../../../styles/breakpoint";
import FoulAppearance from "./Weapon/FoulAppearance";
import DisturbingPresence from "./Weapon/DisturbingPresence";
// import FoulAppearance from "./Weapon/FoulAppearance";

const PlayerPositionWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  filter: ${(props) =>
    props.status === PLAYER_STATUS.SENDOFF ? "grayscale(100%)" : null};
  height: ${(props) => props.size + "px"};
  justify-content: center;
  left: ${(props) => props.x * props.size + "px"};
  opacity: ${(props) => props.opacity};
  /* outline: ${(props) => (props.isCurrent ? "1px solid orange" : null)}; */
  pointer-events: ${(props) => (props.hasEvent ? null : "none")};
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  transition: top 0.2s ease, left 0.2s ease;
  width: ${(props) => props.size + "px"};
  z-index: ${(props) => 1 + props.y};

  border-radius: 50%;

  svg,
  .playerIcon {
    transform: translateY(
      -${(props) => (props.isUp ? props.size / 4 : 0) + "px"}
    );
  }

  .current_player {
    transform: translateY(
      -${(props) => (props.isUp ? props.size / 4 : 0) + "px"}
    );
  }

  ${(props) => !props.isFocused} {
    &::before {
      background: ${(props) => `#${props.color}`};
      content: "";
      /* height: 85%; */
      height: 40%;
      left: 8%;
      position: absolute;
      /* top: 43%; */
      bottom: ${(props) => -3 + (props.isUp ? props.size / 4 : 0) + "px"};
      transform: rotate3d(17, 0, 0, 46deg);
      width: 85%;
      z-index: 0;
      border-radius: 50%;
      box-shadow: 0px 1px 0px 1px ${(props) => darken(0.25, "#" + props.color)};
      animation: ${(props) =>
        props.isFocusedAnimated ? "pulsate 1s ease-out infinite" : "none"};
    }

    @keyframes pulsate {
      0% {
        box-shadow: 0px 1px 2px 0px
          ${(props) => darken(0.25, "#" + props.color)};
      }
      50% {
        box-shadow: 0px 1px 2px 8px
          ${(props) => darken(0.25, "#" + props.color)};
      }
      100% {
        box-shadow: 0px 1px 2px 0px
          ${(props) => darken(0.25, "#" + props.color)};
      }
    }
  }
`;

const CarriedBallPositionWrapper = styled.div`
  bottom: 0;
  height: ${(props) => props.size + "px"};
  position: absolute;
  right: 0;
  width: ${(props) => props.size + "px"};
`;

const PlayerPosition = React.memo(
  ({
    color,
    game,
    has_ball,
    player,
    showIcons,
    showSkills,
    showTZ = false,
    side,
    x,
    y,
    displayTooltip,
  }) => {
    const setState = useSetRecoilState(gameState);
    const action = useRecoilValue(actionState);
    const focusedPlayerId = useRecoilValue(FocusedPlayerIdState);
    const [selectedPlayer, setSelectedPlayer] =
      useRecoilState(selectedPlayerState);
    let opacity = 1;

    const { currentPlayerId } = useContext(RootContext);
    const isCurrent = currentPlayerId && player.id === currentPlayerId;

    function handleClickPlayer(p) {
      if (selectedPlayer !== p) {
        setState((s) => {
          return { ...s, action: null };
        });
      }
      setSelectedPlayer({ ...p, color });
    }
    if (game && isSetUp(game)) {
      const r = new alea(
        `${game.id}-${player.id}-${game.score1}-${game.score2}`
      );
      if (x == null || y == null) {
        const p = getHalfStadium(side, game.stadium_width);
        x = getRandomNumber(p.x, p.y, r);
        y = getRandomNumber(0, game.stadium_height - 1, r);
        opacity = 0.5;
      }
    }
    return (
      <>
        <PlayerPositionWrapper
          hasEvent={
            action !== ACTION_LIST.PASS &&
            action !== ACTION_LIST.THROW_TEAM_MATE
          }
          x={x}
          y={y}
          size={TILE_SIZE}
          onClick={() => handleClickPlayer(player)}
          isCurrent={isCurrent}
          isUp={
            player.status === PLAYER_STATUS.BONE_HEAD ||
            player.status === PLAYER_STATUS.STANDING
          }
          isFocused={focusedPlayerId === player.id || showTZ}
          isFocusedAnimated={focusedPlayerId === player.id}
          color={color}
          opacity={opacity}
        >
          {showIcons && hasStatusOnField(player) && (
            <ActionMarker player={player} size={TILE_SIZE} />
          )}
          {showSkills &&
            Object.keys(showSkills).length > 0 &&
            hasStatusOnField(player) && (
              <SkillsMarker
                showSkills={showSkills}
                player={player}
                size={TILE_SIZE}
              />
            )}
          {hasSkill(player, SKILL_NAMES.CHAINSAW) && (
            <ChainsawWeapon player={player} />
          )}
          {/* {hasSkill(player, SKILL_NAMES.FOUL_APPEARANCE) && (
            <FoulAppearance player={player} />
          )}
          {player && hasSkill(player, SKILL_NAMES.DISTURBING_PRESENCE) && (
            <DisturbingPresence player={player} />
          )} */}
          <IconPlayer
            displayTooltip={displayTooltip}
            name={player.icon_id}
            size={"100%"}
            status={player.status}
            side={side}
            color={color}
            state={player.state}
            player={player}
          />
          <PlayerMarker
            isCurrent={isCurrent}
            status={player.status}
            state={player.state}
          />
          {has_ball && (
            <CarriedBallPositionWrapper size={TILE_SIZE / 2}>
              <IconBall size={"100%"} />
            </CarriedBallPositionWrapper>
          )}
        </PlayerPositionWrapper>
      </>
    );
  }
);

export const PlayerPositions = React.memo(
  ({
    ball_player_id,
    color,
    players,
    side,
    showIcons,
    showTZ,
    game,
    showSkills,
  }) => {
    const displayTooltip = useMediaQuery(`(min-width:${breakpoint.md})`);

    return players.map((player) => (
      <PlayerPosition
        color={color}
        game={game}
        has_ball={player.id === ball_player_id}
        key={player.id}
        player={player}
        showIcons={showIcons}
        showSkills={showSkills}
        showTZ={showTZ}
        side={side}
        x={player.x}
        y={player.y}
        displayTooltip={displayTooltip}
      />
    ));
  }
);

export const DugoutPlayerPositions = React.memo(
  ({ team, side, players, dugoutWidth, color, isSetUpVisible }) => {
    const displayTooltip = useMediaQuery(`(min-width:${breakpoint.md})`);

    const [setUpPositionDugout, { error }] = useMutation(
      SET_UP_POSITION_DUGOUT
    );
    const game = useRecoilValue(currentGameState);
    const { currentPlayer, updateCurrentPlayer } = useContext(RootContext);
    const isCurrentPlayerTeam =
      currentPlayer && currentPlayer.teamId === team.id;
    const setUp = useCallback(
      (p) => {
        setUpPositionDugout({
          variables: {
            playerId: currentPlayer.id,
            targetId: p.id,
          },
        }).then(({ data }) => {
          const player = data.setUpPositionDugout.players.find(
            (p) => p.id === currentPlayer.id
          );
          if (player) {
            updateCurrentPlayer(player);
          }
        });
      },
      [setUpPositionDugout, currentPlayer, updateCurrentPlayer]
    );
    return players.map((player, index) => {
      const x = index % dugoutWidth;
      const y = Math.floor(index / dugoutWidth);
      return (
        <React.Fragment key={"dug-setup" + player.id}>
          <PlayerPosition
            color={color}
            player={player}
            side={side}
            x={x}
            y={y}
            displayTooltip={displayTooltip}
          />
          {isSetUp(game) &&
            isSetUpVisible &&
            isCurrentPlayerTeam &&
            hasStatusOnField(currentPlayer) &&
            player.status === PLAYER_STATUS.RESERVE &&
            currentPlayer.order < player.order && (
              <>
                {error && <QueryError error={error} />}
                <SetupTileWrapper
                  x={x}
                  y={y}
                  size={TILE_SIZE}
                  onClick={() => setUp(player)}
                  data-setup-position={x + "-" + y}
                >
                  {"\u00A0"}
                </SetupTileWrapper>
              </>
            )}
        </React.Fragment>
      );
    });
  }
);
