import React from "react";
import styled from "styled-components";
import Info from "./Interface/Info";

const WrapperH1 = styled.h1`
  align-items: center;
  color: ${(props) => (props.color ? props.color : null)};
  display: flex;
  gap: 0.5em;
  justify-content: ${(props) => (props.centered ? "center" : null)};
`;

const WrapperH2 = styled.h2`
  align-items: center;
  color: ${(props) => (props.color ? props.color : null)};
  display: flex;
  gap: 0.5em;
  justify-content: ${(props) => (props.centered ? "center" : null)};
`;

const WrapperH3 = styled.h3`
  align-items: center;
  color: ${(props) => (props.color ? props.color : null)};
  display: flex;
  gap: 0.5em;
  justify-content: ${(props) => (props.centered ? "center" : null)};
`;

export const H1 = React.memo(
  ({ info, children, centered = false, color = null }) => {
    return (
      <WrapperH1 centered={centered} color={color}>
        <span>{children}</span>
        <InfoTxt txt={info} />
      </WrapperH1>
    );
  }
);

export const H2 = React.memo(
  ({ info, children, centered = false, color = null }) => {
    return (
      <WrapperH2 centered={centered} color={color}>
        <span>{children}</span>
        <InfoTxt txt={info} />
      </WrapperH2>
    );
  }
);

export const H3 = React.memo(
  ({ info, children, centered = false, color = null }) => {
    return (
      <WrapperH3 centered={centered} color={color}>
        <span>{children}</span>
        <InfoTxt txt={info} />
      </WrapperH3>
    );
  }
);

const InfoTxt = React.memo(({ txt }) => {
  return txt ? (
    <Info>
      <div dangerouslySetInnerHTML={{ __html: txt }} />
    </Info>
  ) : null;
});
