import { HighlightsState } from "../../../atoms/mouseState";
import { TILE_SIZE } from "../../Stadium";
import { useRecoilValue } from "recoil";
import React from "react";
import styled from "styled-components";

const FocusedTileStyle = styled.div`
  align-items: center;
  background-color: orange;
  border-radius: 50%;
  color: black;
  font-weight: bold;
  display: flex;
  height: ${(props) => props.size + "px"};
  justify-content: center;
  left: ${(props) => props.x * props.size + "px"};
  opacity: 0.5;
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  width: ${(props) => props.size + "px"};
  z-index: 100;
`;
// render for movement
const HighlightTiles = React.memo(() => {
  const focus = useRecoilValue(HighlightsState);
  return (
    focus &&
    focus.map((tile, index) => (
      <FocusedTileStyle size={TILE_SIZE} x={tile.x} y={tile.y} key={index}>
        {focus.length > 1 && index + 1}
      </FocusedTileStyle>
    ))
  );
});

export default HighlightTiles;
