import { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import publicPath from "../helpers/publicPath";

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  align-self: center;
  flex-direction: column;
`;

const Text = styled.div`
  position: absolute;
  top: 2em;
  text-align: center;
  color: white;
  font-size: 2em;
  padding: 0.5em;
  filter: drop-shadow(1px 0 0 black) drop-shadow(-1px 0 0 black)
    drop-shadow(0 1px 0 black) drop-shadow(0 -1px 0 black);
`;

const Migration = memo(() => {
  const { t } = useTranslation();
  const src = publicPath(`interface/migration.jpg`);

  return (
    <Wrapper>
      {t("t:migrationWipDomain", "__migrationWipDomain")}{" "}
      <a href="https://enefel.a2hosted.com/">https://enefel.a2hosted.com/</a>
      <br />
      <Text>{t("t:migrationWip", "__migrationWip")}</Text>
      <img
        src={src}
        alt={t("t:migrationWip", "__migrationWip")}
        width={`100%`}
        height={`100%`}
      />
    </Wrapper>
  );
});

export default Migration;
