import React from "react";
import styled from "styled-components";
import palette from "../../styles/palette";

const ColBorder = styled.div`
  display: inline-block;
  padding: 3px;
  width: 100%;
`;

const ColContent = styled.div`
  align-items: center;
  background-color: ${palette.primary.main};
  border-radius: 11px;
  border: 1px solid white;
  border: 1px solid white;
  box-shadow: 0px 0px 0px 3px ${palette.primary.main};
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem 1rem;
  width: 100%;
`;

const ColContentWrapper = styled.div`
  align-items: center;
  border-radius: 3px;
  border: 3px solid #085334;
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.hasMax ? "399px" : null)};
  width: 100%;
`;

const Col = React.memo(
  ({ children, header, withBorder = true, hasMax = true }) => {
    const Header = header;
    return (
      <ColBorder>
        <ColContent>
          {Header && <Header />}
          {withBorder && (
            <ColContentWrapper hasMax={hasMax}>{children}</ColContentWrapper>
          )}
          {!withBorder && children}
        </ColContent>
      </ColBorder>
    );
  }
);

export default Col;
