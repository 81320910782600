import gql from "graphql-tag";

export const BUY_ITEM = gql`
  mutation buyItemStore($itemKey: String, $type: String) {
    buyItemStore(itemKey: $itemKey, type: $type) {
      id
      tooth
      purchases {
        id
        itemKey
        price
        type
      }
    }
  }
`;

export const GET_USER_PURCHASES = gql`
  {
    me {
      id
      purchases {
        id
        itemKey
        price
        type
      }
    }
  }
`;
