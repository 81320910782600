import React from "react";

export const STORE_KEYS = {
  CURRENT_PLAYER: "player",
  HIDE_TZ: "hideTZ",
  LANGUAGE: "locale",
  PITCH_INDICATOR: "pitchIndicator",
  SHOW_METEO: "showMeteo",
  SHOW_ICONS: "showIcons",
  SHOW_SKILLS: "showSkills",
  TOKEN: "token",
};

export function getFromStore(element, json = false) {
  let value = window.localStorage.getItem(element) || null;
  return json ? (value ? JSON.parse(value) : null) : value;
}
export function setInStore(element, value) {
  if (value != null) {
    window.localStorage.setItem(element, value);
  } else {
    window.localStorage.removeItem(element);
  }
}

export function useStateWithLocalStorage(
  localStorageKey,
  json = false,
  d = null
) {
  const [value, setValue] = React.useState(
    getFromStore(localStorageKey, json) || d
  );
  React.useEffect(() => {
    setInStore(localStorageKey, json ? JSON.stringify(value) : value);
  }, [value, json, localStorageKey]);
  // Warning, return a string ("false")
  return [value, setValue];
}

export function getCurrentPlayerStore() {
  return getFromStore(STORE_KEYS.CURRENT_PLAYER, true);
}

export function setCurrentPlayerStore(player) {
  setInStore(STORE_KEYS.CURRENT_PLAYER, JSON.stringify(player));
}
