export const paths = {
  charts: "/charts",
  avatars: "/avatars",
  avatarsDefault: "/avatars-default",
  careers: "/careers",
  changePassword: "/change-password",
  chat: "/chat",
  chats: "/chats",
  donate: "/donate",
  forgotPassword: "/forgot-password",
  games: "/games",
  gameShow: "/games/show",
  gamesTab: "/games/:tab?",
  index: "/",
  login: "/login",
  myUser: "/me",
  userCards: "/me/cards",
  playerNew: "/players/new",
  players: "/players",
  playerShow: "/player",
  resetPassword: "/reset-password",
  signup: "/signup",
  skills: "/skills",
  stadium: "/stadium",
  standings: "/standings",
  stats: "/stats",
  teamChat: "/chat",
  teamNew: "/teams/new",
  teams: "/teams",
  teamShow: "/team",
  playerResume: "/players/resume",
  transactions: "/transactions",
  injuryReport: "/injury-report",
  hallOfFame: "/hall-of-fame",
  store: "/store",
  cards: "/cards",
  version: "/version",
  lastPlayers: "/last-players",
};
