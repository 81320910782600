import React from "react";
import Skill from "./Skill";
import { getCareerFromKey } from "enefel/race";
import { isSpecialSkill, SKILL_NAMES } from "enefel/skill";

const SpecialSkills = React.memo(
  ({
    playerId = null,
    careerId = null,
    skills = [],
    separator = <br />,
    empty = "None",
    hide = [],
  }) => {
    let raceSkills = [];
    if (careerId) {
      const career = getCareerFromKey(careerId);
      raceSkills = career ? career.skills : [];
    }
    const animosities = skills.filter(
      (s) => s.skill_id === SKILL_NAMES.ANIMOSITY
    );
    const list = skills
      .filter(
        (s) =>
          isSpecialSkill(s) &&
          !hide.includes(s.skill_id) &&
          s.skill_id !== SKILL_NAMES.ANIMOSITY
      )
      .map((s) => {
        let info = null;
        if (Array.isArray(s)) {
          info = s[1];
          s = s[0];
        }
        const key = `${s.skill_id ? s.skill_id : s}-${playerId}-${s.info}`;
        const isNew =
          careerId && raceSkills !== undefined
            ? !raceSkills.some(
                (r) =>
                  r === s.skill_id || (Array.isArray(r) && r[0] === s.skill_id)
              )
            : false;
        return (
          <Skill key={key} skill={s} isNew={isNew} info={info || s.info} />
        );
      });

    if (animosities.length > 0) {
      list.push(
        <Skill
          skill={SKILL_NAMES.ANIMOSITY}
          info={animosities.map((a) => a.info)}
        />
      );
    }

    return list && list.length
      ? list.reduce((prev, curr, i) => {
          if (prev) {
            return [
              prev,
              <React.Fragment key={`${i}-skill-special-${playerId}`}>
                {separator}
              </React.Fragment>,
              curr,
            ];
          } else {
            return curr;
          }
        }, null)
      : empty;
  }
);

export default SpecialSkills;
