import { PRODUCTS } from "enefel/store";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../Interface/Button";
import Buy from "./Buy";
import Product from "./Product";

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  grid-gap: 1.2em;
  padding: 1em;
`;

function Products({ selectedProduct, onSelectProduct, onBuy }) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    onSelectProduct(null);
    setOpen(false);
  };

  const handleClickOpen = (product) => {
    onSelectProduct(product);
    setOpen(true);
  };

  if (open && selectedProduct) {
    return (
      <Buy product={selectedProduct} onClose={handleClickClose} onBuy={onBuy} />
    );
  }
  return (
    <Wrapper>
      {Object.values(PRODUCTS).map((p) => {
        return (
          <Product product={p} key={`product-${p.teeth}`}>
            <Button onClick={() => handleClickOpen(p)}>
              {t("t:EUR", "__EUR")} {p.value}
            </Button>
          </Product>
        );
      })}
    </Wrapper>
  );
}

export default Products;
