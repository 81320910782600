import { memo } from "react";
import { GiTooth } from "react-icons/gi";
import styled from "styled-components";

const Icon = styled.span`
  font-size: 1.5em;
  filter: drop-shadow(-2px 6px 3px red);
`;

const IconTooth = memo(() => {
  return (
    <Icon>
      <GiTooth />
    </Icon>
  );
});

export default IconTooth;
