import { Link, useHistory, useLocation } from "react-router-dom";
import { paths } from "../../path";
import { RootContext } from "../RootContext";
import { TeamIcon, StadiumIcon } from "../Icon/menu/menu";
import { useApolloClient, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import bp from "../../styles/breakpoint";
import IconPlayer from "../Icon/IconPlayer";
import palette from "../../styles/palette";
import React, { useContext } from "react";
import styled from "styled-components";
import Loading from "../Loading";
import QueryError from "../Error/QueryError";
import { GET_PLAYER } from "../../query/PlayerQueries";
import { isPlaying } from "enefel/player";
import { MdPerson, MdVpnKey } from "react-icons/md";
import { AiOutlineShop } from "react-icons/ai";

export const BottomNavigationStyle = styled(BottomNavigation)(({ theme }) => ({
  width: "100%",
  background: `linear-gradient(${palette.primary.dark}, ${palette.primary.main})`,
}));

export const BottomNavigationActionStyle = styled(BottomNavigationAction)(
  ({ theme }) => ({
    color: palette.secondary.contrastText,
    opacity: 0.6,
    textAlign: "center",

    // TODO: fix this
    "& .Mui-selected": {
      color: palette.secondary.contrastText,
      opacity: 1,
    },
  })
);

const Menu = React.memo(({ value, links, onChange }) => {
  return (
    <>
      <BottomNavigationStyle showLabels value={value} onChange={onChange}>
        {links.map((link) => (
          <BottomNavigationActionStyle
            key={link.to}
            component={Link}
            icon={link.icon}
            to={link.to}
            label={link.label}
          />
        ))}
      </BottomNavigationStyle>
    </>
  );
});

const BottomNavSignIn = React.memo(({ user }) => {
  const { t } = useTranslation("t");
  const { currentPlayerId, setUserToken } = useContext(RootContext);
  const { loading, error, data } = useQuery(GET_PLAYER, {
    variables: { id: currentPlayerId || -1 },
  });

  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  if (loading) return <Loading />;
  if (error) return <QueryError error={error} />;
  const currentPlayer = data.player;

  function onChange(event, newValue) {
    if (links[newValue].to === paths.login) {
      setUserToken(null);
      client.clearStore();
      history.push(paths.login);
    }
  }

  const links = [
    {
      to: paths.myUser,
      icon: <MdPerson style={{ fontSize: "1.5em" }} />,
      label: user.username,
    },
  ];
  if (currentPlayer) {
    links.push({
      to: paths.playerShow + "/" + currentPlayer.id,
      icon: <IconPlayer name={currentPlayer.icon_id} />,
      label: currentPlayer.name,
    });
    if (currentPlayer.teamId) {
      links.push({
        to: paths.teamShow + "/" + currentPlayer.teamId,
        icon: <TeamIcon />,
        label: t("t:My team", "__My Team"),
      });
    }
    if (isPlaying(currentPlayer)) {
      links.push({
        to: paths.gameShow + "/" + currentPlayer.gameId,
        icon: <StadiumIcon />,
        label: t("t:My game", "__My game"),
      });
    }
    links.push({
      to: paths.store,
      icon: <AiOutlineShop style={{ fontSize: "1.5em" }} />,
      label: t("t:Shop", "__Shop"),
    });
  }
  let value = links.findIndex((l) => l.to === location.pathname);
  return <Menu value={value} links={links} onChange={onChange} />;
});

const BottomNavGuest = React.memo(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const links = [
    {
      to: paths.players,
      icon: <MdPerson />,
      label: t("t:players", "__Players"),
    },
    {
      to: paths.games,
      icon: <StadiumIcon />,
      label: t("t:games", "__Games"),
    },
    {
      to: paths.teams,
      icon: <TeamIcon />,
      label: t("t:teams", "__Teams"),
    },
    {
      to: paths.login,
      icon: <MdVpnKey />,
      label: t("t:login", "__Login"),
    },
  ];
  let value = links.findIndex((l) => l.to === location.pathname);
  return <Menu value={value} links={links} />;
});

// const WarningWrapper = styled.div`
//   background-color: red;
//   bottom: 0px;
//   color: white;
//   font-size: 0.9em;
//   left: 0px;
//   padding: 2px 1rem;
//   text-align: center;
// `;

// const Warning = React.memo(() => {
//   const { t } = useTranslation();
//   return <WarningWrapper>{t("t:end alpha", "__end alpha")}</WarningWrapper>;
// });

const Nav = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 5000;
`;

const FooterContent = styled.div`
  @media screen and (min-width: ${bp.md}) {
    display: none;
  }
`;

const BottomNav = React.memo(() => {
  const { user } = useContext(RootContext);

  return (
    <Nav>
      <FooterContent>
        {user ? <BottomNavSignIn user={user} /> : <BottomNavGuest />}
      </FooterContent>
      {/* <Flash /> */}
    </Nav>
  );
});

export default BottomNav;
