import React, { useMemo } from "react";
import ReactTable from "../Table/ReactTable";
import StatHeader from "./StatHeader";

const StatsTable = ({ info, data, fields, hasHeader = true }) => {
  const sizes = [info[0].columns.length];

  const headers = Object.keys(fields).map((p) => {
    sizes.push(
      fields[p].length + (sizes.length > 0 ? sizes[sizes.length - 1] : 0)
    );
    return {
      Header: p,
      columns: fields[p].map((field) => ({
        Header: () => <StatHeader field={field} />,
        accessor: field,
      })),
    };
  });

  const columns = useMemo(() => info.concat(headers), [info, headers]);

  return (
    <ReactTable
      columns={columns}
      data={data}
      separators={sizes}
      hidezero={true}
      hasHeader={hasHeader}
    />
  );
};

export default StatsTable;
