import React from "react";
import styled from "styled-components";
import { TILE_SIZE } from "../../Game/Stadium/Stadium";
import publicPath from "../../../helpers/publicPath";
import { TILES_STADIUM } from "enefel/stadium";
import { METEO } from "enefel/meteo";

const Wrapper = styled.div.attrs((props) => ({
  // style: {
  //   background: `url("${publicPath(`tile/field/${props.name}`)}")`,
  // },
}))`
  background: url("${publicPath(`tile/field/field.png`)}") -${(props) =>
      props.size * props.x}px -${(props) => props.size * props.y}px;
  display: inline-flex;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  /* background-size: ${(props) => props.size} ${(props) => props.size}; */
  filter: ${(props) => (props.isSnow ? "grayscale(1) invert()" : null)};
`;
const IconField = React.memo(({ type, state, meteo, size = TILE_SIZE }) => {
  const tile = TILES_STADIUM[type];
  if (!tile) {
    console.error("stadium type error :", type);
    return null;
  }
  let iconIndex = tile.icons.findIndex((k) => {
    return +k > +state;
  });
  if (iconIndex === -1) {
    iconIndex = tile.icons.length;
  }
  iconIndex -= 1;
  return (
    <Wrapper
      x={iconIndex}
      y={tile.y}
      size={size}
      name={tile.icons[iconIndex]}
      isSnow={meteo === METEO.HEAVY_SNOW}
    ></Wrapper>
  );
});

export default IconField;
