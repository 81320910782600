import React from "react";
import { useTranslation } from "react-i18next";

const Rookie = React.memo(() => {
  const { t } = useTranslation();
  return (
    <>
      {t("t:RookieTitle", "__RookieTitle")}
      <br />
      {t("t:RookieDescription", "__RookieDescription")}
    </>
  );
});
export default Rookie;
