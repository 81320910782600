import React from "react";
import styled from "styled-components";

import { IconBall } from "../../../../Icon/IconBall";
import { TILE_SIZE } from "../../Stadium";

const BallPositionWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${(props) => props.size + "px"};
  justify-content: center;
  left: ${(props) => props.x * props.size + "px"};
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  transition: top 0.2s ease, left 0.2s ease;
  width: ${(props) => props.size + "px"};
  z-index: 2;
`;
const Ball = React.memo(({ x, y }) => {
  if (x == null || y == null) {
    return null;
  }
  return (
    <BallPositionWrapper x={x} y={y} size={TILE_SIZE}>
      <IconBall size={"50%"} />
    </BallPositionWrapper>
  );
});

export default Ball;
