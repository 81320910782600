import React from "react";
import styled from "styled-components/macro";
import {
  coordinatesConvertorX,
  coordinatesConvertorY,
  getIndexFromXY,
} from "enefel/pitch";

import { TILE_SIZE } from "../Game/Stadium/Stadium";
import IconField from "../Icon/field/IconField";

// TODO:  https://kovart.github.io/dashed-border-generator/
const TileStyle = styled.div`
  position: relative;
  display: inline-block;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  text-align: center;
  outline: ${(props) =>
    props.pitchIndicator ? `1px dashed #005000c9;` : "none"};
  pointer-events: ${(props) => (props.isInteractive ? "auto" : "none")};
`;

const RowStyle = styled.div`
  display: flex;
  position: relative;
  white-space: nowrap;
`;

const WrapperNewTile = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  user-select: none;
  width: 100%;
`;

const DisplayPitch = React.memo(
  ({
    meteo,
    height,
    width,
    types,
    states,
    pitchIndicator,
    onClick = null,
    onMouseEnter = null,
    initialState = null,
    initialType = null,
  }) => {
    let stadium = [];

    for (let y = 0; y < height; y++) {
      let row = [];
      for (let x = 0; x < width; x++) {
        const index = getIndexFromXY(x, y, width);
        const isNewTile =
          (initialType != null && initialType[index] !== types[index]) ||
          (initialState != null && initialState[index] !== states[index]);
        row.push(
          <TileStyle
            data-position={x + "-" + y}
            key={"tile-" + x + "-" + y}
            size={TILE_SIZE + "px"}
            title={coordinatesConvertorX(x) + " " + coordinatesConvertorY(y)}
            pitchIndicator={pitchIndicator}
            isInteractive={onClick ? true : false}
            onMouseDown={
              onClick
                ? () => {
                    onClick({ x, y });
                  }
                : null
            }
            onMouseEnter={
              onMouseEnter
                ? (e) => {
                    onMouseEnter(e, { x, y });
                  }
                : null
            }
          >
            {isNewTile && <WrapperNewTile>-</WrapperNewTile>}
            <IconField
              meteo={meteo}
              type={types[index]}
              state={states[index]}
            />
          </TileStyle>
        );
      }
      stadium.push(<RowStyle key={"row-" + y}>{row}</RowStyle>);
    }
    return stadium;
  }
);

export default DisplayPitch;
