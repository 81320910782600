import { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import publicPath from "../../helpers/publicPath";
import Box from "../Interface/Box";
import ButtonConfirm from "../Interface/ButtonConfirm";
import Col from "../Interface/Col";
import AvatarImg from "../Player/Avatar/AvatarImg";
import ButtonBuy from "./ButtonBuy";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const AVATAR_SIZE_BIG = 280;
export const AVATAR_SIZE = 150;

export const AvatarImage = styled.div`
  width: ${(props) => props.width || AVATAR_SIZE}px;
  height: ${(props) => props.height || AVATAR_SIZE}px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #9dff8c, #164e19) border-box;
  border-radius: 5px;
  border: ${(props) => (props.border == null ? 3 : props.border)}px solid
    transparent;
  position: relative;
  filter: ${(props) => (props.isUsed === true ? "grayscale(1)" : null)};
  &::after {
    box-shadow: inset 0px 0px 3px 2px #000000;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  & > img {
    border-radius: 1px;
    width: 100%;
    height: 100%;
  }
`;

const Description = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  margin-bottom: 0.3rem;
  max-width: 15rem;
`;

const Item = memo(
  ({
    description = "",
    disabled,
    extention = ".jpeg",
    hide = false,
    itemKey,
    loading,
    onBuy,
    price,
    title,
    type,
  }) => {
    const { t } = useTranslation();
    const src = publicPath(`tile/shop/${type}/${itemKey}${extention}`);

    return (
      <Wrapper
        style={{
          display: hide ? "none" : "block",
        }}
      >
        <Col>
          <Box header={title}>
            <Wrapper>
              <AvatarImg alt={type} src={src} />

              {description && <Description>{description}</Description>}
              <ButtonConfirm
                disabled={disabled}
                loading={loading}
                onAction={() => onBuy(itemKey)}
                txt={<ButtonBuy price={price} />}
                txtConfirm={t("t:Sure?", "__Sure?")}
                warningStyle={false}
              />
            </Wrapper>
          </Box>
        </Col>
      </Wrapper>
    );
  }
);

export default Item;
