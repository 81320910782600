import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import palette from "../styles/palette";

export const MainLink = styled(NavLink)`
  align-items: baseline;
  color: ${(prop) => (prop.color ? prop.color : null)};
  display: inline-flex;
  font-weight: bold;
  opacity: 0.8;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.3s ease;

  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: underline;
  }
`;

export const Link = styled(NavLink)`
  align-items: baseline;
  color: ${(prop) => (prop.color ? prop.color : palette.secondary.main)};
  display: inline-flex;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const A = React.memo(
  ({
    to,
    children,
    big = false,
    onClick = null,
    colorContrast = false,
    target = null,
    tabIndex = null,
    className,
    color = null,
  }) => {
    if (!color && colorContrast) {
      color = palette[colorContrast].contrastLink;
      if (!color) {
        color = palette[colorContrast].contrastText;
      }
    }
    return big ? (
      <MainLink
        target={target}
        onClick={onClick ? onClick.bind(this) : null}
        to={to}
        color={color}
        activeStyle={{ opacity: 1, textDecoration: "underline" }}
        tabIndex={tabIndex}
        className={className}
      >
        {children}
      </MainLink>
    ) : (
      <Link
        target={target}
        color={color}
        onClick={onClick ? onClick.bind(this) : null}
        to={to}
        tabIndex={tabIndex}
        className={className}
      >
        {children}
      </Link>
    );
  }
);

export default A;
