import { memo } from "react";
import styled from "styled-components";
import IconTooth from "./IconTooth";

const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;

  .icon {
    position: relative;
    top: 2px;
  }
`;

const ButtonBuy = memo(({ price }) => {
  return (
    <Wrapper>
      <span className={"icon"}>
        <IconTooth />
      </span>
      <span>{price}</span>
    </Wrapper>
  );
});

export default ButtonBuy;
