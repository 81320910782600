import { useTranslation } from "react-i18next";
import React from "react";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
import { Label } from "../Interface/checkbox";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_SEASON } from "../../query/StandingQueries";
import Loading from "../Loading";
import QueryError from "../Error/QueryError";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getRaceFromCareer } from "enefel/race";
import { getCareers } from "enefel/career";
import { SelectStyled } from "../Select";
import { PLAYER_STATE } from "enefel/state";

const Small = styled.div`
  font-size: 0.8em;
`;

const FiltersWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1em;

  .stat {
    margin-left: auto;
  }
`;

const BlockWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 1em;
`;

const Filter = React.memo(
  ({
    filter,
    onChange,
    withStat = true,
    withCoach = true,
    withActif = true,
    withSeason = true,
  }) => {
    const { t } = useTranslation();

    const {
      loading: loadingSeason,
      error: errorSeason,
      data: dataSeason,
    } = useQuery(GET_CURRENT_SEASON);

    const handleChangeRace = (race) => {
      onChange({ race: race.target.value });
    };

    const handleChangeSeason = (season) => {
      onChange({ season: season.target.value });
    };

    const handleActiveChange = (v) => {
      onChange({ state: v.target.checked ? PLAYER_STATE.ACTIVE : null });
    };

    const handleIsCoachChange = (v) => {
      onChange({ onlyCoach: v.target.checked });
    };

    const handleChangeStats = (event, v) => {
      onChange({ stats: v });
    };

    const races = {};
    Object.keys(getCareers()).forEach((key) => {
      const race = getRaceFromCareer(key);
      if (!races[race]) {
        races[race] = true;
      }
    });
    const currentSeason = dataSeason ? +dataSeason.currentSeason.id : "all";

    if (errorSeason) return <QueryError error={errorSeason} />;
    return (
      <FiltersWrapper>
        <Label>
          <span>{t("t:race", "__race")}</span>
          <SelectStyled
            value={filter.race}
            onChange={handleChangeRace}
            inputProps={{
              "aria-label": t("t:Select Carriere", "__Select Carriere"),
            }}
            variant="standard"
          >
            <MenuItem value={"all"} key={"all"}>
              {t("t:all", "__all")}
            </MenuItem>
            {Object.keys(races)
              .sort((c1, c2) =>
                t("race:" + c1, "__" + c1).localeCompare(
                  t("race:" + c2, "__" + c2)
                )
              )
              .map((careerKey) => (
                <MenuItem value={careerKey} key={careerKey}>
                  {t("race:" + careerKey, "__" + careerKey)}
                </MenuItem>
              ))}
          </SelectStyled>
        </Label>
        {withSeason && (
          <Label>
            <span>{t("t:season", "__season")}</span>
            {loadingSeason ? (
              <Loading />
            ) : (
              <SelectStyled
                value={filter.season}
                onChange={handleChangeSeason}
                inputProps={{
                  "aria-label": t("t:Select Season", "__Select Season"),
                }}
                variant="standard"
              >
                <MenuItem value={0} key={"all"}>
                  {t("t:all", "__all")}
                </MenuItem>

                {[...Array(currentSeason)].map((_, index) => (
                  <MenuItem
                    value={currentSeason - index}
                    key={currentSeason - index}
                  >
                    {currentSeason - index}
                  </MenuItem>
                ))}
              </SelectStyled>
            )}
          </Label>
        )}
        {withCoach && (
          <Label>
            <span>{t("t:only coach", "__only coach")}</span>

            <input
              type="checkbox"
              checked={filter.onlyCoach}
              onChange={handleIsCoachChange}
            />
          </Label>
        )}
        {withActif && (
          <Label>
            <div>
              {t("t:only active", "__only active")}
              <Small>{t("t:long time", "__long time")}</Small>
            </div>

            <input
              type="checkbox"
              checked={filter.state === PLAYER_STATE.ACTIVE}
              onChange={handleActiveChange}
            />
          </Label>
        )}
        {withStat && (
          <BlockWrapper className="stat">
            <label>{t("t:stats", "__stats")}</label>
            <ToggleButtonGroup
              size="small"
              value={filter.stats}
              exclusive
              onChange={handleChangeStats}
              aria-label={t("t:stats", "__stats")}
            >
              <ToggleButton
                value={0}
                aria-label={t("t:without stats", "__stats")}
              >
                {t("t:without stats", "__withoutstats")}
              </ToggleButton>
              <ToggleButton
                value={1}
                aria-label={t("t:standard stats", "__standard stats")}
              >
                {t("t:standard stats", "__standard stats")}
              </ToggleButton>
              <ToggleButton
                value={2}
                aria-label={t("t:expanded stats", "__expanded stats")}
              >
                {t("t:expanded stats", "__expanded stats")}
              </ToggleButton>
            </ToggleButtonGroup>
          </BlockWrapper>
        )}
      </FiltersWrapper>
    );
  }
);

export default Filter;
