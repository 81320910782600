import { ACTION_MOVE } from "../../../../query/ActionQueries";
import { getPlayerOnCell, isOnField, p } from "enefel";
import { TILE_SIZE } from "../../Stadium/Stadium";
import { useMutation } from "@apollo/client";
import IconMove from "../../../Icon/action/IconMove";
import QueryError from "../../../Error/QueryError";
import React from "react";
import styled from "styled-components";
import { bonusDodge } from "enefel/move";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fumbleBallState, gameState } from "../../atoms/gameState";
import { getEnemyTackleZones } from "enefel/position";
import { SKILL_NAMES, hasSkillForPlayer } from "enefel/skill";

const ButtonWrapper = styled.div`
  align-items: center;
  background-color: "tranparent";
  cursor: pointer;
  display: flex;
  height: ${(props) => props.size + "px"};
  justify-content: center;
  left: ${(props) => props.x * props.size + "px"};
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  width: ${(props) => props.size + "px"};
  z-index: 2;
`;

const Arrow = React.memo(
  ({
    dice,
    direction,
    gfi,
    hasToDodge,
    onClick,
    player,
    players,
    size,
    x,
    y,
    withStar = false,
  }) => {
    const teamId = player.teamId;
    let bonus = hasToDodge
      ? bonusDodge(
          player,
          getEnemyTackleZones(p(player.x, player.y), teamId, players),
          getEnemyTackleZones(p(x, y), teamId, players)
        )
      : null;

    let result = null;
    if (bonus !== null) {
      result = dice - bonus;
      if (result > 6) {
        result = 6;
      }
      if (result < 2) {
        result = 2;
      }
      result = `${result}+${withStar ? "*" : ""}`;
    }

    return (
      <ButtonWrapper x={x} y={y} size={size} gfi={gfi} onClick={onClick}>
        <IconMove direction={direction} gfi={gfi} result={result} />
      </ButtonWrapper>
    );
  }
);

const MoveAction = React.memo(
  ({ player, x, y, players, stadium_width, stadium_height }) => {
    const [actionMove, { loading, error }] = useMutation(ACTION_MOVE);
    const setGameState = useSetRecoilState(gameState);
    const fumble = useRecoilValue(fumbleBallState);

    function canMove(targetX, targetY) {
      return (
        !getPlayerOnCell(targetX, targetY, players) &&
        isOnField(targetX, targetY, stadium_width, stadium_height)
      );
    }

    function move(targetX, targetY) {
      actionMove({
        variables: {
          playerId: player.id,
          x: targetX,
          y: targetY,
          fumble,
        },
      });
      setGameState((s) => ({ ...s, fumbleBall: false }));
    }
    const gfi = +player.current_MA === 0 && player.current_gfi > 0;
    const hasMove = +player.current_MA > 0 || +player.current_gfi > 0;
    let hasToDodge = false;
    const formatedPlayers = players.reduce((obj, item) => {
      return {
        ...obj,
        [item.id]: item,
      };
    }, {});
    let dice;
    let divingTacklePlayer = false;
    if (hasMove) {
      const zdt = getEnemyTackleZones(player, player.teamId, formatedPlayers);
      hasToDodge = zdt.length > 0;
      divingTacklePlayer = zdt.find((p) =>
        hasSkillForPlayer(p, player, SKILL_NAMES.DIVING_TACKLE)
      );
      if (player.current_AG >= 6) {
        dice = 1;
      } else if (player.current_AG >= 5) {
        dice = 2;
      } else if (player.current_AG >= 4) {
        dice = 3;
      } else if (player.current_AG >= 3) {
        dice = 4;
      } else if (player.current_AG >= 2) {
        dice = 5;
      } else {
        dice = 6;
      }
    }
    return (
      <>
        {error && <QueryError error={error} />}
        {loading
          ? null // <Loading />
          : hasMove && (
              <>
                {canMove(x - 1, y - 1) && (
                  <Arrow
                    direction={1}
                    x={x - 1}
                    y={y - 1}
                    onClick={move.bind(this, x - 1, y - 1)}
                    size={TILE_SIZE}
                    gfi={gfi}
                    player={player}
                    players={formatedPlayers}
                    hasToDodge={hasToDodge}
                    dice={dice}
                    withStar={!!divingTacklePlayer}
                  />
                )}
                {canMove(x, y - 1) && (
                  <Arrow
                    direction={2}
                    x={x}
                    y={y - 1}
                    onClick={move.bind(this, x, y - 1)}
                    size={TILE_SIZE}
                    gfi={gfi}
                    player={player}
                    players={formatedPlayers}
                    hasToDodge={hasToDodge}
                    dice={dice}
                    withStar={!!divingTacklePlayer}
                  />
                )}
                {canMove(x + 1, y - 1) && (
                  <Arrow
                    direction={3}
                    x={x + 1}
                    y={y - 1}
                    onClick={move.bind(this, x + 1, y - 1)}
                    size={TILE_SIZE}
                    gfi={gfi}
                    player={player}
                    players={formatedPlayers}
                    hasToDodge={hasToDodge}
                    dice={dice}
                    withStar={!!divingTacklePlayer}
                  />
                )}
                {canMove(x - 1, y) && (
                  <Arrow
                    direction={4}
                    x={x - 1}
                    y={y}
                    onClick={move.bind(this, x - 1, y)}
                    size={TILE_SIZE}
                    gfi={gfi}
                    player={player}
                    players={formatedPlayers}
                    hasToDodge={hasToDodge}
                    dice={dice}
                    withStar={!!divingTacklePlayer}
                  />
                )}
                {canMove(x + 1, y) && (
                  <Arrow
                    direction={5}
                    x={x + 1}
                    y={y}
                    onClick={move.bind(this, x + 1, y)}
                    size={TILE_SIZE}
                    gfi={gfi}
                    player={player}
                    players={formatedPlayers}
                    hasToDodge={hasToDodge}
                    dice={dice}
                    withStar={!!divingTacklePlayer}
                  />
                )}
                {canMove(x - 1, y + 1) && (
                  <Arrow
                    direction={6}
                    x={x - 1}
                    y={y + 1}
                    onClick={move.bind(this, x - 1, y + 1)}
                    size={TILE_SIZE}
                    gfi={gfi}
                    player={player}
                    players={formatedPlayers}
                    hasToDodge={hasToDodge}
                    dice={dice}
                    withStar={!!divingTacklePlayer}
                  />
                )}
                {canMove(x, y + 1) && (
                  <Arrow
                    direction={7}
                    x={x}
                    y={y + 1}
                    onClick={move.bind(this, x, y + 1)}
                    size={TILE_SIZE}
                    gfi={gfi}
                    player={player}
                    players={formatedPlayers}
                    hasToDodge={hasToDodge}
                    dice={dice}
                    withStar={!!divingTacklePlayer}
                  />
                )}
                {canMove(x + 1, y + 1) && (
                  <Arrow
                    direction={8}
                    x={x + 1}
                    y={y + 1}
                    onClick={move.bind(this, x + 1, y + 1)}
                    size={TILE_SIZE}
                    gfi={gfi}
                    player={player}
                    players={formatedPlayers}
                    hasToDodge={hasToDodge}
                    dice={dice}
                    withStar={!!divingTacklePlayer}
                  />
                )}
              </>
            )}
      </>
    );
  }
);

export default MoveAction;
