import { useTranslation } from "react-i18next";
import CustomTooltip from "../../Interface/Tooltip";
import Dialog from "@mui/material/Dialog";
import React from "react";
import styled from "styled-components";
import { TILE_SIZE } from "../Stadium/Stadium";
import ShowSkills from "./ShowSkills";

const StatsButton = styled.div`
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.size * 0.6 + "px"};
  height: ${(props) => props.size * 0.6 + "px"};
  justify-content: center;
  line-height: ${(props) => props.size * 0.6 + "px"};
  width: ${(props) => props.size * 0.6 + "px"};
`;

const DialogShowSkills = React.memo(
  ({ showSkills, handleToggleSkill, handleSkillColorChange }) => {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <CustomTooltip
          placement="top"
          title={t("t:Show skills", "__Show skills")}
        >
          <StatsButton onClick={handleClickOpen} size={TILE_SIZE}>
            <span role="img" aria-label={t("t:Show skills", "__Show skills")}>
              💪
            </span>
          </StatsButton>
        </CustomTooltip>
        <Dialog onClose={handleClose} open={open}>
          <ShowSkills
            showSkills={showSkills}
            handleToggleSkill={handleToggleSkill}
            handleSkillColorChange={handleSkillColorChange}
          />
        </Dialog>
      </>
    );
  }
);

export default DialogShowSkills;
