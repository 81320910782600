import React from "react";
import Error from "./Error";

const QueryError = ({ error }) => {
  return (
    <Error>
      {error &&
        error.graphQLErrors &&
        error.graphQLErrors.map((e, i) => {
          return (
            <div key={i}>
              {e.message}
              {e && e.exception && (
                <ul>
                  {e.exception &&
                    e.exception.stacktrace.map((s, i2) => {
                      return <li key={i2}>{s}</li>;
                    })}
                </ul>
              )}
            </div>
          );
        })}

      {error &&
        (error.graphQLErrors == null || error.graphQLErrors.length === 0) && (
          <div>{error}</div>
        )}
    </Error>
  );
};
export default QueryError;
