import React from "react";
import styled from "styled-components";
import publicPath from "../../helpers/publicPath";

const NoWrap = styled.div`
  align-items: center;
  display: flex;
  white-space: nowrap;
`;

const Facebook = () => {
  return (
    <NoWrap>
      <a
        href="https://www.facebook.com/enefel2"
        target={"_blank"}
        rel="noopener noreferrer"
      >
        <img
          src={publicPath("interface/f_logo_RGB-Blue_58.png")}
          alt="facebook"
        />
      </a>
    </NoWrap>
  );
};
export default Facebook;
