import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { setInStore, STORE_KEYS, getFromStore } from "../../storage/Storage";
import palette from "../../styles/palette";
import styled from "styled-components";

export const SelectStyle = styled(Select)(({ theme }) => ({
  color: palette.primary.contrastText,
  fontWeight: "bold",
  opacity: 0.8,
  textDecoration: "none",
  textTransform: "uppercase",

  "&:hover, &:focus": {
    opacity: 1,
    textDecoration: "underline",
  },

  "& svg": {
    fill: palette.primary.contrastText,
  },
}));

const ChangeLanguage = React.memo(() => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(
    getFromStore(STORE_KEYS.LANGUAGE) || "en"
  );

  function changeLanguage(e) {
    const code = e.target.value;

    if (i18n.language !== code) {
      i18n.changeLanguage(code);
      setLanguage(code);
      setInStore(STORE_KEYS.LANGUAGE, code);
    }
  }
  return (
    <SelectStyle
      value={language}
      onChange={changeLanguage}
      inputProps={{
        "aria-label": t("t:ChangeLanguage", "__ChangeLanguage"),
      }}
      // className={classes.root}
      variant="standard"
    >
      <MenuItem value={"en"}>{t("t:en")}</MenuItem>
      <MenuItem value={"fr"}>{t("t:fr")}</MenuItem>
    </SelectStyle>
  );
});
export default ChangeLanguage;
