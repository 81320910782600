import React from "react";
import { useTranslation } from "react-i18next";

const BlockSupports = React.memo(
  ({ attackerST, attackerBonus, defenderST, defenderBonus }) => {
    const { t } = useTranslation();

    return (
      <>
        {t("t:Attacker", "__Attacker")}: {attackerST} + {attackerBonus}
        <br />
        {t("t:Defender", "__Defender")}: {defenderST} + {defenderBonus}
      </>
    );
  }
);

export default BlockSupports;
