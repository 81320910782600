import React, { useEffect } from "react";
import { TILE_SIZE } from "../../Stadium/Stadium";
import styled from "styled-components";
import { p, getThrowingBonus } from "enefel";
import { useRecoilValue } from "recoil";
import { passTargetState } from "../../atoms/gameState";
import { focusState } from "../../atoms/mouseState";
import { distance } from "enefel/calcul";

let tableDiv = [];

const cleanField = () => {
  tableDiv.forEach((div) => {
    div.style.outline = "none";
    div.style.zIndex = "0";
  });
  tableDiv = [];
};

const ThrowTeamMateTemplateWrapper = styled.div`
  background-color: ${(props) => props.color};
  height: ${(props) => props.thickness};
  left: ${(props) => props.cx};
  line-height: 1px;
  margin: 0px;
  opacity: 0.5;
  padding: 0px;
  position: absolute;
  top: ${(props) => props.cy};
  transform: ${(props) => "rotate(" + props.angle + "deg)"};
  width: ${(props) => props.length};
  z-index: 0;
  pointer-events: none;
`;

const ThrowTeamMateTemplate = React.memo(
  ({ game, x1, y1, playerFrom, players }) => {
    const passTarget = useRecoilValue(passTargetState);
    const focus = useRecoilValue(focusState);

    let x2 = focus ? focus.x : null;
    let y2 = focus ? focus.y : null;

    useEffect(() => {
      return () => {
        cleanField();
      };
    }, []);

    if (passTarget && passTarget.x !== null && passTarget.y !== null) {
      // ThrowTeamMate action is on going, we display the selected target
      x2 = passTarget.x;
      y2 = passTarget.y;
    }
    const div1 = document.querySelector(`[data-position='${x1}-${y1}']`);
    const div2 = document.querySelector(`[data-position='${x2}-${y2}']`);
    if (!div1 || !div2) {
      return null;
    }
    const thickness = TILE_SIZE / 4 + TILE_SIZE * 2;

    const center = TILE_SIZE / 2;

    const p1 = p(div1.offsetLeft + center, div1.parentNode.offsetTop + center);
    const p2 = p(div2.offsetLeft + center, div2.parentNode.offsetTop + center);

    // distance
    var length = distance(p1, p2);

    // center
    var cx = (p1.x + p2.x) / 2 - length / 2;
    var cy = (p1.y + p2.y) / 2 - thickness / 2;

    // angle
    var angle = Math.atan2(p1.y - p2.y, p1.x - p2.x) * (180 / Math.PI);

    const bonus = getThrowingBonus(p(x1, y1), p(x2, y2), game);

    let color;
    if (bonus === 1) {
      color = "#B5E31E";
    } else if (bonus === 0) {
      color = "#F9F213";
    } else {
      return null;
    }

    return (
      <ThrowTeamMateTemplateWrapper
        thickness={thickness + "px"}
        color={color}
        cx={cx + "px"}
        cy={cy + "px"}
        angle={angle}
        length={length + "px"}
      />
    );
  }
);

export default ThrowTeamMateTemplate;
