import { useQuery } from "@apollo/client";
import { GAME_HISTORY_INFO, GAME_HISTORY_TYPE } from "enefel";
import { getTime, TYPE_TIMER } from "enefel/time";
import React from "react";
import styled from "styled-components/macro";
import { GET_GAME_HISTORY } from "../../../query/GameQueries";
import CustomTooltip from "../../Interface/Tooltip";
import { FaFootballBall } from "react-icons/fa";
import { GiBootKick, GiWhistle } from "react-icons/gi";
import { BiTimer } from "react-icons/bi";
import { ImPlus } from "react-icons/im";
import { GiBeerStein } from "react-icons/gi";
import { FaFlagCheckered } from "react-icons/fa";

import Day from "../../Date/Day";
import { getGameColors } from "enefel/color";
import PlayerLinkFromId from "../../Player/PlayerLinkFromId";
import { useTranslation } from "react-i18next";
import palette from "../../../styles/palette";
import { PLAYER_STATUS } from "enefel/status";
import { getDateEndHalfTime, getDateStartHalfTime } from "enefel/game";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const BAR_SIZE = 10;
const EVENT_SIZE = BAR_SIZE + 100 / 10;

const Background = styled.div`
  background: ${palette.primary.dark};
  margin-top: 15px;
  height: ${BAR_SIZE}px;
  position: relative;
  width: 100%;
  outline: solid 1px #39b136;
`;

const getEventSize = (type) => {
  if (type === TIME_EVENT_TYPE.INJURY) {
    return EVENT_SIZE * 0.6;
  }
  return EVENT_SIZE;
};

const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const Event = styled.div`
  align-items: center;
  background: #${(props) => (props.color ? props.color : palette.primary.dark)};
  border-radius: 50%;
  display: flex;
  height: ${(props) => getEventSize(props.type)}px;
  justify-content: center;
  left: calc(
    ${(props) => props.time | 0}% - ${(props) => getEventSize(props.type) / 2}px
  );
  position: absolute;
  /* top: ${(props) => BAR_SIZE - getEventSize(props.type) / 1.3}px; */
  top: 0px;
  transition: all 0.2s ease-in-out;
  width: ${(props) => getEventSize(props.type)}px;
  z-index: 2;
  transform: translate(
    0,
    -${(props) => (getEventSize(props.type) - BAR_SIZE) / 2}px
  );
  outline: solid 1px ${palette.primary.dark};
  transform-origin: center;
  cursor: ${(props) => (props.onClick ? "pointer" : null)};

  &:hover {
    transform: translate(
        0,
        -${(props) => (getEventSize(props.type) - BAR_SIZE) / 2}px
      )
      scale(1.2);
    filter: drop-shadow(1px 1px 2px black);
    z-index: 3;
  }
`;

const LineAfter = styled.div`
  background: ${(props) =>
    props.nextStyle === "end"
      ? `repeating-linear-gradient(
  45deg,
  black,
  black 4px,
  #444 5px,
  #444 6px
)`
      : `repeating-linear-gradient(
  to right,
  #f6ba52,
  #f6ba52 5px,
  #ffd180 5px,
  #ffd180 10px
)`};
  content: " ";
  display: block;
  height: ${(props) =>
    props.nextStyle === "end" ? BAR_SIZE * 0.8 : BAR_SIZE}px;
  position: absolute;
  margin-left: ${(props) => props.start}%;
  left: 0;
  top: 0;
  width: ${(props) => props.end - props.start}%;
  transform: ${(props) =>
    props.nextStyle === "end" ? "translate(0, 15%)" : null};
  opacity: ${(props) => (props.nextStyle === "end" ? "0.5" : "1")};
`;

const getTimeFromLimit = (start, end) => {
  const timeStart = start.getTime();
  const timeEnd = end.getTime();
  const pourcEnd = timeEnd - timeStart;

  return (date) => {
    const pourcDate = date.getTime() - timeStart;
    return (pourcDate / pourcEnd) * 100;
  };
};

export const TIME_EVENT_TYPE = {
  END: "end",
  HALF: "half",
  INJURY: "injury",
  KICK: "kick",
  NOW: "now",
  START: "start",
  TD: "td",
};

export const getIconTimeline = (type) => {
  let icon = null;
  if (type === TIME_EVENT_TYPE.TD) {
    icon = <FaFootballBall size={"0.8em"} />;
  } else if (type === TIME_EVENT_TYPE.KICK) {
    icon = <GiBootKick />;
  } else if (type === TIME_EVENT_TYPE.START) {
    icon = <FaFlagCheckered size={"0.8em"} />;
  } else if (type === TIME_EVENT_TYPE.END) {
    icon = <GiWhistle />;
  } else if (type === TIME_EVENT_TYPE.NOW) {
    icon = <BiTimer />;
  } else if (type === TIME_EVENT_TYPE.INJURY) {
    icon = <ImPlus size={"0.5em"} />;
  } else if (type === TIME_EVENT_TYPE.HALF) {
    icon = <GiBeerStein size={"0.8em"} />;
  }

  return icon;
};

export const TimeEvent = React.memo(
  ({
    type,
    next,
    date,
    children,
    nextStyle,
    colorBg = "ffffff",
    color = "085223",
    onClick,
  }) => {
    return (
      <>
        <Event time={date} color={colorBg} type={type} onClick={onClick}>
          <CustomTooltip placement="top" title={children}>
            <div
              style={{
                alignItems: "center",
                color: `#${color}`,
                display: "flex",
                height: "100%",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {getIconTimeline(type)}
            </div>
          </CustomTooltip>
        </Event>
        {next != null && (
          <LineAfter start={date} end={next} nextStyle={nextStyle} />
        )}
      </>
    );
  }
);

const TdTimeEvent = React.memo(({ history, game, getT, onClick }) => {
  const { t } = useTranslation();
  const [color1, color1Bis, color2, color2Bis] = getGameColors(game);
  return (
    <TimeEvent
      onClick={() => onClick(history.createdAt)}
      type={TIME_EVENT_TYPE.TD}
      date={getT(new Date(history.createdAt))}
      next={getT(
        new Date(
          new Date(history.createdAt).getTime() + getTime(TYPE_TIMER.TD) * 1000
        )
      )}
      color={history.team_id === game.team1.id ? color1 : color2}
      colorBg={history.team_id === game.team1.id ? color1Bis : color2Bis}
    >
      <div>{t("t:td", "__td")}</div>
      <Day withHours={true}>{history.createdAt}</Day>
      <div>
        <PlayerLinkFromId
          color={history.team_id === game.team1.id ? color1 : color2}
          playerId={history.player_id}
          colorContrast={"primary"}
        />
      </div>
    </TimeEvent>
  );
});

const InjTimeEvent = React.memo(({ history, game, getT, onClick }) => {
  const { t } = useTranslation("gameHistory");
  const [color1, color1Bis, color2, color2Bis] = getGameColors(game);
  let idResponsible = history.player_id;
  let idTarget = null;
  const infos = history.info.split(";");
  const type = history.type;
  let info;
  let text = t(`gameHistory:type_${history.type}`, `__type_${history.type}`);
  if (type === GAME_HISTORY_TYPE.FOUL) {
    info = infos.find((i) => i.split(" ")[0] === GAME_HISTORY_INFO.FOUL);
    if (info) {
      const param = info.split(" ");
      idResponsible = param[1];
      idTarget = param[2];
    }
  } else if (type === GAME_HISTORY_TYPE.BLOCK) {
    info = infos.find((i) => i.split(" ")[0] === GAME_HISTORY_INFO.BLOCK);
    if (info) {
      const param = info.split(" ");
      idResponsible = param[1];
      idTarget = param[2];
    }
  } else if (type === GAME_HISTORY_TYPE.MOVE) {
    info = infos.find((i) => {
      const param = i.split(" ");
      return param[0] === GAME_HISTORY_INFO.GO_FOR_IT && param[5] === "false";
    });
    if (info) {
      text = t("gameHistory:go for it", "__go for it");
    }
    info = infos.find((i) => {
      const param = i.split(" ");
      return param[0] === GAME_HISTORY_INFO.DODGE && param[5] === "false";
    });
    if (info) {
      text = t("gameHistory:dodge", "__dodge");
    }
  }

  return (
    <TimeEvent
      onClick={() => onClick(history.createdAt)}
      type={TIME_EVENT_TYPE.INJURY}
      date={getT(new Date(history.createdAt))}
      color={history.team_id === game.team1.id ? color2 : color1}
      colorBg={history.team_id === game.team1.id ? color2Bis : color1Bis}
    >
      <div>
        {t("t:injury", "__injury")} ({text})
      </div>
      <Day withHours={true}>{history.createdAt}</Day>
      <PlayerWrapper>
        {idResponsible && (
          <>
            <PlayerLinkFromId
              playerId={idResponsible}
              color={history.team_id === game.team1.id ? color1 : color2}
              colorContrast={"primary"}
              side={history.team_id === game.team1.id ? 1 : 2}
            />
            {idTarget && <span>VS</span>}
          </>
        )}
        {idTarget && (
          <PlayerLinkFromId
            playerId={idTarget}
            color={history.team_id === game.team1.id ? color2 : color1}
            colorContrast={"primary"}
            status={PLAYER_STATUS.CASUALTY}
            side={history.team_id === game.team1.id ? 2 : 1}
          />
        )}
      </PlayerWrapper>
    </TimeEvent>
  );
});

const Timeline = React.memo(({ game }) => {
  const { t } = useTranslation();
  const h = useHistory();

  const { loading, data } = useQuery(GET_GAME_HISTORY, {
    variables: {
      gameId: game.id,
      playerId: null,
    },
    fetchPolicy: "cache-only",
  });

  const start = new Date(game.date_start);
  const end = new Date(game.date_end);
  const half = getDateStartHalfTime(game);
  const getT = getTimeFromLimit(start, end);
  const now = new Date();

  const tds = [];
  const injuries = [];
  if (data) {
    data.gameHistory.forEach((h) => {
      const infos = h.info.split(";");
      infos.forEach((i) => {
        let data = i.split(" ");
        if (!data) {
          data = [];
        }
        const type = data[0];
        if (type === GAME_HISTORY_INFO.TD) {
          tds.push({ ...h });
        }

        // Add INJURY for all the armor pass
        // if (type === GAME_HISTORY_INFO.INJURY) {
        //   injuries.push({ ...h });
        // }
        if (type === GAME_HISTORY_INFO.CASUALTY) {
          injuries.push({ ...h });
        }
      });
    });
  }
  const onClick = (createdAt) => {
    let tag = null;
    // Start with more recent
    data.gameHistory.find((h, index) => {
      if (new Date(createdAt) >= new Date(h.createdAt)) {
        tag = data.gameHistory.length - index;
        return true;
      }
      return false;
    });
    if (tag) {
      h.replace({
        search: new URLSearchParams({ h: tag }).toString(),
      });
    }
    return tag;
  };
  return (
    <Background>
      <TimeEvent
        date={getT(start)}
        next={getT(
          new Date(start.getTime() + getTime(TYPE_TIMER.BEFORE) * 1000)
        )}
        type={TIME_EVENT_TYPE.START}
      >
        <div>{t("t:Kickoff", "__Kickoff")}</div>
        <Day withHours={true}>{start}</Day>
      </TimeEvent>

      {game.hasHalfTime && (
        <TimeEvent
          date={getT(half)}
          type={TIME_EVENT_TYPE.HALF}
          next={getT(getDateEndHalfTime(game))}
        >
          <div>{t("t:half time", "__half time")}</div>
          <Day withHours={true}>{half}</Day>
        </TimeEvent>
      )}

      <TimeEvent date={getT(end)} type={"end"}>
        <div>{t("t:End game", "__End game")}</div>
        <Day withHours={true}>{end}</Day>
      </TimeEvent>

      <TimeEvent date={getT(end)} type={TIME_EVENT_TYPE.END}>
        <div>{t("t:End game", "__End game")}</div>
        <Day withHours={true}>{end}</Day>
      </TimeEvent>

      {now > start && now < end && (
        <TimeEvent
          type={TIME_EVENT_TYPE.NOW}
          date={getT(now)}
          next={getT(end)}
          nextStyle={"end"}
        >
          <div>{t("t:Current time", "__Current time")}</div>
          <Day withHours={true}>{now}</Day>
        </TimeEvent>
      )}
      {!loading &&
        injuries.map((inj) => (
          <InjTimeEvent
            key={inj.id}
            history={inj}
            game={game}
            getT={getT}
            onClick={onClick}
          />
        ))}
      {!loading &&
        tds.map((td) => (
          <TdTimeEvent
            key={td.id}
            history={td}
            game={game}
            getT={getT}
            onClick={onClick}
          />
        ))}
    </Background>
  );
});

export default Timeline;
