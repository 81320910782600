import { ACTION_BLOCK } from "../../../../query/ActionQueries";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
  getAttackerStrength,
  getBlockAssistance,
  getDefenderStrength,
  getDicesBlock,
} from "enefel";
import QueryError from "../../../Error/QueryError";
import Loading from "../../../Loading";
import Button from "../../../Interface/Button";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  actionState,
  gameState,
  pushsState,
  pushsStateFrenzy,
} from "../../atoms/gameState";
import styled from "styled-components";
import { hasSkill, hasToAgilityJumpUp, SKILL_NAMES } from "enefel/skill";
import BlockOptions from "./BlockOptions/BlockOptions";
import BlockValidation from "./BlockOptions/BlockValidation";
import BlockSupports from "./BlockOptions/BlockSupports";
import DiceOrderBlock from "./DiceOrder/DiceOrderBlock";
import {
  isFrenzyAvailable,
  getOrderDice,
  getOrderDiceAgainst,
} from "enefel/block";
import { ACTION_LIST } from "../shared";
import { canUseFend } from "enefel/canUseSkills";
const Wrapper = styled.div`
  font-size: 0.8em;
`;

const StrengtWrapper = styled.div`
  margin: 0.5em 0;
`;

const ActionWrapper = styled.div`
  margin: 0.5em 0;
`;

const BlockText = styled.div`
  max-width: 11rem;
`;

export function getMissingPush(pushs, frenzy = false) {
  let missingPush = null;
  if (pushs && pushs.length > 0) {
    missingPush = document.querySelector(
      `input[type=radio][name=${frenzy ? "frenzy-" : ""}block${pushs.length}]`
    );
  }
  return missingPush;
}

const BlockAction = React.memo(({ game, attacker, defender, players }) => {
  const { t } = useTranslation("skill");
  const [actionBlock, { loading, error }] = useMutation(ACTION_BLOCK);
  const action = useRecoilValue(actionState);
  const pushs = useRecoilValue(pushsState);
  const pushsFrenzy = useRecoilValue(pushsStateFrenzy);
  const setGameState = useSetRecoilState(gameState);

  const calculateOrder = (attacker, defender, attackerChoose, hasDauntless) => {
    return attackerChoose || hasDauntless
      ? getOrderDice(attacker, defender)
      : getOrderDiceAgainst(attacker, defender);
  };

  const bonusAttacker = getBlockAssistance(
    attacker,
    defender,
    players,
    true
  ).length;
  const bonusDefender = getBlockAssistance(defender, attacker, players).length;

  const [blitz, setBlitz] = useState(
    attacker.current_MA < attacker.MA && attacker.has_action
  );
  let hasFend = canUseFend(attacker, defender, blitz);
  let hasHorns = hasSkill(attacker, SKILL_NAMES.HORNS);
  let hasFrenzy = isFrenzyAvailable(attacker, defender);
  let hasFoulAppearance = hasSkill(defender, SKILL_NAMES.FOUL_APPEARANCE);

  const [attackerST, setAttackerEffect] = useState(
    getAttackerStrength(attacker, blitz, game.meteo)
  );
  let attackerChoose =
    attackerST + bonusAttacker >= getDefenderStrength(defender) + bonusDefender;
  let hasDauntless = hasSkill(attacker, SKILL_NAMES.DAUNTLESS);

  const [order, setOrder] = useState(
    calculateOrder(attacker, defender, attackerChoose, hasDauntless)
  );
  const [orderFrenzy, setOrderFrenzy] = useState(
    getOrderDice(attacker, defender)
  );
  const [rerollFA, setRerollFA] = useState(false);

  useEffect(() => {
    setOrder(calculateOrder(attacker, defender, attackerChoose, hasDauntless));
  }, [attacker, defender, attackerChoose, hasDauntless]);

  useEffect(() => {
    setAttackerEffect(getAttackerStrength(attacker, blitz, game.meteo));
  }, [blitz, hasHorns, attacker, game.meteo]);
  const [follow, setFollow] = useState(
    hasFend ? (hasFrenzy ? true : false) : true
  );
  const [followFall, setFollowFall] = useState(
    hasFend ? (hasFrenzy ? true : false) : true
  );

  const missingPush = getMissingPush(pushs);
  const missingPushFrenzy = hasFrenzy
    ? getMissingPush(pushsFrenzy, hasFrenzy)
    : false;

  function handleChangeAction(newAction) {
    setGameState((s) => ({
      ...s,
      pushs: null,
      pushsFrenzy: null,
      action: newAction,
      passTarget: null,
    }));
  }

  const dices = getDicesBlock(
    attackerST + bonusAttacker,
    getDefenderStrength(defender) + bonusDefender
  );

  const toggleBlitz = useCallback(() => {
    setBlitz((b) => !b);
  }, [setBlitz]);

  const toggleFollow = useCallback(
    (type, force = null) => {
      if (hasFend) {
        setFollowFall(false);
        setFollow(false);
      }
      if (type === "fall") {
        setFollowFall((f) => (force !== null ? force : !f));
      } else {
        setFollow((f) => (force !== null ? force : !f));
      }
    },
    [setFollowFall, setFollow, hasFend]
  );

  const handleChangeOrder = useCallback(
    (newValues) => {
      setOrder(newValues);
    },
    [setOrder]
  );

  const handleChangeOrderFrenzy = useCallback(
    (newValues) => {
      setOrderFrenzy(newValues);
    },
    [setOrderFrenzy]
  );

  const block = () => {
    actionBlock({
      variables: {
        playerIdFrom: attacker.id,
        playerIdTo: defender.id,
        dices,
        order,
        pushs: pushs.map((p) => ({ x: p.x, y: p.y })),
        follow,
        followFall,
        blitz,
        pushsFrenzy: hasFrenzy
          ? pushsFrenzy.map((p) => ({ x: p.x, y: p.y }))
          : null,
        orderFrenzy: hasFrenzy ? orderFrenzy : null,
        rerollFA,
      },
    }).then(() => {
      handleChangeAction(null);
    });
  };
  if (error) {
    return <QueryError error={error} />;
  }
  if (loading) {
    return <Loading />;
  }
  const isPushFrenzyDone = !hasFrenzy
    ? true
    : pushsFrenzy != null &&
      pushsFrenzy.length > 0 &&
      missingPushFrenzy == null;
  const isPushDone = pushs != null && pushs.length > 0 && missingPush == null;
  return (
    <Wrapper>
      {action !== ACTION_LIST.BLOCK && (
        <Button onClick={() => handleChangeAction(ACTION_LIST.BLOCK)}>
          {attacker.current_MA < attacker.MA
            ? t("t:Blitz", "__Blitz")
            : t("t:Block", "__Block")}
          : {defender.name}
        </Button>
      )}
      {action === ACTION_LIST.BLOCK && (
        <>
          <Button onClick={() => handleChangeAction(null)}>
            {t("t:CancelBlock", "__CancelBlock")}
          </Button>
          <br />
          <br />
          <StrengtWrapper>
            <BlockSupports
              attackerST={attackerST}
              attackerBonus={bonusAttacker}
              defenderST={getDefenderStrength(defender)}
              defenderBonus={bonusDefender}
            />
          </StrengtWrapper>
          <ActionWrapper>
            <BlockOptions
              blitz={blitz}
              follow={follow}
              followFall={followFall}
              hasFend={hasFend}
              hasFoulAppearance={hasFoulAppearance}
              hasFrenzy={hasFrenzy}
              hasHorns={hasHorns}
              isBlitzDisabled={attacker.current_MA < attacker.MA}
              onChangeBlitz={toggleBlitz}
              onChangeFollow={toggleFollow}
              onChangeRerollFA={setRerollFA}
              rerollFA={rerollFA}
            />
          </ActionWrapper>

          <DiceOrderBlock
            attacker={attacker}
            defender={defender}
            attackerChoose={attackerChoose}
            hasDauntless={hasDauntless}
            hasFrenzy={hasFrenzy}
            order={order}
            orderFrenzy={orderFrenzy}
            handleChangeOrder={handleChangeOrder}
            handleChangeOrderFrenzy={handleChangeOrderFrenzy}
          />

          {!blitz && hasToAgilityJumpUp(attacker) && (
            <div>{t("t:hasToAgilityJumpUp", "__hasToAgilityJumpUp")}</div>
          )}

          {isPushDone && isPushFrenzyDone && (
            <BlockValidation
              onValidateBlock={block}
              blitz={blitz}
              attackerChoose={attackerChoose}
              dices={dices}
              isGfi={attacker.current_MA <= 0 && attacker.has_action}
            />
          )}
          {!isPushDone && (
            <BlockText>
              <br />
              {t(
                "t:ChooseWhereToPushTheOpponent",
                "__ChooseWhereToPushTheOpponent"
              )}
            </BlockText>
          )}
          {isPushDone && !isPushFrenzyDone && (
            <BlockText>
              <br />
              {t(
                "t:ChooseWhereToPushTheOpponentFrenzy",
                "__ChooseWhereToPushTheOpponentFrenzy"
              )}
            </BlockText>
          )}
        </>
      )}
    </Wrapper>
  );
});
export default BlockAction;
