import React, { useCallback } from "react";
import Dugout from "./Dugout/Dugout";
import Field from "./Field/Field";
import styled from "styled-components/macro";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useStateWithLocalStorage, STORE_KEYS } from "../../../storage/Storage";
import { getGameColors } from "enefel/color";
import Configs from "./Configs";
import { currentGameState, actionState, gameState } from "../atoms/gameState";
import TeamActions from "./TeamActions";
import { focusState } from "../atoms/mouseState";
import { hasStatusOnField } from "enefel/status";
import { ACTION_LIST } from "../Action/shared";
import Store from "./Store";
import { isGameRunning } from "enefel";
import ConfettiTD from "./Confetti/ConfettiTD";
import Inducement from "../../Inducement/Inducement";
import Timeline from "./Timeline";
import { GAME_STATUS } from "enefel/game";
import { helpSkillsToDisplay } from "enefel/skill";

export const TILE_SIZE = 35;

const StadiumWrapper = styled.div`
  background: linear-gradient(rgb(10, 81, 36), #0f7534);
  display: inline-flex;
  flex-direction: column;
  padding: ${TILE_SIZE}px;
  padding-bottom: ${TILE_SIZE / 2}px;
  position: relative;
  width: fit-content;

  & > :first-child {
    margin-bottom: ${TILE_SIZE}px;
  }
`;

const WrapperField = styled.div`
  position: relative;
`;

const WrapperDugout = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const WrapperTeamActions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1em;
  height: ${(props) => props.size + "px"};
  justify-content: ${(props) => (props.side === "left" ? "start" : "end")};
  left: ${(props) => (props.side === "left" ? props.size + "px" : null)};
  position: absolute;
  right: ${(props) => (props.side === "right" ? props.size + "px" : null)};
  top: ${(props) => props.size * (props.stadium_height + 1) + "px"};
  width: ${(props) => props.size * 10 + "px"};
`;

const WrapperConfig = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  top: ${(props) => props.size + "px"};
  width: ${(props) => props.size + "px"};

  & > div {
    margin-bottom: 0.5rem;
  }
`;

const Stadium = React.memo(
  ({
    currentPlayer,
    currentPlayerTeam,
    isSetUpVisible,
    onComplete,
    players1,
    players2,
  }) => {
    const action = useRecoilValue(actionState);
    const game = useRecoilValue(currentGameState);
    const setGameState = useSetRecoilState(gameState);
    const setFocusState = useSetRecoilState(focusState);

    const [color1, color1Bis, color2, color2Bis] = getGameColors(game);

    const [showTZ, setShowTZ] = useStateWithLocalStorage(
      STORE_KEYS.HIDE_TZ,
      true
    );
    const [pitchIndicator, setPitchIndicator] = useStateWithLocalStorage(
      STORE_KEYS.PITCH_INDICATOR,
      true,
      false
    );
    const [showMeteo, setShowMeteo] = useStateWithLocalStorage(
      STORE_KEYS.SHOW_METEO,
      true,
      true
    );
    const [showIcons, setShowIcons] = useStateWithLocalStorage(
      STORE_KEYS.SHOW_ICONS,
      true,
      false
    );
    const [showSkills, setShowSkills] = useStateWithLocalStorage(
      STORE_KEYS.SHOW_SKILLS,
      true,
      helpSkillsToDisplay
    );
    const getPositionFromEvent = useCallback(
      ({ currentTarget, clientX, clientY }) => {
        var rect = currentTarget.getBoundingClientRect();

        var x = Math.floor((clientX - rect.left + 1) / TILE_SIZE);
        var y = Math.floor((clientY - rect.top + 1) / TILE_SIZE);
        if (
          x < 0 ||
          x >= game.stadium_width ||
          y < 0 ||
          y >= game.stadium_height
        ) {
          x = null;
          y = null;
        }
        return { x, y };
      },
      [game]
    );

    const saveFocusPosition = useCallback(
      (x, y) => {
        const newFocus = x != null && y != null ? { x, y } : null;

        setFocusState((f) => {
          if (f && x === f.x && y === f.y) {
            return f;
          }
          return newFocus;
        });
      },
      [setFocusState]
    );

    const handleMouseOver = useCallback(
      (e) => {
        const { x, y } = { ...getPositionFromEvent(e) };
        saveFocusPosition(x, y);
      },
      [getPositionFromEvent, saveFocusPosition]
    );

    const memoizedGetPlayers1 = useCallback(
      (onField) => {
        return players1.filter((p) =>
          onField ? hasStatusOnField(p) : !hasStatusOnField(p)
        );
      },
      [players1]
    );

    const memoizedGetPlayers2 = useCallback(
      (onField) => {
        return players2.filter((p) =>
          onField ? hasStatusOnField(p) : !hasStatusOnField(p)
        );
      },
      [players2]
    );

    const handleChangeShowTZ = useCallback(
      (value, side) => {
        setShowTZ((currentValue) => {
          return { ...currentValue, ["team" + side]: value };
        });
      },
      [setShowTZ]
    );

    const handleChangeShowIcons = useCallback(() => {
      setShowIcons((p) => !p);
    }, [setShowIcons]);

    const handlePitchIndicator = useCallback(() => {
      setPitchIndicator((p) => !p);
    }, [setPitchIndicator]);

    const handleToggleMeteo = useCallback(() => {
      setShowMeteo((p) => !p);
    }, [setShowMeteo]);

    const handleSkillColorChange = useCallback(
      (newColor, skillId) => {
        const newShowSkills = { ...showSkills };
        newShowSkills[skillId].color = newColor;
        setShowSkills(newShowSkills);
      },
      [setShowSkills, showSkills]
    );

    const handleToggleSkill = useCallback(
      (skillId) => {
        const newShowSkills = { ...showSkills };
        if (!newShowSkills[skillId]) {
          newShowSkills[skillId] = { actif: true, color: "white" };
        }
        newShowSkills[skillId].actif = !newShowSkills[skillId]?.actif;
        setShowSkills(newShowSkills);
      },
      [setShowSkills, showSkills]
    );

    const handleClick = useCallback(
      (e) => {
        if (
          action !== ACTION_LIST.PASS &&
          action !== ACTION_LIST.THROW_TEAM_MATE
        ) {
          return;
        }
        const { x, y } = { ...getPositionFromEvent(e) };
        if (x !== null && y !== null) {
          setGameState((s) => ({ ...s, passTarget: { x, y } }));
        }
      },
      [action, getPositionFromEvent, setGameState]
    );

    if (!game) {
      return null;
    }

    const skillsToDisplayed = showSkills
      ? Object.keys(showSkills).reduce((ac, cu) => {
          return showSkills[cu].actif ? { ...ac, [cu]: showSkills[cu] } : ac;
        }, {})
      : {};

    return (
      <div>
        {game && <ConfettiTD game={game} color1={color1} color2={color2} />}
        <StadiumWrapper>
          <WrapperField
            onClick={handleClick}
            // TODO: Run out of react?
            onMouseOver={handleMouseOver}
            onMouseLeave={saveFocusPosition.bind(this, null, null)}
          >
            <Field
              color1={color1}
              color2={color2}
              currentPlayer={currentPlayer}
              currentPlayerTeam={currentPlayerTeam}
              isSetUpVisible={isSetUpVisible}
              onComplete={onComplete}
              pitchIndicator={pitchIndicator}
              players1={memoizedGetPlayers1(true)}
              players2={memoizedGetPlayers2(true)}
              showTZ={showTZ}
              showIcons={showIcons}
              showSkills={skillsToDisplayed}
              showMeteo={showMeteo}
            />
          </WrapperField>

          <WrapperConfig size={TILE_SIZE}>
            <Configs
              color1={color1}
              color1Bis={color1Bis}
              color2={color2}
              color2Bis={color2Bis}
              handleChangeShowIcons={handleChangeShowIcons}
              handleChangeShowTZ={handleChangeShowTZ}
              handlePitchIndicator={handlePitchIndicator}
              handleSkillColorChange={handleSkillColorChange}
              handleToggleSkill={handleToggleSkill}
              handleToggleMeteo={handleToggleMeteo}
              pitchIndicator={pitchIndicator}
              showIcons={showIcons}
              showSkills={showSkills}
              showTZ={showTZ}
              showMeteo={showMeteo}
            />
          </WrapperConfig>

          <WrapperTeamActions
            side={"left"}
            size={TILE_SIZE}
            stadium_height={game.stadium_height}
          >
            <TeamActions
              reroll={game.reroll1}
              team={game.team1}
              onComplete={onComplete}
            />
            <Inducement team={game.team1} />
          </WrapperTeamActions>
          <WrapperTeamActions
            side={"right"}
            size={TILE_SIZE}
            stadium_height={game.stadium_height}
          >
            <Inducement team={game.team2} />
            <TeamActions
              reroll={game.reroll2}
              team={game.team2}
              onComplete={onComplete}
            />
          </WrapperTeamActions>

          <WrapperDugout size={TILE_SIZE}>
            <Dugout
              team1={game.team1}
              team2={game.team2}
              players1={memoizedGetPlayers1(false)}
              players2={memoizedGetPlayers2(false)}
              isSetUpVisible={isSetUpVisible}
            />

            {currentPlayer && isGameRunning(game) && (
              <Store team={game.team1} />
            )}
          </WrapperDugout>
          {game.status !== GAME_STATUS.WAITING && <Timeline game={game} />}
        </StadiumWrapper>
      </div>
    );
  }
);

export default Stadium;
