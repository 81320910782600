import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Countdown from "../Date/Countdown";
import CustomTooltip from "../Interface/Tooltip";
import styled from "styled-components/macro";
import {
  hasSkillDisplayableInfo,
  isSpecialSkill,
  SKILL_DURATION,
  SKILL_NAMES,
} from "enefel/skill";
import { GiMuscleFat } from "react-icons/gi";
import { FaHandSparkles } from "react-icons/fa";
import { AiFillStar } from "react-icons/ai";
import { BsFillEmojiAngryFill } from "react-icons/bs";
import { GiBootKick, GiBeerStein, GiWhistle } from "react-icons/gi";

import Race from "../Race/Race";
import { InlineFlex } from "../styles";

const Wrapper = styled(InlineFlex)`
  cursor: help;
  font-weight: ${(props) => (props.isNew ? "bold" : "normal")};
  white-space: nowrap;
`;

const WrapperSkillText = styled.span`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
`;

const WrapperOpen = styled.span`
  cursor: pointer;
  text-decoration: ${(props) => (props.isOpen ? "none" : "underline")};
`;

const SkillDuration = React.memo(({ duration }) => {
  const { t } = useTranslation();
  let icon = null;
  let description = "";

  if (duration === SKILL_DURATION.END_GAME) {
    icon = <GiWhistle />;
    description = t("t:skill duration end game", "__skill duration end game");
  } else if (duration === SKILL_DURATION.HALF_TIME) {
    icon = <GiBeerStein />;
    description = t("t:skill duration half time", "__skill duration half time");
  } else if (duration === SKILL_DURATION.NEXT_SETUP) {
    icon = <GiBootKick />;
    description = t(
      "t:skill duration next setup",
      "__skill duration next setup"
    );
  }

  if (icon === null) {
    return null;
  }
  return (
    <CustomTooltip placement="bottom" title={description}>
      <WrapperSkillText>{icon}</WrapperSkillText>
    </CustomTooltip>
  );
});

export const SkillText = React.memo(({ skill, skillId, date_next = false }) => {
  const { t } = useTranslation("skill");

  return (
    <>
      <span>{t("skill:" + skillId, "__" + skillId)}</span>

      {date_next ? <Countdown date={date_next} /> : null}
    </>
  );
});

const SpecialSkill = React.forwardRef(
  ({ name, displayName = false, info, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("skill");
    const handleClick = () => setIsOpen(!isOpen);
    return (
      <InlineFlex {...props} ref={ref}>
        {displayName ? `${t("skill:" + name, "__" + name)} ` : null}
        {(name === SKILL_NAMES.STAR && <AiFillStar />) ||
          (name === SKILL_NAMES.SPECIALIST && <FaHandSparkles />) ||
          (name === SKILL_NAMES.BIG_GUY && <GiMuscleFat />) ||
          (name === SKILL_NAMES.ANIMOSITY && info && (
            <>
              <BsFillEmojiAngryFill />
              {info.length > 0 ? (
                <WrapperOpen onClick={handleClick} isOpen={isOpen}>
                  {isOpen
                    ? info
                        .map((i) => <Race raceId={i} withGroup={false} />)
                        .reduce((prev, curr) => [prev, ", ", curr])
                    : `x${info.length}`}
                </WrapperOpen>
              ) : (
                <Race raceId={info} withGroup={false} />
              )}
            </>
          )) ||
          null}
      </InlineFlex>
    );
  }
);

const Info = React.memo(({ skillId, info }) => {
  const { t } = useTranslation("race");
  if (hasSkillDisplayableInfo(skillId)) {
    if (!info) {
      if (
        skillId === SKILL_NAMES.DIRTY_PLAYER ||
        skillId === SKILL_NAMES.MIGHTY_BLOW
      ) {
        info = 1;
      } else {
        return "";
      }
    }

    if (skillId === SKILL_NAMES.LONER) {
      return `(${info}+)`;
    }
    if (skillId === SKILL_NAMES.ANIMOSITY) {
      return `(${t("race:" + info, "__" + info)})`;
    }
    if (
      skillId === SKILL_NAMES.DIRTY_PLAYER ||
      skillId === SKILL_NAMES.MIGHTY_BLOW ||
      skillId === SKILL_NAMES.CHAINSAW
    ) {
      return `(+${info})`;
    }
  }
});

const StandardSkill = React.forwardRef(
  (
    {
      skill,
      isNew,
      name,
      date_next,
      hasDisplayableInfo,
      info,
      level,
      ...props
    },
    ref
  ) => {
    return (
      <Wrapper isNew={isNew} {...props} ref={ref}>
        {isNew}
        <SkillText skillId={name} date_next={date_next} />{" "}
        {skill && skill.duration != null && (
          <SkillDuration duration={skill.duration} />
        )}
        {hasDisplayableInfo ? <Info skillId={name} info={info} /> : null}{" "}
        {level > 1 ? `(x${level})` : null}
      </Wrapper>
    );
  }
);

const Skill = React.memo(
  ({
    skill,
    withDate = false,
    tooltip = true,
    isNew = false,
    info = null,
    level = 1,
    displayName = false,
    displayAsStandard = false,
  }) => {
    const { t } = useTranslation("skill");

    const name = skill.skill_id ? skill.skill_id : skill;
    const date_next = withDate ? skill.date_next : null;
    const hasDisplayableInfo = hasSkillDisplayableInfo(name);
    const title = (
      <>
        <div>{t("skill:" + name + "", "__" + name + "")}:</div>
        <div>
          {t("skill:" + name + " description", "__" + name + " description")}
        </div>
      </>
    );

    const getSkillGeneric = (
      skill,
      name,
      displayName,
      info,
      date_next,
      hasDisplayableInfo,
      isNew,
      level
    ) =>
      isSpecialSkill(name) && displayAsStandard === false ? (
        <SpecialSkill name={name} displayName={displayName} info={info} />
      ) : (
        <StandardSkill
          skill={skill}
          date_next={date_next}
          hasDisplayableInfo={hasDisplayableInfo}
          info={info}
          isNew={isNew}
          level={level}
          name={name}
        />
      );

    return tooltip ? (
      <CustomTooltip placement="top" title={title}>
        {getSkillGeneric(
          skill,
          name,
          displayName,
          info,
          date_next,
          hasDisplayableInfo,
          isNew,
          level
        )}
      </CustomTooltip>
    ) : (
      getSkillGeneric(
        skill,
        name,
        displayName,
        info,
        date_next,
        hasDisplayableInfo,
        isNew,
        level
      )
    );
  }
);

export default Skill;
