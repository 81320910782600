import { createTheme, adaptV4Theme } from "@mui/material/styles";

const COLOR = {
  "green-500": "#0f7534",
  "red-500": "#d62128",
  "red-300": "#ee0000",
  "orange-500": "#ff9a08",
  "grey-300": "#ececec",
  "grey-100": "#fafafa",
  white: "#fff",
};

// + light, dark
const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: COLOR["green-500"],
      },
      box: {
        // Box with grass
        contrastTitle: "#fff",
        contrastText: "#fff",
        contrastLink: "#9dff8c",
      },
      secondary: {
        main: COLOR["red-500"],
      },
      background: {
        global: COLOR["grey-300"],
        default: COLOR["grey-100"],
        paper: COLOR["white"],
      },
      error: {
        main: COLOR["red-300"],
        contrastText: COLOR["red-300"],
      },
    },
  })
);

export default theme;

// const COLOR = {
//   "green-500": "#026836",
//   "green-700": "#164e19",
//   "gray-500": "#a2af9f",
//   "gray-700": "#6e7a6c",
//   "blue-500": "#0081a6",
//   "blue-700": "#005072",
//   "red-500": "#d62128",
//   "orange-500": "#ff9a08",
// };
/* #207720 #181991 #f82d2d #FF9A08  */
