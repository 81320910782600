import { useTranslation } from "react-i18next";
import CustomTooltip from "../../../../Interface/Tooltip";
import styled from "styled-components";
import React from "react";
import { hasSkill } from "enefel/skill";

const WrapperAction = styled.div`
  background-color: ${(props) => props.color};
  border: 1px solid black;
  bottom: 1px;
  height: 5px;
  left: ${(props) => props.index * 5 + (props.index > 0 ? 2 : 0) + "px"};
  position: absolute;
  width: 5px;
  z-index: 2;
`;

const SkillsMarker = React.memo(({ showSkills, player, size }) => {
  const { t } = useTranslation();

  return Object.keys(showSkills)
    .filter((skillId) => hasSkill(player, skillId))
    .map((skillId, index) => (
      <CustomTooltip
        key={`${skillId}-${player.id}`}
        placement="top"
        title={
          <div>
            <div>{t(`skill:${skillId}`, `__${skillId}`)}</div>
            <div>
              {t(`skill:${skillId} description`, `__${skillId} description`)}
            </div>
          </div>
        }
      >
        <WrapperAction
          color={showSkills[skillId].color}
          index={index}
          size={size}
          actif={player.has_action ? true : false}
          block={player.has_action ? true : false}
        ></WrapperAction>
      </CustomTooltip>
    ));
});

export default SkillsMarker;
