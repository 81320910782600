import React from "react";
import styled from "styled-components";
import palette from "../../styles/palette";

const SIZE_BALL = "16px";
const CheckboxStyle = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  overflow: hidden;

  &:checked + span {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`;

const LabelWrapper = styled.div`
  align-items: center;
  background: grey;
  border-radius: 100px;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  justify-content: space-between;
  position: relative;
  /* transition: background-color 0.2s; */
  width: 40px;
  box-shadow: inset 0px 1px 2px black;

  filter: ${(props) => (props.disabled ? "opacity(0.5)" : null)};
`;

const ButtonStyle = styled.span`
  background: #fff;
  border-radius: 45px;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  height: ${SIZE_BALL};
  left: 2px;
  position: absolute;
  top: 2px;
  /* transition: 0.2s; */
  width: ${SIZE_BALL};
  box-shadow: 0px 1px 2px black;
`;

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  margin-right: ${(props) => (props.direction === "row" ? 0 : "1em")};

  & > * {
    margin-right: ${(props) => (props.direction !== "row" ? "1em" : 0)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const LabelFlex = styled.label`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;
  width: 100%;
`;

const Checkbox = React.memo(
  ({
    isOn,
    handleToggle,
    disabled = false,
    onColor = palette.primary.main,
  }) => {
    return (
      <LabelWrapper
        style={{ background: isOn ? onColor : null }}
        disabled={disabled}
      >
        <CheckboxStyle
          checked={isOn}
          onChange={disabled ? null : handleToggle}
          type="checkbox"
        />
        <ButtonStyle />
      </LabelWrapper>
    );
  }
);

export default Checkbox;
