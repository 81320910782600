import { useQuery } from "@apollo/client";
import { getGameColors } from "enefel/color";
import gql from "graphql-tag";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import QueryError from "../../Error/QueryError";
import Loading from "../../Loading";
import PlayerLinkFromId from "../../Player/PlayerLinkFromId";
import StatsTable from "../../Stats/StatsTable";
import { getGameStatsField } from "./statUtils";

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  & > * {
    span:last-child {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 170px;
      font-size: 0.9rem;
    }
  }
`;
export const GameStatsExtra = React.memo(({ data, fields, game }) => {
  const { t } = useTranslation("stat");
  const [color1, , color2] = getGameColors(game);
  const getColorByTeamId = (teamId) => {
    return teamId === game.team1.id ? color1 : color2;
  };
  const info = [
    {
      Header: t("t:Player", "__Player"),
      columns: [
        {
          Header: t("t:Name", "__Name"),
          accessor: "player_id",
          Cell: (all) => {
            const player_id = all?.cell?.row?.original?.player_id;
            const team_id = all?.cell?.row?.original?.team_id;
            const color = getColorByTeamId(team_id);
            return (
              <PlayerWrapper color={color}>
                <PlayerLinkFromId
                  withIcon={true}
                  horizontal={true}
                  playerId={player_id}
                  color={color}
                  withMorale={false}
                />
              </PlayerWrapper>
            );
          },
        },
      ],
    },
  ];
  return <StatsTable data={data} fields={fields} info={info} />;
});

export const getGameStatsQuery = (fields) => {
  return gql`
    query gameStat($gameId: ID!) {
      gameStat(gameId: $gameId) {
        player_id
        team_id
        ${Object.values(fields)
          .reduce((accumulator, currentValue) => {
            return accumulator.concat(currentValue);
          }, [])
          .join("\n")}
      }
    }
  `;
};

const GameStat = React.memo(({ game }) => {
  const { t } = useTranslation("stat");

  const fields = getGameStatsField(t);
  const query = getGameStatsQuery(fields);
  const { loading, error, data } = useQuery(query, {
    variables: { gameId: game.id, fields },
  });
  if (loading) return <Loading />;
  if (error) return <QueryError error={error} />;
  return <GameStatsExtra data={data.gameStat} fields={fields} game={game} />;
});
export default GameStat;
