import { useTranslation } from "react-i18next";
import Countdown from "../../../../Date/Countdown";
import CustomTooltip from "../../../../Interface/Tooltip";
import styled from "styled-components";
import React from "react";
import { PLAYER_CARAC } from "enefel/player";
import { calculateTimeRemaining } from "enefel/date";

const STATE = {
  OPEN: "open",
  OPEN_MOVE: "open_move",
  OPEN_ACTION: "open_action",
  CLOSE: "close",
  CLOSE_WARNING: "close_warning",
};

const getState = (player) => {
  if (player.has_action) {
    if (player[`current_${PLAYER_CARAC.MA}`] < player[PLAYER_CARAC.MA]) {
      return STATE.OPEN_MOVE;
    } else {
      return STATE.OPEN;
    }
  } else {
    if (!player.date_next_action) {
      return STATE.OPEN_ACTION;
    }
    const timeRemaining = calculateTimeRemaining(player.date_next_action);

    if (timeRemaining / (1000 * 60 * 60) <= 3) {
      return STATE.CLOSE_WARNING;
    }
    return STATE.CLOSE;
  }
};
const getTextAction = (player, t) => {
  switch (getState(player)) {
    case STATE.OPEN: {
      return <span>{t("t:action_open", "__action_open")}</span>;
    }
    case STATE.OPEN_MOVE: {
      return <span>{t("t:action_open_move", "__action_open_move")}</span>;
    }
    case STATE.OPEN_ACTION: {
      return <span>{t("t:action_open_action", "__action_open_action")}</span>;
    }
    case STATE.CLOSE_WARNING: {
      return (
        <span>
          {t("t:action_close_warning", "__action_close_warning")}:{" "}
          <Countdown date={player.date_next_action} />
        </span>
      );
    }
    case STATE.CLOSE: {
      return (
        <span>
          {t("t:action_close", "__action_close")}:{" "}
          <Countdown date={player.date_next_action} />
        </span>
      );
    }
    default: {
      return <span>ERROR</span>;
    }
  }
};
const getStyleAction = (player) => {
  let backgroundColor;
  let border;
  let content;

  switch (getState(player)) {
    case STATE.OPEN: {
      backgroundColor = "greenyellow";
      border = "1px solid #00841a";
      break;
    }
    case STATE.OPEN_MOVE:
      backgroundColor = "#FCBF00";
      border = "1px solid #fc6700";
      break;
    case STATE.OPEN_ACTION: {
      backgroundColor = "#FCBF00";
      border = "1px solid #fc6700";
      content = "✓";
      break;
    }
    case STATE.CLOSE: {
      backgroundColor = "red";
      border = "1px solid #ae0101";
      break;
    }
    case STATE.CLOSE_WARNING: {
      backgroundColor = "red";
      border = "1px solid #ae0101";
      content = "!";
      break;
    }
    default: {
      backgroundColor = "";
      border = "";
    }
  }

  return {
    backgroundColor,
    border,
    content,
  };
};

const WrapperAction = styled.div`
  border-radius: 100%;
  height: 8px;
  right: 0;
  position: absolute;
  top: 0;
  width: 8px;
  z-index: 2;

  &:after {
    content: ${({ content }) => (content ? `"${content}"` : "")};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ content }) => (content === "!" ? "greenyellow" : "red")};
    font-size: 0.7em;
    text-shadow: -1px -1px 0 ${({ content }) => (content === "!" ? "#014c10" : "#320707")},
      1px -1px 0 ${({ content }) => (content === "!" ? "#014c10" : "#320707")},
      -1px 1px 0 ${({ content }) => (content === "!" ? "#014c10" : "#320707")},
      1px 1px 0 ${({ content }) => (content === "!" ? "#014c10" : "#320707")};
  }
`;

const ActionMarker = React.memo(({ player, size }) => {
  const { t } = useTranslation();

  return (
    <CustomTooltip
      placement="top"
      title={
        <div>
          <div>{getTextAction(player, t)}</div>
        </div>
      }
    >
      <WrapperAction
        size={size}
        actif={player.has_action ? true : false}
        style={getStyleAction(player)}
        content={getStyleAction(player).content}
      ></WrapperAction>
    </CustomTooltip>
  );
});

export default ActionMarker;
