import { useTranslation } from "react-i18next";
import CustomTooltip from "../../Interface/Tooltip";
import Dialog from "@mui/material/Dialog";
import GameStat from "./GameStat";
import React from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { TILE_SIZE } from "../Stadium/Stadium";
import { currentGameState } from "../atoms/gameState";

const StatsButton = styled.div`
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.size * 0.6 + "px"};
  height: ${(props) => props.size * 0.6 + "px"};
  justify-content: center;
  line-height: ${(props) => props.size * 0.6 + "px"};
  width: ${(props) => props.size * 0.6 + "px"};
`;

const DialogStat = React.memo(() => {
  const { t } = useTranslation();
  const game = useRecoilValue(currentGameState);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomTooltip
        placement="top"
        title={t("t:Show game stats", "__ShowGameStats")}
      >
        <StatsButton onClick={handleClickOpen} size={TILE_SIZE}>
          <span
            role="img"
            aria-label={t("t:Show game stats", "__ShowGameStats")}
          >
            📈
          </span>
        </StatsButton>
      </CustomTooltip>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
        onClose={handleClose}
        aria-labelledby="game stats"
        open={open}
        maxWidth={false}
        hidezero={true}
      >
        <GameStat game={game} />
      </Dialog>
    </>
  );
});

export default DialogStat;
