import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export function TeamIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M20.4 14h-1.8c.2.6.3 1.1.3 1.7v6.9l-.1.6h3c1 0 2-.9 2-2v-3.7c0-2-1.5-3.4-3.4-3.4zM5.2 15.7c0-.6.1-1.1.3-1.6H3.7a3.4 3.4 0 00-3.4 3.4v3.7c0 1.1 1 2 2 2h3a2 2 0 010-.6zM14.1 12.3H10a3.4 3.4 0 00-3.4 3.4v6.9c0 .3.3.6.7.6h9.5c.4 0 .7-.3.7-.6v-7c0-1.8-1.5-3.3-3.4-3.3zM12 3.3a4 4 0 100 8.2 4 4 0 000-8.2zM4.9 7.1a3 3 0 100 6.1 3 3 0 000-6.1z" />
      <path d="M19.2 7.1a3 3 0 100 6.1 3 3 0 000-6.1z" />
    </SvgIcon>
  );
}

export function StadiumIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.1 18.4c-.7 0-1.3.5-1.3 1.2v1.9h2.6v-1.9c0-.7-.6-1.2-1.3-1.2z" />
      <path d="M21 12.5c-5.6 2.6-16 2.5-20.5-2l.5 2.9c.9 1.4 3.4 2.6 6.5 3.2L7.2 18c-2.4-.5-4.4-1.3-5.8-2.3l.3 1.7c.3 2 3.3 3.4 7.6 3.9v-1.8a2.8 2.8 0 015.6 0v1.8c4.3-.5 7.3-2 7.6-4l.3-1.6c-1.4 1-3.4 1.8-5.8 2.3l-.3-1.5c3.1-.6 5.6-1.8 6.5-3.2l.5-2.8c-.6.7-1.6 1.3-2.8 1.9z" />
      <path d="M12.1 12.7c2 0 3.9-.2 5.5-.6v-1.5h-11V12c1.7.4 3.5.6 5.5.6z" />
      <path d="M4 11.1l1 .5V9h14.1v2.6c2-.7 4.3-2 4.3-3.8C23 4.3 15.8 3 12 3c-3.1 0-6 .5-8.2 1.6-4.2 2-4 4.7 0 6.5zm8-6.4c3.5 0 5.8.7 7.2 1.3l-.6 1.3a19.4 19.4 0 00-13 0L4.8 6c1.4-.6 3.8-1.3 7.2-1.3z" />
    </SvgIcon>
  );
}
