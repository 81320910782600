import { createGlobalStyle } from "styled-components";
import palette from "../styles/palette";
import publicPath from "../helpers/publicPath";

const urlBackgroundGrass = publicPath("interface/grassbottom.png");
const urlPaper = publicPath("interface/paper.jpg");
// https://styled-components.com/docs/api#createglobalstyle
//  ${(props) => (props.whiteColor ? "white" : "black")}

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    /* 
    herbe verte en bas
    background-color: ${palette.background.global};
    background-image: url("${urlBackgroundGrass}");
    background-position: bottom;
    background-repeat: repeat-x; */
    /* 
    fibre carbone
   background: radial-gradient(black 15%, transparent 16%) 0 0,
radial-gradient(black 15%, transparent 16%) 8px 8px,
radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
background-color:#282828;
background-size:16px 16px; */
 /*   paper */
     
    background-image: url("${urlPaper}");
   
    margin: 0;
    padding: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }   
  #root {
    display: flex;
    flex-direction: column;    
    min-height: 100%;
    /* min-width: fit-content; */
  }
  body,
  html {
    height: 100%;
  }
  h1, h2, h3, h4, h5, h6 {
    color: ${palette.secondary.main};
    margin: 0; 
    padding: 0;
    text-transform: uppercase;
    text-shadow: 1px 1px 0px ${palette.secondary.dark};
  }
  .error {
    color: red;
    font-weight: bold;
  }

  /* .rich-editor {
    padding: 10rem;
    }
  .rich-editor .rdw-editor-main {
    background-color: #fff;
    }
  .rich-editor .DraftEditor-editorContainer {
    padding: 1rem;
    }
  .draft-editor-wrapper {
    border: 1px solid #ccc;
  } */
 
`;
export default GlobalStyle;
