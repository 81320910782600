import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import IconDiceBlock from "../../../../Icon/IconDiceBlock";
import CustomTooltip from "../../../../Interface/Tooltip";
import { currentGameState } from "../../../atoms/gameState";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import bp from "../../../../../styles/breakpoint";

export const DicesWrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

export const DiceWrapper = styled.div`
  align-items: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 2px;
`;

export const DiceOrderWrapper = styled.div`
  background-color: black;
  color: white;
  text-align: center;
`;

const Help = styled.div`
  display: none;
  @media screen and (max-width: ${bp.md}) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
  }
`;

export const IsReroll = styled.div`
  background-color: ${(props) => (props.isReroll ? "green" : "red")};
  filter: ${(props) => (props.disabled ? "grayscale(1) opacity(0.5)" : "none")};
  color: white;
  text-align: center;
  border-radius: 0 0 5px 5px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DiceOrderContent = React.memo(
  ({
    value,
    index,
    isReroll,
    onToogle,
    attacker,
    defender,
    onLeft = () => null,
    onRight = () => null,
  }) => {
    const { t } = useTranslation();
    const game = useRecoilValue(currentGameState);
    let rerolls = 0;
    if (attacker.can_reroll) {
      if (game.team1.id === attacker.teamId) {
        rerolls = game.team1.date_next_reroll ? 0 : game.reroll1;
      } else {
        rerolls = game.team2.date_next_reroll ? 0 : game.reroll2;
      }
    }

    return (
      <DiceWrapper>
        <div>
          <IconDiceBlock number={value} />
        </div>
        <DiceOrderWrapper>{index}</DiceOrderWrapper>
        <CustomTooltip
          placement="top"
          title={
            rerolls === 0
              ? t("t:no reroll available", "__no reroll available")
              : t("t:ClickToRerollBlock", "__ClickToRerollBlock")
          }
        >
          <IsReroll
            isReroll={isReroll}
            onClick={onToogle}
            disabled={rerolls === 0}
          >
            {t("t:RerollSmall", "__RerollSmall")}
          </IsReroll>
        </CustomTooltip>
        <Help>
          {index - 1 > 0 && (
            <BsArrowLeftSquare onClick={() => onLeft(index - 1)} />
          )}
          {index - 1 < 4 && (
            <BsArrowRightSquare onClick={() => onRight(index - 1)} />
          )}
        </Help>
      </DiceWrapper>
    );
  }
);

export default DiceOrderContent;
