import { useLazyQuery } from "@apollo/client";
import { PLAYER_STAT } from "enefel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../helpers/browser";
import { GET_PLAYERS_STATS } from "../../query/PlayerQueries";
import QueryError from "../Error/QueryError";
import Loading from "../Loading";
import Ranking from "./Ranking";

import styled from "styled-components";
import Filter from "../Player/Filter";
import { PLAYER_STATE } from "enefel/state";

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const HallOfFame = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    race: "all",
    season: 0,
    onlyCoach: false,
    state: PLAYER_STATE.ACTIVE,
  });
  const [getPlayersStats, { loading, error, data }] =
    useLazyQuery(GET_PLAYERS_STATS);

  setPageTitle(t("t:hall of fame", "__hall of fame"));

  useEffect(() => {
    getPlayersStats({ variables: filter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlayersStats({ variables: filter });
  }, [filter, getPlayersStats]);

  const handleChange = (newFilter) => {
    setFilter({ ...filter, ...newFilter });
  };

  if (error) return <QueryError error={error} />;
  if (loading || !data) return <Loading />;

  return (
    <div>
      <h1>{t("t:hall of fame", "__hall of fame")}</h1>
      <br />
      <br />
      <Filter
        filter={filter}
        onChange={handleChange}
        withStat={false}
        withCoach={false}
        withActif={false}
      />
      <br />
      <br />
      <Wrapper>
        <Ranking
          players={data.players}
          field={PLAYER_STAT.EXPERIENCE}
          title={t(`stat:EXPERIENCE`, `__EXPERIENCE`)}
        />
        <Ranking
          players={data.players}
          field={PLAYER_STAT.TD}
          title={t(`stat:TD`, `__TD`)}
        />
        <Ranking
          players={data.players}
          field={PLAYER_STAT.INJURY_CASUALTY}
          title={t(`stat:INJURY_CASUALTY`, `__INJURY_CASUALTY`)}
        />
        <Ranking
          players={data.players}
          field={PLAYER_STAT.PASS_PERFECT_COMPLETED}
          title={t(`stat:PASS_PERFECT_COMPLETED`, `__PASS_PERFECT_COMPLETED`)}
        />
        <Ranking
          players={data.players}
          field={PLAYER_STAT.GAME_PLAYED}
          title={t(`stat:GAME_PLAYED`, `__GAME_PLAYED`)}
        />
        <Ranking
          isAverage={true}
          players={data.players}
          field={PLAYER_STAT.MOVE}
          title={t(`stat:MOVE BY GAME`, `__MOVE BY GAME`)}
        />
        <Ranking
          players={data.players}
          field={PLAYER_STAT.INTERCEPTION_SUCCESS}
          title={t(`stat:INTERCEPTION_SUCCESS`, `__INTERCEPTION_SUCCESS`)}
        />
      </Wrapper>
      <Wrapper>
        <Ranking
          players={data.players}
          field={PLAYER_STAT.TURNOVER}
          title={t(`stat:TURNOVER`, `TURNOVER`)}
          isNegative={true}
        />
        <Ranking
          players={data.players}
          field={PLAYER_STAT.PASS_FUMBLE}
          title={t(`stat:PASS_FUMBLE`, `PASS_FUMBLE`)}
          isNegative={true}
        />
        <Ranking
          players={data.players}
          field={PLAYER_STAT.RECEIVE_INJURY_CASUALTY}
          title={t(`stat:RECEIVE_INJURY_CASUALTY`, `RECEIVE_INJURY_CASUALTY`)}
          isNegative={true}
        />
        <Ranking
          players={data.players}
          field={PLAYER_STAT.REROLL}
          title={t(`stat:REROLL`, `REROLL`)}
          isNegative={true}
        />
      </Wrapper>
    </div>
  );
};

export default HallOfFame;
