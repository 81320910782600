import { helpSkillsToDisplay } from "enefel/skill";
import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox, { LabelFlex } from "../../Interface/checkbox";
import styled from "styled-components/macro";
import palette from "../../../styles/palette";
import theme from "../../../styles/theme";
import debounce from "lodash/debounce";

const Block = styled.div`
  background-color: ${palette.primary.dark};
  box-shadow: ${theme.shadows[1]};
  color: white;
  padding: 1.5rem;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`;

const SkillName = styled.span`
  margin-right: 2rem;
`;

const Color = styled.input`
  border: none;
  cursor: pointer;
  height: 15px;
  margin-right: 1rem;
  width: 20px;
  background-color: ${(props) => props.color};
`;

const ShowSkills = React.memo(
  ({ showSkills, handleToggleSkill, handleSkillColorChange }) => {
    const { t } = useTranslation("skill");

    const debounceHandleColorChange = debounce((color, skillId) => {
      handleSkillColorChange(color, skillId);
    }, 200);

    return (
      <Block>
        {Object.keys(helpSkillsToDisplay).map((skillId) => {
          return (
            <Wrapper key={skillId}>
              <Color
                type="color"
                color={showSkills[skillId]?.color}
                value={showSkills[skillId]?.color}
                onChange={(e) =>
                  debounceHandleColorChange(e.target.value, skillId)
                }
              />
              <LabelFlex>
                <SkillName>{t(`skill:${skillId}`, `__${skillId}`)}</SkillName>
                <Checkbox
                  isOn={showSkills[skillId]?.actif}
                  handleToggle={() => {
                    return handleToggleSkill(skillId);
                  }}
                />
              </LabelFlex>
            </Wrapper>
          );
        })}
      </Block>
    );
  }
);

export default ShowSkills;
