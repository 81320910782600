import { ShowTackleButton } from "./PlayerPosition/TackleZone";
import { TILE_SIZE } from "./Stadium";
import { useTranslation } from "react-i18next";
import CustomTooltip from "../../Interface/Tooltip";
import DialogStat from "../Stat/DialogStat";
import React from "react";
import styled from "styled-components";
import DialogShowSkills from "../ShowSkills/DialogShowSkills";
import { BsFillSunFill } from "react-icons/bs";

const PitchIndicator = styled.div`
  background-color: green;
  border: 1px solid ${(props) => (props.isShow ? "white" : "green")};
  cursor: pointer;
  height: ${(props) => props.size * 0.6 + "px"};
  width: ${(props) => props.size * 0.6 + "px"};
`;

const ActivateMeteo = styled.div`
  cursor: pointer;
  height: ${(props) => props.size * 0.6 + "px"};
  width: ${(props) => props.size * 0.6 + "px"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffc200;
  filter: grayscale(${(props) => (props.showMeteo ? "0" : "1")});
`;

const ShowActionPoint = styled.div`
  background-color: green;
  cursor: pointer;
  height: ${(props) => props.size * 0.6 + "px"};
  width: ${(props) => props.size * 0.6 + "px"};
  position: relative;
  filter: grayscale(${(props) => (props.isShow ? "0" : "1")});

  & > * {
    position: absolute;
    width: 30%;
    height: 30%;
    top: calc((100% / 2) - (30% / 2));
    border-radius: 50%;
  }

  & > *:first-child {
    background-color: red;
    border: 1px solid black;
    border-radius: 100%;
    left: calc((100% / 2) - (30%));
  }

  & > *:last-child {
    background-color: greenyellow;
    border: 1px solid black;
    border-radius: 100%;
    left: calc((100% / 2) - (30% / 4));
  }
`;

const Configs = React.memo(
  ({
    color1,
    color1Bis,
    color2,
    color2Bis,
    handleChangeShowTZ,
    handlePitchIndicator,
    handleChangeShowIcons,
    handleToggleSkill,
    pitchIndicator,
    showTZ,
    showIcons,
    showSkills,
    handleSkillColorChange,
    handleToggleMeteo,
    showMeteo,
  }) => {
    const { t } = useTranslation();

    return (
      <>
        <ShowTackleButton
          onChangeShowTZ={handleChangeShowTZ}
          side={1}
          isShow={showTZ ? showTZ["team1"] : false}
          color={color1}
          border={color1Bis}
        />

        <ShowTackleButton
          onChangeShowTZ={handleChangeShowTZ}
          side={2}
          isShow={showTZ ? showTZ["team2"] : false}
          color={color2}
          border={color2Bis}
        />

        <CustomTooltip
          placement="top"
          title={
            showIcons
              ? t("t:HideIcons", "__HideIcons")
              : t("t:ShowIcons", "__ShowIcons")
          }
        >
          <ShowActionPoint
            isShow={showIcons}
            onClick={handleChangeShowIcons}
            size={TILE_SIZE}
          >
            <span></span>
            <span></span>
          </ShowActionPoint>
        </CustomTooltip>

        <CustomTooltip
          placement="top"
          title={
            pitchIndicator
              ? t("t:HidePitchSquare", "__HidePitchSquare")
              : t("t:ShowPitchSquare", "__ShowPitchSquare")
          }
        >
          <PitchIndicator
            isShow={pitchIndicator}
            size={TILE_SIZE}
            onClick={handlePitchIndicator}
          />
        </CustomTooltip>

        <DialogStat />

        <DialogShowSkills
          showSkills={showSkills}
          handleToggleSkill={handleToggleSkill}
          handleSkillColorChange={handleSkillColorChange}
        />

        <CustomTooltip
          placement="top"
          title={
            showMeteo
              ? t("t:HideMeteo", "__HideMeteo")
              : t("t:ShowMeteo", "__ShowMeteo")
          }
        >
          <ActivateMeteo
            size={TILE_SIZE}
            showMeteo={showMeteo}
            onClick={handleToggleMeteo}
          >
            <BsFillSunFill />
          </ActivateMeteo>
        </CustomTooltip>
      </>
    );
  }
);
export default Configs;
