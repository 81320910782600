import { useMutation, useQuery } from "@apollo/client";
import { STORE_ITEM_TYPE } from "enefel/store";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { setPageTitle } from "../../helpers/browser";
import { BUY_ITEM, GET_USER_PURCHASES } from "../../query/StoreQueries";
import QueryError from "../Error/QueryError";
import Loading from "../Loading";
import { RootContext } from "../RootContext";
import StoreAvatars from "./StoreAvatar";
import StoreBadges from "./StoreBadges";

const Wrapper = styled.div``;

const StoreShowWithUser = memo(({ user }) => {
  const { t } = useTranslation();

  const { setUser } = useContext(RootContext);
  const { loading, error, data } = useQuery(GET_USER_PURCHASES);
  const [buyItemStore, { loading: loadingBuy, error: errorBuy }] =
    useMutation(BUY_ITEM);

  const handleBuyAvatar = (itemKey) => {
    if (loading) {
      return;
    }
    buyItemStore({
      variables: {
        itemKey,
        type: STORE_ITEM_TYPE.AVATAR,
      },
    }).then(({ data }) => {
      setUser({ ...user, ...data.buyItemStore });
    });
  };
  const handleBuy = (itemKey) => {
    if (loading) {
      return;
    }
    buyItemStore({
      variables: {
        itemKey,
        type: STORE_ITEM_TYPE.BADGE,
      },
    }).then(({ data }) => {
      setUser({ ...user, ...data.buyItemStore });
    });
  };

  setPageTitle(t("t:players", "__players"));
  if (loading) return <Loading />;
  return (
    <Wrapper>
      <h1>{t("t:Shop", "__Shop")}</h1>
      <br />
      {(error || errorBuy) && <QueryError error={error || errorBuy} />}

      <StoreBadges
        tooth={user ? user.tooth : 0}
        onBuy={handleBuy}
        loading={loadingBuy}
        purchases={data?.me?.purchases || []}
      />
      <br />
      <StoreAvatars
        tooth={user ? user.tooth : 0}
        onBuy={handleBuyAvatar}
        loading={loadingBuy}
        purchases={data?.me?.purchases || []}
      />
    </Wrapper>
  );
});

const StoreShow = memo(() => {
  const { user } = useContext(RootContext);

  return <StoreShowWithUser user={user} />;
});

export default StoreShow;
