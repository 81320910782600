import React from "react";
import { getCareerFromPlayer } from "enefel/race";
import styled from "styled-components";
import { BB2020Carac } from "enefel/player";

const Wrapper = styled.span`
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
`;
const WrapperLess = styled.span`
  font-weight: normal;
  color: red;
`;

const PlayerCharac = React.memo(({ prop, player, color = "red" }) => {
  const career = getCareerFromPlayer(player);
  let carac = player[prop];
  if (career && career[prop]) {
    if (career[prop] < carac) {
      return <Wrapper>{BB2020Carac(carac, prop)}</Wrapper>;
    }
    if (career[prop] > carac) {
      return <WrapperLess>{BB2020Carac(carac, prop)}</WrapperLess>;
    }
  }
  return <>{BB2020Carac(carac, prop)}</>;
});
export default PlayerCharac;
