import React from "react";
import {
  StadiumCoordinatesX,
  StadiumCoordinatesY,
} from "./StadiumCoordinates/StadiumCoordinates";
import Ball from "./Ball/Ball";
import Pitch from "./Pitch/Pitch";
import PitchLines from "../../../Stadium/PitchLines";
import PlayersLayer from "./PlayersLayer/PlayersLayer";
import FieldAction from "../../Action/FieldAction";
import { useRecoilValue } from "recoil";
import { currentGameState } from "../../atoms/gameState";
import TDZones from "./TDZones/TDZones";
import FocusTile from "./Focus/FocusTile";
import HighlightTiles from "./Focus/HighlightTiles";
import Blood from "./Blood/Blood";
import MeteoEffect from "../../../Meteo/MeteoEffect";

const Field = React.memo(
  ({
    currentPlayer,
    currentPlayerTeam,
    isSetUpVisible,
    players1,
    players2,
    showIcons,
    showTZ,
    pitchIndicator,
    color1,
    color2,
    showSkills,
    showMeteo,
  }) => {
    const game = useRecoilValue(currentGameState);

    if (!game) {
      return null;
    }
    return (
      <>
        <Pitch game={game} pitchIndicator={pitchIndicator} />
        <TDZones
          stadium_height={game.stadium_height}
          stadium_width={game.stadium_width}
          color1={color1}
          color2={color2}
        />
        <PitchLines
          stadium_height={game.stadium_height}
          stadium_width={game.stadium_width}
          stadium_zone={game.stadium_zone}
        />
        <StadiumCoordinatesX stadium_width={game.stadium_width} />
        <StadiumCoordinatesY stadium_height={game.stadium_height} />
        <Blood gameId={game.id} />
        <FocusTile />
        {players1.length > 0 && (
          <PlayersLayer
            color={color1}
            currentPlayer={currentPlayer}
            isSetUpVisible={isSetUpVisible}
            players={players1}
            showTZ={showTZ ? showTZ.team1 : false}
            side={1}
            showIcons={showIcons}
            showSkills={showSkills}
          />
        )}
        {players2.length > 0 && (
          <PlayersLayer
            color={color2}
            currentPlayer={currentPlayer}
            isSetUpVisible={isSetUpVisible}
            players={players2}
            showTZ={showTZ ? showTZ.team2 : false}
            side={2}
            showIcons={showIcons}
            showSkills={showSkills}
          />
        )}
        <Ball x={game.ball_x} y={game.ball_y} />{" "}
        {/* Actions displayed in the field */}
        <br />
        <br />
        <FieldAction
          currentPlayerTeam={currentPlayerTeam}
          currentPlayer={currentPlayer}
          players={players1.concat(players2)}
        />
        <HighlightTiles />
        {showMeteo && (
          <MeteoEffect
            value={game.meteo}
            stadium_height={game.stadium_height}
            stadium_width={game.stadium_width}
          />
        )}
      </>
    );
  }
);
export default Field;
