import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";
import isDev from "./helpers/devDetect";
import { getFromStore, STORE_KEYS } from "./storage/Storage";
export const languagesList = ["en", "fr"];
const ns = [
  "t",
  "stat",
  "race",
  "skill",
  "badge",
  "gameHistory",
  "inducement",
  "item",
  "meteo",
  "rule",
  "stat",
];
i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: isDev(),
    ns,
    lng: getFromStore(STORE_KEYS.LANGUAGE) || "en",
    fallbackLng: "en",
    whitelist: languagesList,

    interpolation: {
      skipOnVariables: false,
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
