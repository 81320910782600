import palette from "../../styles/palette";
import styled from "styled-components";
import bp from "../../styles/breakpoint";

const TableWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  min-width: ${(props) => (props.min ? props.min : null)};
  .sticky {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &.sortable {
    @media screen and (max-width: ${bp.md}) {
      padding-top: 2em;
      background-color: ${palette.primary.dark};
      border-radius: 10px 10px 0 0;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Thead = styled.thead`
  tr:first-child {
    display: ${(props) => (props.visible !== false ? null : "none")};
  }
  tr:first-child th:first-child {
    border-radius: 10px 0 0 0;
  }
  tr:first-child th:last-child {
    border-radius: 0 10px 0 0;
  }
  tr:nth-child(2) th:first-child {
    border-radius: ${(props) => (props.visible !== false ? "0" : "10px 0 0 0")};
  }
  tr:nth-child(2) th:last-child {
    border-radius: ${(props) => (props.visible !== false ? "0" : "0 10px 0 0")};
  }
`;

const HeaderTr = styled.tr`
  background: linear-gradient(
    ${palette.secondary.main},
    ${palette.secondary.dark}
  );
  color: ${palette.secondary.contrastText};
  text-transform: uppercase;
  font-size: 0.8em;
`;

const Th = styled.th`
  padding: 0.4rem 0.5rem;
  text-align: left;
  width: ${(props) => (props.width ? props.width + "px" : null)};
  white-space: nowrap;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

const Td = styled.td`
  font-size: ${(props) => (props.size ? props.size + "em" : null)};
  padding: 0.2rem 0.5rem;
  opacity: ${(props) => (props.alphaValue ? 0.3 : null)};
  ${(props) => {
    return props.separators
      ? props.separators
          .map(
            (s) => `&:nth-child(${s + 1}) {
      border-left: 2px solid ${palette.secondary.light};
    }`
          )
          .join(" ")
      : null;
  }};
`;

const Tr = styled.tr`
  filter: ${(props) =>
    props.isOnField == null || props.isOnField ? "sepia(0)" : "sepia(1)"};
  background-color: ${palette.primary.main};
  color: ${palette.primary.contrastText};
  cursor: ${(props) =>
    props.isCursor ? "pointer" : props.draggable ? "grab" : null};

  ${(props) =>
    props.isCurrent
      ? `& :first-child:before {     
        background-color: ${palette.secondary.main};
        content: "";
        position: absolute;
        width: 6px;
        height: 100%;
        top: 0;
        left: 0;
      }`
      : null}

  &:nth-child(2n) {
    background-color: ${palette.primary.light};
  }
  &:hover {
    background-color: ${palette.primary.dark};
  }

  a {
    color: ${palette.secondary.contrastText};
  }
`;

export { TableWrapper, Table, Td, Tr, Thead, HeaderTr, Th };
