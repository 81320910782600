// .flex {
//   display: flex;
//   --gap: 0px;
//   --column-gap: var(--gap);
//   --row-gap: var(--gap);
//   margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);
// }

// .flex > * {
//   margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
// }

export const gap = (value) => {
  return `
      display: flex; 
      margin: calc(${value} / -2) calc(${value} / -2);
     

      & > * {
        margin: calc(${value} / 2) calc(${value} / 2);
      }
    `;
};
