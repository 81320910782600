import gql from "graphql-tag";
import { PlayerRaw, TeamRaw } from "./Fragments";

export const GET_TEAM_APPLICATIONS = gql`
  query getTeam($id: ID!) {
    team(id: $id) {
      id
      postulations {
        team_id
        player_id
        createdAt
        updatedAt
      }
    }
  }
`;

export const BUY_STADIUM = gql`
  mutation buyStadium($coachId: ID, $type: String, $stadiumSize: String) {
    buyStadium(coachId: $coachId, type: $type, stadiumSize: $stadiumSize) {
      ...teamRaw
    }
  }
  ${TeamRaw}
`;

export const GET_TEAM_REWARDS = gql`
  query teamRewards($teamId: ID!) {
    teamRewards(teamId: $teamId) {
      id
      name
      league {
        id
        name
        season
      }
    }
  }
`;
export const BUY_REROLL_COACH = gql`
  mutation buyRerollCoach($coachId: ID) {
    buyRerollCoach(coachId: $coachId) {
      ...teamRaw
    }
  }
  ${TeamRaw}
`;

export const ADD_TEAM_POSTULATION = gql`
  mutation addTeamPostulation($teamId: ID, $playerId: ID) {
    addTeamPostulation(teamId: $teamId, playerId: $playerId) {
      player_id
      team_id
      createdAt
      updatedAt
    }
  }
`;

export const ANSWER_TEAM_POSTULATION = gql`
  mutation answerTeamPostulation(
    $coachId: ID
    $playerId: ID
    $isAccepted: Boolean
  ) {
    answerTeamPostulation(
      coachId: $coachId
      playerId: $playerId
      isAccepted: $isAccepted
    ) {
      ...teamRaw
      postulations {
        team_id
        player_id
        createdAt
        updatedAt
      }
      players {
        ...playerRaw
      }
    }
  }
  ${TeamRaw}
  ${PlayerRaw}
`;

export const SET_TEAM_IS_OPEN = gql`
  mutation setTeamIsOpen($coachId: ID, $isOpen: Boolean!) {
    setTeamIsOpen(coachId: $coachId, isOpen: $isOpen) {
      ...teamRaw
    }
  }
  ${TeamRaw}
`;

export const SET_TEAM_DISCORD_ID = gql`
  mutation updateTeamDiscordId($coachId: ID, $discordId: String!) {
    updateTeamDiscordId(coachId: $coachId, discordId: $discordId) {
      ...teamRaw
    }
  }
  ${TeamRaw}
`;

export const CHANGE_TEAM_COACH = gql`
  mutation changeCoach($coachId: ID, $newCoachId: ID) {
    changeCoach(coachId: $coachId, newCoachId: $newCoachId) {
      coach {
        ...playerRaw
      }
      ...teamRaw
    }
  }
  ${TeamRaw}
  ${PlayerRaw}
`;

export const CHANGE_TEAM_COLOR = gql`
  mutation changeTeamColor($coachId: ID!, $color: String!) {
    changeTeamColor(coachId: $coachId, color: $color) {
      ...teamRaw
    }
  }
  ${TeamRaw}
`;

export const CHANGE_PLAYER_ORDER = gql`
  mutation changePlayerOrder($coachId: ID!, $playerId: ID!, $newOrder: Int) {
    changePlayerOrder(
      coachId: $coachId
      playerId: $playerId
      newOrder: $newOrder
    ) {
      ...teamRaw
      players {
        id
        name
        order
        connectedAt
      }
    }
  }
  ${TeamRaw}
`;

export const CREATE_TEAM = gql`
  mutation createTeam($playerId: ID!, $teamName: String!) {
    createTeam(playerId: $playerId, teamName: $teamName) {
      ...teamRaw
      players {
        id
        name
        canCreateTeam
        order
        team {
          id
          name
          coach_id
        }
      }
    }
  }
  ${TeamRaw}
`;

export const JOIN_TEAM = gql`
  mutation joinTeam($playerId: ID!, $teamId: ID!) {
    joinTeam(playerId: $playerId, teamId: $teamId) {
      ...teamRaw
      coach {
        ...playerRaw
      }
      players {
        ...playerRaw
      }
    }
  }
  ${TeamRaw}
  ${PlayerRaw}
`;

export const REMOVE_FROM_TEAM = gql`
  mutation quitTeam($playerId: ID, $coachId: ID) {
    removeFromTeam(playerId: $playerId, coachId: $coachId) {
      ...teamRaw
      coach {
        ...playerRaw
      }
      players {
        ...playerRaw
      }
    }
  }
  ${TeamRaw}
  ${PlayerRaw}
`;

export const QUIT_TEAM = gql`
  mutation quitTeam($playerId: ID!) {
    quitTeam(playerId: $playerId) {
      ...teamRaw
      coach {
        ...playerRaw
      }
      players {
        ...playerRaw
      }
    }
  }
  ${TeamRaw}
  ${PlayerRaw}
`;

export const SET_UP_POSITION = gql`
  mutation setUpPosition($playerId: ID!, $x: Int!, $y: Int!) {
    setUpPosition(playerId: $playerId, x: $x, y: $y) {
      ...teamRaw
      players {
        ...playerRaw
        x
        y
      }
    }
  }
  ${PlayerRaw}
  ${TeamRaw}
`;

export const SET_UP_POSITION_DUGOUT = gql`
  mutation setUpPositionDugout($playerId: ID!, $targetId: ID!) {
    setUpPositionDugout(playerId: $playerId, targetId: $targetId) {
      ...teamRaw
      players {
        ...playerRaw
        x
        y
      }
    }
  }
  ${PlayerRaw}
  ${TeamRaw}
`;

export const GET_TEAM = gql`
  query getTeam($id: ID!) {
    team(id: $id) {
      ...teamRaw
      coach {
        ...playerRaw
        team {
          id
          color1
        }
      }
      players {
        ...playerRaw
        team {
          id
          color1
        }
      }
    }
  }
  ${PlayerRaw}
  ${TeamRaw}
`;

export const GET_TEAMS = gql`
  {
    teams {
      ...teamRaw
      coach {
        ...playerRaw
        team {
          id
          color1
        }
      }
      players {
        ...playerRaw
        team {
          id
          color1
        }
      }
      league {
        id
        name
      }
    }
  }
  ${PlayerRaw}
  ${TeamRaw}
`;

export const GET_TEAM_HISTORY = gql`
  query teamHistory($id: ID) {
    teamHistory(id: $id) {
      id
      type
      info
      createdAt
      player_id
    }
  }
`;

export const GET_TEAM_GAMES = gql`
  query teamGames($id: ID) {
    teamGames(id: $id) {
      id
      team1 {
        id
        name
      }
      team2 {
        id
        name
      }
      score1
      score2
      type
      league {
        id
        season
      }
    }
  }
`;

export const UPDATE_TEAM_DESCRIPTION = gql`
  mutation editTeamDescription($teamId: ID, $message: String, $playerId: ID) {
    editTeamDescription(
      teamId: $teamId
      message: $message
      playerId: $playerId
    ) {
      ...teamRaw
    }
  }
  ${TeamRaw}
`;
