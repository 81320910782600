import { atom, selector } from "recoil";

export const selectedPlayerState = atom({
  key: "game-state-selected-player",
  default: null,
});

export const defaultGameState = {
  action: null,
  currentStep: null,
  dices: {},
  game: null,
  passTarget: null,
  pushs: null,
  pushsFrenzy: null,
  stepsGame: [],
  fumbleBall: false,
};

const isHistory = (state) => {
  return (
    state.currentStep !== null &&
    state.game !== null &&
    state.currentStep < state.stepsGame.length
  );
};

export const gameState = atom({
  key: "game-state",
  default: defaultGameState,
});

export const isHistoryState = selector({
  key: "is-history-state",
  get: ({ get }) => {
    const state = get(gameState);
    return isHistory(state);
  },
});

export const actionState = selector({
  key: "game-state-action",
  get: ({ get }) => {
    const state = get(gameState);
    return state.action;
  },
});

export const fumbleBallState = selector({
  key: "fumble-state-ball",
  get: ({ get }) => {
    const state = get(gameState);
    return state.fumbleBall;
  },
});

export const currentGameState = selector({
  key: "game-state-game",
  get: ({ get }) => {
    const state = get(gameState);
    return isHistory(state) && state.currentStep > 0
      ? state.stepsGame[state.currentStep - 1]
      : state.game;
  },
});

export const currentRealGame = selector({
  key: "game-real-game",
  get: ({ get }) => {
    const state = get(gameState);
    return state.game;
  },
});

export const passTargetState = selector({
  key: "game-state-pass-target",
  get: ({ get }) => {
    const state = get(gameState);
    return state.passTarget;
  },
});

export const pushsState = selector({
  key: "game-state-pushs",
  get: ({ get }) => {
    const state = get(gameState);
    return state.pushs;
  },
});

export const pushsStateFrenzy = selector({
  key: "game-state-pushs-frenzy",
  get: ({ get }) => {
    const state = get(gameState);
    return state.pushsFrenzy;
  },
});

export const dicesState = selector({
  key: "game-state-dices",
  get: ({ get }) => {
    const state = get(gameState);
    return state.dices;
  },
});
