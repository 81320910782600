import { memo } from "react";
import { useTranslation } from "react-i18next";
import CustomTooltip from "../../Interface/Tooltip";
import IconPlayer from "../IconPlayer";
import DetailInducement from "./DetailInducement";
import { INDUCEMENT } from "enefel/inducement";

const InducementIcon = memo(({ name }) => {
  const { t } = useTranslation("inducement");
  return (
    <CustomTooltip
      placement="top"
      title={
        <DetailInducement
          title={t(`inducement:${name}`, `__${name}`)}
          description={t(
            `inducement:${name} description`,
            `__${name} description`
          )}
          icon={name === INDUCEMENT.BRIBE.key ? null : `${name}.jpeg`} // Create image for bribe
        />
      }
    >
      <div>
        <IconPlayer name={`staff-${name}`} />
      </div>
    </CustomTooltip>
  );
});

export default InducementIcon;
