import { useApolloClient, useQuery } from "@apollo/client";
import { GET_PLAYER } from "../../query/PlayerQueries";
import React from "react";
import PlayerLink from "./PlayerLink";
import Loading from "../Loading";
import QueryError from "../Error/QueryError";
import { PLAYER_STATUS } from "enefel/status";
import { PlayerRaw } from "../../query/Fragments";

const PlayerLoaded = React.memo(
  ({
    big = false,
    color = null,
    colorContrast = null,
    horizontal = false,
    isActive = true,
    onClick = null,
    playerId,
    side = 1,
    size = "24px",
    status = PLAYER_STATUS.STANDING,
    withIcon = true,
    withName = true,
  }) => {
    const { loading, error, data } = useQuery(GET_PLAYER, {
      variables: { id: playerId },
    });

    if (loading) return <Loading />;
    if (error) return <QueryError error={error} />;
    if (!data.player) {
      return null;
    }

    const player = data.player;
    return (
      <PlayerLink
        big={big}
        color={color ? color : player.team ? player.team.color1 : null}
        colorContrast={colorContrast}
        horizontal={horizontal}
        isActive={isActive}
        onClick={onClick}
        player={player}
        side={side}
        size={size}
        status={status}
        withIcon={withIcon}
        withName={withName}
      />
    );
  }
);

const PlayerLinkFromId = React.memo((props) => {
  const {
    big = false,
    color = null,
    colorContrast = null,
    horizontal = false,
    isActive = true,
    onClick = null,
    playerId,
    side = 1,
    size = "24px",
    status = PLAYER_STATUS.STANDING,
    withIcon = true,
    withMorale = true,
    withName = true,
  } = { ...props };
  const client = useApolloClient();
  let playerCache;
  try {
    playerCache = client.readFragment({
      id: "Player:" + playerId,
      fragment: PlayerRaw,
    });
  } catch (error) {
    console.log("Error load player", error);
  }

  let player = {};
  if (playerCache) {
    player = { ...player, ...playerCache, id: playerId };
  } else {
    return <PlayerLoaded {...props} />;
  }
  return (
    <PlayerLink
      big={big}
      color={color ? color : player.team ? player.team.color1 : null}
      colorContrast={colorContrast}
      horizontal={horizontal}
      isActive={isActive}
      onClick={onClick}
      player={player}
      side={side}
      size={size}
      withIcon={withIcon}
      withName={withName}
      withMorale={withMorale}
      status={status}
    />
  );
});

export default PlayerLinkFromId;
