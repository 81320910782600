import { getRaceGroup, GROUP_NAME } from "enefel/race";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaBiohazard } from "react-icons/fa";
import { FaFootballBall } from "react-icons/fa";
import CustomTooltip from "../Interface/Tooltip";
import styled from "styled-components";

import { GiDevilMask } from "react-icons/gi";
import { MdSentimentNeutral } from "react-icons/md";
import { GiSpikeball } from "react-icons/gi";

const Wrapper = styled.div`
  margin-top: 3px;
`;

export const GroupIcon = React.memo(({ group }) => {
  const { t } = useTranslation("race");
  const Icon =
    group === GROUP_NAME.AGILE
      ? FaFootballBall
      : group === GROUP_NAME.CHAOTIC
      ? FaBiohazard
      : group === GROUP_NAME.EXOTIC
      ? GiSpikeball
      : group === GROUP_NAME.VICIOUS
      ? GiDevilMask
      : group === GROUP_NAME.NEUTRAL
      ? MdSentimentNeutral
      : null;

  if (!Icon) {
    return null;
  }
  return (
    <CustomTooltip placement="top" title={t(`race:${group}`)}>
      <Wrapper>
        <Icon />
      </Wrapper>
    </CustomTooltip>
  );
});

const Group = React.memo(({ race }) => {
  const group = getRaceGroup(race);
  return <GroupIcon group={group} />;
});

export default Group;
