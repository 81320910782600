import { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import publicPath from "../../helpers/publicPath";
import { BoxHeader } from "../Interface/Box";
import Col from "../Interface/Col";

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
  max-width: 500px;
`;

const Text = styled.div`
  padding: 0 1em;
  text-align: center;
  color: white;
  font-size: 1.5em;
`;

const PaymentClose = memo(() => {
  const { t } = useTranslation();
  const src = publicPath(`interface/404.png`);

  return (
    <Wrapper>
      <Col hasMax={false}>
        <BoxHeader hasMax={false}>
          {t("t:buy some teeth", "__buy some teeth")}
        </BoxHeader>
        <Content>
          <img
            src={src}
            alt={t("t:wip", "__wip")}
            width={`100%`}
            height={`100%`}
          />
          <Text>{t("t:We are working on it", "__We are working on it")}</Text>
        </Content>
      </Col>
    </Wrapper>
  );
});

export default PaymentClose;
