import React from "react";

import { getAvatarTypeFromPlayer, getPlayerAvatarPath } from "enefel/race";
import publicPath from "../../../helpers/publicPath";

import AvatarImg from "./AvatarImg";

const AvatarShow = React.memo(
  ({ player, avatarFile, isUsed = false, withTooltip = true }) => {
    const avatarType = getAvatarTypeFromPlayer(player, avatarFile);
    if (!avatarType) {
      return null;
    }
    const path = getPlayerAvatarPath(player);
    const avatar = publicPath(`${path}/${avatarType}/${avatarFile}`);

    return (
      <AvatarImg
        alt={player.name}
        src={avatar}
        isUsed={isUsed}
        withTooltip={withTooltip}
      />
    );
  }
);

export default AvatarShow;
