import React from "react";
import { isSetUp } from "enefel";
import { PlayerPositions } from "../../PlayerPosition/PlayerPosition";
import TackeZones from "../../PlayerPosition/TackleZone";
import SetUp from "../../PlayerPosition/SetUp";
import { currentGameState } from "../../../atoms/gameState";
import { useRecoilValue } from "recoil";
import { GAME_STATUS } from "enefel/game";

const PlayersLayer = React.memo(
  ({
    color,
    currentPlayer,
    isSetUpVisible,
    players,
    showTZ,
    side,
    showIcons,
    showSkills,
  }) => {
    const game = useRecoilValue(currentGameState);
    const team = side === 1 ? game.team1 : game.team2;
    const isCurrentPlayerTeam =
      currentPlayer && currentPlayer.teamId === team?.id;
    return (
      <>
        <PlayerPositions
          ball_player_id={game.ball_player_id}
          color={color}
          game={game}
          players={players}
          showIcons={showIcons}
          showTZ={showTZ}
          side={side}
          showSkills={showSkills}
        />

        {showTZ && game.status === GAME_STATUS.ONGOING && (
          <TackeZones side={side} players={players} color={color} />
        )}
        {isSetUp(game) && isCurrentPlayerTeam && (
          <SetUp
            currentPlayer={currentPlayer}
            isSetUpVisible={isSetUpVisible}
            players={players}
            side={side}
          />
        )}
      </>
    );
  }
);
export default PlayersLayer;
