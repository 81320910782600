import React from "react";

import UsersChart from "./UsersChart";
import { useQuery } from "@apollo/client";
import Loading from "../Loading";
import QueryError from "../Error/QueryError";
import { GET_USERS } from "../../query/UserQueries";

const Charts = ({ users }) => {
  const { loading, error, data } = useQuery(GET_USERS);

  if (error) return <QueryError error={error} />;
  if (loading || !data) return <Loading />;
  return <UsersChart users={data.users} />;
};

export default Charts;
