import { Dialog } from "@mui/material";
import { memo, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import styled from "styled-components";
import { ButtonStyle } from "../../Interface/Button";
import IconTooth from "../../Store/IconTooth";
import Payment from "../../Store/Payment";
import PaymentClose from "../../Store/PaymentClose";

const Wrapper = styled(ButtonStyle)`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.2em;
  transform: scale(0.8);
  animation-name: stretch;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: 5;
  animation-play-state: running;

  @keyframes stretch {
    0% {
      transform: scale(0.75);
    }

    10% {
      transform: scale(0.85);
    }

    20% {
      transform: scale(0.8);
    }
  }
`;

const Quantity = styled.div`
  font-size: 1.8em;
`;

const IconWrapper = styled.div`
  font-size: 1.5em;
  color: white;
  align-self: self-end;
  text-shadow: 1px 1px white, -1px -1px #444;
`;

const ToothDisplay = memo(({ tooth = 0 }) => {
  const canShowShop = true;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Wrapper onClick={handleClickOpen}>
        <IconTooth />
        <Quantity>{tooth}</Quantity>
        <IconWrapper>
          <MdAddCircleOutline />
        </IconWrapper>
      </Wrapper>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
          },
        }}
        onClose={handleClose}
        aria-labelledby="game stats"
        open={open}
        maxWidth={false}
        hidezero={true}
      >
        {canShowShop && <Payment onClose={handleClose} />}
        {!canShowShop && <PaymentClose />}
      </Dialog>
    </>
  );
});

export default ToothDisplay;
