import React from "react";
import styled from "styled-components";
import { TILES } from "enefel/pitch";
import { TILE_SIZE } from "../Game/Stadium/Stadium";

const Line = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.8);
  height: ${(props) => (props.height != null ? props.height : 0)};
  left: ${(props) => (props.left != null ? props.left : null)};
  position: absolute;
  top: ${(props) => (props.top != null ? props.top : null)};
  width: ${(props) => (props.width != null ? props.width : 0)};
`;

const PitchLines = React.memo(
  ({ stadium_height, stadium_width, stadium_zone }) => {
    const TD1 =
      ((stadium_zone.indexOf(TILES.TD1) % stadium_width) + 1) * TILE_SIZE;
    const TD2 = (stadium_zone.indexOf(TILES.TD2) % stadium_width) * TILE_SIZE;
    const S1 =
      ((stadium_zone.indexOf(TILES.SCRIMMAGE1) % stadium_width) + 1) *
      TILE_SIZE;
    const S2 =
      (stadium_zone.indexOf(TILES.SCRIMMAGE2) % stadium_width) * TILE_SIZE;
    const LAT1 =
      Math.floor(stadium_zone.indexOf(TILES.CENTRE1) / stadium_width) *
      TILE_SIZE;
    const LAT2 =
      Math.floor(stadium_zone.indexOf(TILES.LATERALB1) / stadium_width) *
      TILE_SIZE;
    return (
      <>
        {/* LAT1  */}
        <Line
          top={LAT1 + "px"}
          left={0}
          width={TILE_SIZE * stadium_width + "px"}
        />

        {/* LAT2  */}
        <Line
          top={LAT2 + "px"}
          left={0}
          width={TILE_SIZE * stadium_width + "px"}
        />

        {/* S1  */}
        <Line
          top={0}
          left={S1 + "px"}
          height={TILE_SIZE * stadium_height + "px"}
        />
        {/* S2  */}
        {S1 !== S2 && (
          <Line
            top={0}
            left={S2 + "px"}
            height={TILE_SIZE * stadium_height + "px"}
          />
        )}
        {/* TD1  */}
        <Line
          top={0}
          left={TD1 + "px"}
          height={TILE_SIZE * stadium_height + "px"}
        />
        {/* TD2  */}
        <Line
          top={0}
          left={TD2 + "px"}
          height={TILE_SIZE * stadium_height + "px"}
        />

        {/* top  */}
        <Line top={0} left={0} width={TILE_SIZE * stadium_width + "px"} />

        {/* bottom  */}
        <Line
          top={TILE_SIZE * stadium_height + "px"}
          left={0}
          width={TILE_SIZE * stadium_width + "px"}
        />

        {/* left  */}
        <Line top={0} left={0} height={TILE_SIZE * stadium_height + "px"} />

        {/* right  */}
        <Line
          top={0}
          left={TILE_SIZE * stadium_width + "px"}
          height={TILE_SIZE * stadium_height + "px"}
        />
      </>
    );
  }
);

export default PitchLines;
