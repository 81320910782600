import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { getRandomNumber } from "enefel/random";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import publicPath from "../../helpers/publicPath";
import { BoxHeader } from "../Interface/Box";
import Col from "../Interface/Col";
import Products from "./Products";
import alea from "seedrandom/lib/alea";
import Product from "./Product";
import Donation from "../Interface/Donation";
import isDev from "../../helpers/devDetect";

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
`;

const Presentation = styled.div`
  display: flex;
  gap: 1rem;
`;

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

const Text = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  gap: 1rem;
  white-space: break-spaces;
`;

const Image = styled.div`
  flex: none;
  width: 30%;
  margin: 0.5rem;
  & > img {
    width: 100%;
    border: 5% solid black;
  }
`;

const PAYPAL_CLIENT = isDev()
  ? "AUJOaIJ1nR6tEWecD9sS_gVTVdVk_NYdAuCLNCyL3JjdwiYzpyii0CBm-3j5KqcYUrUKF_nycUXpcTYA"
  : "AWja90Y2R5DzhF3GThB9QNh0G4j-VJ0BfHiabUKcDzn86AYgkpwPsl-8B9pertHtITapnOY52pbN-5oI";

const Payment = memo(({ onClose }) => {
  const { t } = useTranslation();
  const today = new Date();
  const number = new alea(`${today.getMonth()}-${today.getUTCDate()}`);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hasBuy, setHasBuy] = useState(false);

  const bankerNb = getRandomNumber(1, 12, number);

  const src = publicPath(`tile/shop/banker/${bankerNb}.jpeg`);
  const clientId = PAYPAL_CLIENT;

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
  };

  const handleBuy = () => {
    setHasBuy(true);
  };

  return (
    <Wrapper>
      <Col hasMax={false}>
        <BoxHeader hasMax={false}>
          {t("t:buy some teeth", "__buy some teeth")}
        </BoxHeader>
        <Content>
          <Presentation>
            <Image>
              {<img src={src} alt={t("t:banker", "__banker")} width={"100%"} />}
            </Image>
            {hasBuy && (
              <Center>
                <Text>{t("t:shop thank payment", "__shop thank payment")}</Text>
                <Donation onClick={onClose} />
              </Center>
            )}
            {!hasBuy && !selectedProduct && (
              <Text>{t("t:shop Welcome shop", "__shop Welcome shop")}</Text>
            )}
            {!hasBuy && selectedProduct && (
              <Text>
                <Product product={selectedProduct}>
                  <div>
                    {t("t:EUR", "__EUR")} {selectedProduct.value}
                  </div>
                </Product>
                <div>
                  {t(
                    "t:__shop choose a payment method",
                    "__shop choose a payment method"
                  )}
                </div>
              </Text>
            )}
          </Presentation>
          {!hasBuy && (
            <PayPalScriptProvider
              options={{
                "client-id": clientId,
                currency: "EUR",
              }}
            >
              <Products
                selectedProduct={selectedProduct}
                onSelectProduct={handleSelectProduct}
                onBuy={handleBuy}
              />
            </PayPalScriptProvider>
          )}
        </Content>
      </Col>
    </Wrapper>
  );
});

export default Payment;
