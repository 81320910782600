import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import publicPath from "../../helpers/publicPath";
import { paths } from "../../path";
import A from "../A";

const Image = styled.div`
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 100%;
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    position: relative;
    top: 18px;
  }
`;

const Donation = React.memo(({ onClick = null }) => {
  const { t } = useTranslation();
  const src = publicPath(`interface/shop.png`);

  return (
    <A to={paths.store} big={true} colorContrast={"primary"} onClick={onClick}>
      <Image src={src}>
        <span>{t("t:Shop", "__Shop")}</span>
      </Image>
    </A>
  );
});

export default Donation;
