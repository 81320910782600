import React from "react";
import styled from "styled-components";
import { ReactComponent as MoveIcon } from "./move.svg";
import { TILE_SIZE } from "../../Game/Stadium/Stadium";

const Result = styled.div`
  font-size: 0.6em;
  position: absolute;
  color: white;
  z-index: 1;
`;

const Move = styled.div`
  cursor: pointer;
  display: inline-flex;
  opacity: 0.6;
  transform: ${(props) => props.rotation};
  height: ${(props) => props.size};
  width: ${(props) => props.size};

  .bg {
    fill: ${(props) => (props.gfi ? "red" : "blue")};
  }
`;

function getRotation(direction) {
  let deg;
  switch (direction) {
    case 1:
      deg = -45;
      break;
    case 2:
      deg = 0;
      break;
    case 3:
      deg = 45;
      break;
    case 4:
      deg = -90;
      break;
    case 5:
      deg = 90;
      break;
    case 6:
      deg = -135;
      break;
    case 7:
      deg = 180;
      break;
    case 8:
      deg = 135;
      break;

    default:
      deg = 0;
      break;
  }
  return `rotate(${deg}deg)`;
}

const IconMove = React.memo(
  ({ direction, size = (TILE_SIZE * 2) / 3 + "px", gfi = false, result }) => {
    return (
      <>
        {result != null && <Result>{result}</Result>}
        <Move rotation={getRotation(direction)} gfi={gfi} size={size}>
          <MoveIcon width="100%" height="100%" />
        </Move>
      </>
    );
  }
);

export default IconMove;
