import gql from "graphql-tag";
import { GameRaw, ItemRaw, PlayerRaw, TeamRaw } from "./Fragments";

export const GET_RESUME = gql`
  {
    resume {
      nbPlayers
      nbGames
      nbSeasons
      nbTd
      nbTeams
    }
  }
`;

export const GAME_SET_UP = gql`
  query team($id: ID!) {
    team(id: $id) {
      id
      players {
        id
        x
        y
        status
      }
    }
  }
`;

export const GET_GAME_HISTORY = gql`
  query gameHistory($gameId: ID!, $playerId: ID, $lastHistoryId: ID) {
    gameHistory(
      gameId: $gameId
      playerId: $playerId
      lastHistoryId: $lastHistoryId
    ) {
      id
      type
      info
      createdAt
      player_id
      team_id
    }
  }
`;

export const DEBUG_RESET_GAME = gql`
  mutation debugReset($gameId: ID!) {
    debugReset(gameId: $gameId) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const GET_GAME_ITEMS = gql`
  query getGame($id: ID!, $playerId: ID) {
    game(id: $id, playerId: $playerId) {
      id
      items {
        ...itemRaw
      }
    }
  }
  ${ItemRaw}
`;

export const BUY_GAME_ITEMS = gql`
  mutation buyItem($playerId: ID, $itemId: String, $price: Int) {
    buyItem(playerId: $playerId, itemId: $itemId, price: $price) {
      ...gameRaw
      items {
        id
        icon
        rarity
        price
      }
    }
  }
  ${GameRaw}
`;

export const CREATE_GAME = gql`
  mutation CreateGame($coachId: ID!, $leagueId: ID!, $type: String) {
    createGame(coachId: $coachId, leagueId: $leagueId, type: $type) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const CANCEL_GAME = gql`
  mutation CancelGame($coachId: ID!, $gameId: ID!) {
    cancelGame(coachId: $coachId, gameId: $gameId) {
      id
    }
  }
`;

export const JOIN_GAME = gql`
  mutation JoinGame($coachId: ID!, $gameId: ID!) {
    joinGame(coachId: $coachId, gameId: $gameId) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const GET_GAME_OWNER = gql`
  query getGame($id: ID!) {
    game(id: $id) {
      id
      team1 {
        id
        color1
        color2
      }
    }
  }
`;

export const GET_GAME = gql`
  query getGame($id: ID!, $playerId: ID) {
    game(id: $id, playerId: $playerId) {
      ...gameRaw
      history {
        id
        type
        info
        createdAt
        player_id
        team_id
      }
    }
  }
  ${GameRaw}
`;

export const GET_LEAGUES = gql`
  query leagues($season: Int) {
    leagues(season: $season) {
      id
      name
      season
    }
  }
`;

export const GET_GAMES = gql`
  query games($leagueIds: [ID], $statuses: [Int], $limit: Int) {
    games(leagueIds: $leagueIds, statuses: $statuses, limit: $limit) {
      ball_player_id
      ball_x
      ball_y
      date_end
      date_start
      date_kickoff
      fan1
      fan2
      id
      league_id
      score1
      score2
      stadium_height
      stadium_state
      stadium_type
      stadium_width
      stadium_zone
      status
      isFriendly
      type
      createdAt
      team1 {
        ...teamRaw
        players {
          ...playerRaw
        }
      }
      team2 {
        ...teamRaw
        players {
          ...playerRaw
        }
      }
      league {
        id
        season
        name
      }
    }
  }
  ${TeamRaw}
  ${PlayerRaw}
`;
