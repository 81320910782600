import React, { useEffect, useState } from "react";
import DiceOrderContent from "./DiceOrderContent";

export const DicesWrapper = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
};

const DiceOrder = React.memo(({ order, onChange, attacker, defender }) => {
  const [orders, setOrders] = useState(order);
  useEffect(() => {
    onChange(orders.map((o) => ({ value: o.value, isReroll: o.isReroll })));
  }, [orders, onChange]);

  function handleToogleReroll(index) {
    const newOrders = [...orders];
    newOrders[index].isReroll = !newOrders[index].isReroll;
    setOrders(newOrders);
  }
  return (
    <div style={DicesWrapper}>
      {orders.map((dice, index) => {
        return (
          <DiceOrderContent
            key={dice.value}
            value={dice.value}
            index={index + 1}
            isReroll={dice.isReroll}
            onToogle={() => handleToogleReroll(index)}
            attacker={attacker}
            defender={defender}
          />
        );
      })}
    </div>
  );
});

export default DiceOrder;
