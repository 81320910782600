import React from "react";
import { DugoutPlayerPositions } from "../PlayerPosition/PlayerPosition";

import { TILE_SIZE } from "../Stadium";
import { isColorTooClose } from "enefel/color";
import { useRecoilValue } from "recoil";
import { currentGameState } from "../../atoms/gameState";
import DisplayDugout, { DugoutWrapper } from "../../../Stadium/DisplayDugout";

const Dugout = React.memo(
  ({ team1, team2, players1, players2, isSetUpVisible }) => {
    const game = useRecoilValue(currentGameState);
    if (!game) {
      return null;
    }
    const color1 = game.team1.color1;
    let color2 = game.team2.color1;
    const width = (game.stadium_width - 4) / 2;
    // TODO: avoid to recalculate that everytime
    if (isColorTooClose(`#${color1}`, `#${color2}`)) {
      color2 = game.team2.color2;
    }
    return (
      <>
        <DugoutWrapper size={TILE_SIZE} width={width * TILE_SIZE}>
          <DisplayDugout team={team1} width={width} height={2} side={1} />
          <DugoutPlayerPositions
            color={color1}
            dugoutWidth={width}
            players={players1}
            side={1}
            team={game.team1}
            isSetUpVisible={isSetUpVisible}
          />
        </DugoutWrapper>
        <DugoutWrapper size={TILE_SIZE} width={width * TILE_SIZE}>
          <DisplayDugout team={team2} width={width} height={2} side={2} />
          <DugoutPlayerPositions
            color={color2}
            dugoutWidth={width}
            players={players2}
            side={2}
            team={game.team2}
            isSetUpVisible={isSetUpVisible}
          />
        </DugoutWrapper>
      </>
    );
  }
);
export default Dugout;
