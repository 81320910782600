import { useMutation } from "@apollo/client";
import {
  availableSquaresToPushBack,
  getPlayerOnCell,
  isOnField,
  p,
} from "enefel";
import { modifJumping } from "enefel/move";
import { BB2020Carac, PLAYER_CARAC } from "enefel/player";
import { isAdjacent } from "enefel/position";
import { SKILL_NAMES, hasSkill } from "enefel/skill";
import { hasStatusFall } from "enefel/status";
import React from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { ACTION_JUMP } from "../../../../query/ActionQueries";
import QueryError from "../../../Error/QueryError";
import IconMove from "../../../Icon/action/IconMove";
import { TILE_SIZE } from "../../Stadium/Stadium";
import { gameState } from "../../atoms/gameState";

const ButtonWrapper = styled.div`
  align-items: center;
  background-color: "tranparent";
  cursor: pointer;
  display: flex;
  height: ${(props) => props.size + "px"};
  justify-content: center;
  left: ${(props) => props.x * props.size + "px"};
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  width: ${(props) => props.size + "px"};
  z-index: 2;
`;

const Arrow = React.memo(
  ({ player, players, x, y, gfi, size, onClick, direction, dice }) => {
    let { modif } = modifJumping(player, p(x, y), player.teamId, players);
    let result = null;
    if (modif !== null) {
      result = dice - modif;
      if (result > 6) {
        result = 6;
      }
      if (result < 2) {
        result = 2;
      }
      result = `${result}+`;
    }

    return (
      <ButtonWrapper x={x} y={y} size={size} gfi={gfi} onClick={onClick}>
        <IconMove direction={direction} gfi={gfi} result={result} />
      </ButtonWrapper>
    );
  }
);

const JumpAction = React.memo(
  ({ player, x, y, players, stadium_width, stadium_height, game }) => {
    const [actionJump, { loading, error }] = useMutation(ACTION_JUMP);
    const setGameState = useSetRecoilState(gameState);

    function handleChangeAction(newAction) {
      setGameState((s) => ({
        ...s,
        pushs: null,
        pushsFrenzy: null,
        action: newAction,
        passTarget: null,
      }));
    }

    function canMove(targetX, targetY) {
      return (
        !getPlayerOnCell(targetX, targetY, players) &&
        isOnField(targetX, targetY, stadium_width, stadium_height)
      );
    }

    function jump(targetX, targetY) {
      actionJump({
        variables: { playerId: player.id, x: targetX, y: targetY },
      }).then(() => {
        handleChangeAction(null);
      });
    }
    const playerMA = +player.current_MA;
    const playerGFI = +player.current_gfi;
    const gfi = playerMA <= 1 && playerGFI > 0;
    const hasMove = playerMA + playerGFI > 0;
    let standardJumpSquares = [];
    for (const p of players) {
      if (hasStatusFall(p) && isAdjacent(p, player)) {
        standardJumpSquares = [
          ...standardJumpSquares,
          ...availableSquaresToPushBack(player, p),
        ];
      }
    }

    let dice;
    if (hasMove) {
      dice = parseInt(BB2020Carac(player.current_AG, PLAYER_CARAC.AG), 10);
    }
    function diplayArrows() {
      const arrows = [];
      for (let dy = y - 2; dy <= y + 2; ++dy) {
        for (let dx = x - 2; dx <= x + 2; ++dx) {
          if (
            (dx === x && dy === y) ||
            (Math.abs(dx - x) < 2 && Math.abs(dy - y) < 2)
          ) {
            continue;
          }
          if (
            !standardJumpSquares.find((s) => s.x === dx && s.y === dy) &&
            !hasSkill(player, SKILL_NAMES.LEAP) &&
            !hasSkill(player, SKILL_NAMES.POGO_STICK)
          ) {
            continue;
          }
          if (canMove(dx, dy)) {
            arrows.push(
              <Arrow
                player={player}
                players={players}
                direction={7}
                x={dx}
                y={dy}
                onClick={jump.bind(this, dx, dy)}
                size={TILE_SIZE}
                gfi={gfi}
                dice={dice}
              />
            );
          }
        }
      }
      return arrows;
    }
    return (
      <>
        {error && (
          <>
            <br />
            <br />
            <QueryError error={error} />
          </>
        )}
        {loading
          ? null // <Loading />
          : hasMove && diplayArrows()}
      </>
    );
  }
);

export default JumpAction;
