import gql from "graphql-tag";

export const CREATE_ORDER_PAYPAL = gql`
  mutation createOrderPaypal($price: Int) {
    createOrderPaypal(price: $price) {
      id
      tooth
    }
  }
`;

export const CAPTURE_PAYMENT_PAYPAL = gql`
  mutation capturePaymentPaypal($orderId: String) {
    capturePaymentPaypal(orderId: $orderId) {
      id
      tooth
    }
  }
`;
