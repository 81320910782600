import React from "react";
import { useTranslation } from "react-i18next";
import { getRaceFromCareer } from "enefel/race";
import styled from "styled-components";
import Group from "./Group";

const Wrapper = styled.div`
  display: inline-flex;
  gap: 0.3em;
  align-items: center;
`;

const Name = styled.div`
  white-space: nowrap;
`;

const Race = React.memo(({ careerId, raceId, withGroup = true }) => {
  const { t } = useTranslation("race");
  const race = raceId ? raceId : getRaceFromCareer(careerId);

  return (
    <Wrapper>
      {withGroup && <Group race={race} />}
      <Name>{t("race:" + race, "__" + race)}</Name>
    </Wrapper>
  );
});

export default Race;
