import React from "react";

import { AvatarImage, AVATAR_SIZE, AVATAR_SIZE_BIG } from "../../Store/Item";
import CustomTooltip from "../../Interface/Tooltip";

const AvatarImg = React.memo(
  ({
    alt,
    src,
    isUsed = false,
    withTooltip = true,
    w = null,
    h = null,
    border = 3,
  }) => {
    return (
      <CustomTooltip
        backgroundColor={"black"}
        title={
          withTooltip && (
            <img
              src={src}
              alt={alt}
              width={`${AVATAR_SIZE_BIG}px`}
              height={`${AVATAR_SIZE_BIG}px`}
              loading="lazy"
            />
          )
        }
      >
        <AvatarImage
          isUsed={isUsed}
          width={`${w || AVATAR_SIZE}`}
          height={`${h || AVATAR_SIZE}`}
          border={border}
        >
          <img
            src={src}
            alt={alt}
            width={`${w || AVATAR_SIZE}px`}
            height={`${h || AVATAR_SIZE}px`}
            loading="lazy"
          />
        </AvatarImage>
      </CustomTooltip>
    );
  }
);

export default AvatarImg;
