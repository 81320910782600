import styled from "styled-components";
import size from "../../styles/breakpoint";

const Nav = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  text-align: left;

  & > * {
    margin-left: 1rem;
  }

  @media screen and (max-width: ${size.md}) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 1em 0;

    a {
      margin: 0.6em 0;
    }
  }
`;
const NavLogin = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex: none;

  & > * {
    margin-left: 1rem;
  }

  @media screen and (max-width: ${size.md}) {
    flex-direction: column;
    align-items: center;

    & > * {
      margin-left: 0;
      margin: 0.3em 0;
    }
  }
`;

export { Nav, NavLogin };
