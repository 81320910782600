import { focusState } from "../../../atoms/mouseState";
import { TILE_SIZE } from "../../Stadium";
import { useRecoilValue } from "recoil";
import palette from "../../../../../styles/palette";
import React from "react";
import styled from "styled-components";

const FocusedTileStyle = styled.div`
  position: absolute;
  width: ${(props) => props.size + "px"};
  height: ${(props) => props.size + "px"};
  top: ${(props) => props.y * props.size + "px"};
  left: ${(props) => props.x * props.size + "px"};
  border: 2px ridge ${palette.secondary.main};
  background-color: ${palette.secondary.light};
  opacity: 0.5;
`;
const FocusTile = React.memo(() => {
  const focus = useRecoilValue(focusState);
  return (
    focus &&
    focus.x != null &&
    focus.y != null && (
      <FocusedTileStyle size={TILE_SIZE} x={focus.x} y={focus.y} />
    )
  );
});

export default FocusTile;
