import { PLAYER_STATUS, hasStatusFall, hasStatusInjury } from "enefel/status";
import React from "react";
import styled from "styled-components";
import publicPath from "../../../../../helpers/publicPath";

const Wrapper = styled.div`
  background-image: ${`url("${publicPath(`tile/weapon/chainsaw.gif`)}")`};
  background-size: cover;
  top: ${(props) => (props.isOnTop ? "0" : null)};
  bottom: ${(props) => (props.isOnTop ? null : "25%")};
  height: 10px;
  left: 20%;
  position: absolute;
  transform: scale(0.9);
  width: 27px;
  z-index: 1;
`;

const ChainsawWeapon = React.memo(({ player }) => {
  const onTop =
    (hasStatusFall(player) || hasStatusInjury(player)) &&
    PLAYER_STATUS.RESERVE !== player.status;

  return <Wrapper isOnTop={onTop} />;
});

export default ChainsawWeapon;
