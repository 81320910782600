import React from "react";
import Header from "./Header/Header";
import styled from "styled-components/macro";
import palette from "../styles/palette";
import publicPath from "../helpers/publicPath";
import Footer from "./Footer/Footer";
import bp from "../styles/breakpoint";
import BottomNav from "./Header/BottomNav";
import SmallFooter from "./Footer/SmallFooter";
import { paths } from "../path";
import { useLocation } from "react-router-dom";
const urlGrass = publicPath("interface/bg.jpg");

const HeaderBox = styled.header`
  align-items: center;
  background: linear-gradient(${palette.primary.main}, ${palette.primary.dark});
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 0 1rem;

  @media screen and (max-width: ${bp.md}) {
    justify-content: center;
  }
`;

const MainBox = styled.main`
  background-color: ${palette.background.default};
  border-color: ${palette.background.default};
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0.8) 70%,
      rgba(255, 255, 255, 0) 100%
    ),
    url(${urlGrass});
  border-radius: 4px;
  box-shadow: -1px 6px 33px -10px rgba(0, 0, 0, 0.86);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "none")};
  padding: 1rem;
  position: relative;
  width: 95%;

  @media screen and (max-width: ${bp.md}) {
    padding-bottom: 60px;
  }
`;

const FooterBox = styled.footer`
  display: block;
  margin-top: 3em;

  @media screen and (max-width: ${bp.md}) {
    display: none;
  }
`;

const FooterBoxSmall = styled.footer`
  display: none;
  margin: 1em 0 0.5em;

  @media screen and (max-width: ${bp.md}) {
    display: block;
  }
`;

const Layout = React.memo(({ children, hasMigration }) => {
  const location = useLocation();
  let hasMaxWidth = ![paths.gameShow, paths.careers].some((path) =>
    location.pathname.includes(path)
  );

  return (
    <>
      <HeaderBox>
        <Header hasMigration={hasMigration} />
      </HeaderBox>
      <MainBox maxWidth={hasMaxWidth ? 1284 : null}>
        {children}
        <FooterBox>
          <Footer />
        </FooterBox>
        <FooterBoxSmall>
          <SmallFooter />
        </FooterBoxSmall>
      </MainBox>
      <BottomNav />
    </>
  );
});

export default Layout;
