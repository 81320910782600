import React from "react";
import { useState } from "react";
import styled from "styled-components/macro";
import palette from "../../styles/palette";
import Menu from "./Menu";
import size from "../../styles/breakpoint";
import Col from "../Interface/Col";
import Box from "../Interface/Box";
import { useTranslation } from "react-i18next";

const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  &.active {
    position: fixed;

    .menu {
      transform: none;
      overflow: auto;
    }

    .anim {
      background-color: green;

      &:nth-child(1) {
        -webkit-animation: inT 0.8s forwards;
        animation: inT 0.8s forwards;
      }
      &:nth-child(2) {
        -webkit-animation: inM 0.8s forwards;
        animation: inM 0.8s forwards;
      }
      &:nth-child(3) {
        -webkit-animation: inBtm 0.8s forwards;
        animation: inBtm 0.8s forwards;
      }
    }
    @-webkit-keyframes inM {
      50% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(45deg);
      }
    }
    @keyframes inM {
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(45deg);
      }
    }

    @-webkit-keyframes outM {
      50% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(45deg);
      }
    }
    @keyframes outM {
      50% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(45deg);
      }
    }

    @-webkit-keyframes inT {
      0% {
        -webkit-transform: translateY(0px) rotate(0deg);
      }
      50% {
        -webkit-transform: translateY(9px) rotate(0deg);
      }
      100% {
        -webkit-transform: translateY(9px) rotate(135deg);
      }
    }
    @keyframes inT {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(9px) rotate(0deg);
      }
      100% {
        transform: translateY(9px) rotate(135deg);
      }
    }

    @-webkit-keyframes outT {
      0% {
        -webkit-transform: translateY(0px) rotate(0deg);
      }
      50% {
        -webkit-transform: translateY(9px) rotate(0deg);
      }
      100% {
        -webkit-transform: translateY(9px) rotate(135deg);
      }
    }
    @keyframes outT {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(9px) rotate(0deg);
      }
      100% {
        transform: translateY(9px) rotate(135deg);
      }
    }

    @-webkit-keyframes inBtm {
      0% {
        -webkit-transform: translateY(0px) rotate(0deg);
      }
      50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
      }
      100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
      }
    }
    @keyframes inBtm {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(-9px) rotate(0deg);
      }
      100% {
        transform: translateY(-9px) rotate(135deg);
      }
    }

    @-webkit-keyframes outBtm {
      0% {
        -webkit-transform: translateY(0px) rotate(0deg);
      }
      50% {
        -webkit-transform: translateY(-9px) rotate(0deg);
      }
      100% {
        -webkit-transform: translateY(-9px) rotate(135deg);
      }
    }
    @keyframes outBtm {
      0% {
        transform: translateY(0px) rotate(0deg);
      }
      50% {
        transform: translateY(-9px) rotate(0deg);
      }
      100% {
        transform: translateY(-9px) rotate(135deg);
      }
    }
  }

  @media screen and (min-width: ${size.md}) {
    display: none;
  }
`;

const ButtonToogle = styled.div`
  cursor: pointer;
  display: block;
  height: 25px;
  left: 1.7rem;
  position: relative;
  top: 1.7rem;
  width: 30px;
  z-index: 1001;

  span {
    background-color: #fff;
    border-radius: 2px;
    content: "";
    display: block;
    height: 4px;
    transition: background-color 0.8s ease;
    width: 100%;

    &:nth-child(1) {
      -webkit-animation: outT 0.8s backwards;
      animation: outT 0.8s backwards;
      -webkit-animation-direction: reverse;
      animation-direction: reverse;
    }
    &:nth-child(2) {
      margin: 5px 0;
      -webkit-animation: outM 0.8s backwards;
      animation: outM 0.8s backwards;
      -webkit-animation-direction: reverse;
      animation-direction: reverse;
    }
    &:nth-child(3) {
      -webkit-animation: outBtm 0.8s backwards;
      animation: outBtm 0.8s backwards;
      -webkit-animation-direction: reverse;
      animation-direction: reverse;
    }
  }
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MenuToggle = styled.div`
  display: block;
`;

const MenuWrapper = styled.ul`
  background: ${palette.primary.contrastText};
  height: 100vh;
  list-style-type: none;
  margin: 0;
  padding: 5em 1em 0 1em;
  position: absolute;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  width: 300px;
  top: 0;
  left: 0;
  background-color: #eae8cf;
  border: 2px solid ${palette.secondary.dark};
  border-radius: 0 27px 27px 0;

  li {
    padding: 10px 0;
    font-size: 22px;
  }

  a {
    text-decoration: none;
    /* color: #232323; */
    transition: color 0.3s ease;

    &:hover {
      /* color: tomato; */
    }
  }
`;

const BackGround = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scaleY(${(props) => (props.isActive ? 1 : 0)});
`;

const Hamburger = React.memo(({ user }) => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive((isActive) => !isActive);
  };

  return (
    <>
      <Nav role="navigation" className={isActive ? "active" : null}>
        <MenuToggle>
          <ButtonToogle
            onClick={toggleClass}
            tabIndex="0"
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                toggleClass();
              }
            }}
          >
            <span className="anim"></span>
            <span className="anim"></span>
            <span className="anim"></span>
          </ButtonToogle>

          <MenuWrapper className="menu">
            <Col>
              <Box header={t("t:menu", "__menu")}>
                <Centered>
                  <Menu
                    user={user}
                    onClick={toggleClass}
                    isActive={isActive}
                    isHamb={true}
                  ></Menu>
                </Centered>
              </Box>
            </Col>
          </MenuWrapper>
        </MenuToggle>
        <BackGround isActive={isActive} onClick={() => setActive(false)} />
      </Nav>
    </>
  );
});
export default Hamburger;
