import PlayerLink from "../Player/PlayerLink";
import styled from "styled-components";
import Col from "../Interface/Col";
import Box from "../Interface/Box";
import palette from "../../styles/palette";
import { PLAYER_STAT } from "enefel";

const WIDTH = 300;
const WIDTH_FIRST = WIDTH / 3;
const WIDTH_SECOND = WIDTH_FIRST / 1.1;

const Wrapper = styled.div`
  position: relative;
  width: ${WIDTH}px;

  a {
    color: ${palette.secondary.contrastText};
  }
`;

const List = styled.ul`
  padding: 0;
  margin: 11rem 0 0rem;
`;

const ListElement = styled.li`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Score = styled.div`
  font-size: ${(props) => (props.size ? props.size : "1rem")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const First = styled.div`
  z-index: 3;
  position: absolute;
  top: 2rem;
  width: ${WIDTH_FIRST}px;
  height: ${WIDTH_FIRST}px;
  left: calc(50% - (${WIDTH_FIRST}px / 2));
  background-color: grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) =>
    props.isNegative ? palette.error.main : palette.primary.main};
  border: 1px solid white;

  filter: drop-shadow(1px 2px 3px black);
  box-shadow: 0px 0px 0px 3px
      ${(props) =>
        props.isNegative ? palette.error.main : palette.primary.main},
    rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
`;

const Second = styled.div`
  z-index: 2;
  position: absolute;
  top: 5rem;
  width: ${WIDTH_SECOND}px;
  height: ${WIDTH_SECOND}px;
  left: calc(25% - (${WIDTH_SECOND}px / 2));
  background-color: grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) =>
    props.isNegative ? palette.error.main : palette.primary.main};
  border: 1px solid white;
  box-shadow: 0px 0px 0px 3px
      ${(props) =>
        props.isNegative ? palette.error.main : palette.primary.main},
    rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

  filter: saturate(0.7) drop-shadow(1px 2px 3px black);
`;

const Third = styled.div`
  z-index: 1;
  position: absolute;
  top: 5rem;
  width: ${WIDTH_SECOND}px;
  height: ${WIDTH_SECOND}px;
  left: calc(75% - (${WIDTH_SECOND}px / 2));
  background-color: grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) =>
    props.isNegative ? palette.error.main : palette.primary.main};
  border: 1px solid white;
  box-shadow: 0px 0px 0px 3px
      ${(props) =>
        props.isNegative ? palette.error.main : palette.primary.main},
    rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  filter: saturate(0.5) drop-shadow(1px 2px 3px black);
`;

const Rank = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & > *:first-child {
    font-size: 0.8rem;
  }
`;

const getPlayersStat = (players, field, isAverage) => {
  return players
    .map((player) => {
      const stats = player.stats;
      let value = stats
        ? stats.reduce((c, p) => {
            return c + p[field];
          }, 0)
        : 0;
      if (isAverage) {
        const gp = stats
          ? stats.reduce((c, p) => {
              return c + p[PLAYER_STAT.GAME_PLAYED];
            }, 0)
          : 0;
        value = gp === 0 ? 0 : (value = value / gp);
      }
      return { player, value: Math.round(value) };
    })
    .sort((a, b) => (a.value < b.value ? 1 : -1));
};

const Ranking = ({
  players,
  field,
  title,
  isNegative = false,
  isAverage = false,
}) => {
  const ranking = getPlayersStat(players, field, isAverage);
  const quantity = 10;
  const playersToDisplay = ranking.slice(0, quantity);

  return (
    <Wrapper>
      <Col>
        <Box header={title}>
          {playersToDisplay.length > 0 && (
            <First isNegative={isNegative}>
              <PlayerLink size={"45px"} player={playersToDisplay[0].player} />{" "}
              <Score size={"1.2"} bold={true}>
                {playersToDisplay[0].value}
              </Score>
            </First>
          )}
          {playersToDisplay.length > 1 && (
            <Second isNegative={isNegative}>
              <PlayerLink size={"40px"} player={playersToDisplay[1].player} />{" "}
              <Score>{playersToDisplay[1].value}</Score>
            </Second>
          )}
          {playersToDisplay.length > 2 && (
            <Third isNegative={isNegative}>
              <PlayerLink size={"30px"} player={playersToDisplay[2].player} />{" "}
              <Score>{playersToDisplay[2].value}</Score>
            </Third>
          )}

          <List>
            {playersToDisplay
              .filter((_, i) => i >= 3)
              .map((p, i) => (
                <ListElement key={p.player.id}>
                  <Rank>
                    <span>{i + 4} - </span>
                    <PlayerLink player={p.player} horizontal={true} />
                  </Rank>
                  <Score>{p.value}</Score>
                </ListElement>
              ))}
          </List>
        </Box>
      </Col>
    </Wrapper>
  );
};

export default Ranking;
