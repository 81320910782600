import React from "react";
import styled from "styled-components";
import { ReactComponent as Ball } from "./ball.svg";
import { TILE_SIZE } from "../Game/Stadium/Stadium";

const BallWrapper = styled.div`
  display: inline-flex;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
`;
export const IconBall = React.memo(({ size = TILE_SIZE + "px" }) => {
  return (
    <BallWrapper size={size}>
      <Ball
        width="100%"
        height="100%"
        style={{
          filter: "drop-shadow(0px 0px 2px yellow)",
        }}
      />
    </BallWrapper>
  );
});
