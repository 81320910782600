import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../../../../Interface/checkbox";
import { LabelStyle } from "../../../../styles";
import CustomTooltip from "../../../../Interface/Tooltip";

const BlockOptions = React.memo(
  ({
    blitz,
    follow,
    followFall,
    hasFend,
    hasFoulAppearance,
    hasFrenzy,
    hasHorns,
    isBlitzDisabled,
    onChangeBlitz,
    onChangeFollow,
    onChangeRerollFA,
    rerollFA,
  }) => {
    const { t } = useTranslation("skill");
    const [followAll, setFollowAll] = useState(follow && followFall);

    useEffect(() => {
      setFollowAll(follow || followFall);
    }, [follow, followFall]);

    function handleFollowAll() {
      const newValue = !followAll;
      onChangeFollow("stand", newValue);
      onChangeFollow("fall", newValue);
      onChangeFollow("out", newValue);
      setFollowAll(newValue);
    }
    return (
      <>
        <div>
          <LabelStyle>
            <span>{t("t:Blitz", "__Blitz")}</span>
            <CustomTooltip
              title={
                isBlitzDisabled
                  ? t("t:Blitz player has move", "__Blitz player has move")
                  : null
              }
            >
              <span>
                <Checkbox
                  isOn={blitz}
                  handleToggle={onChangeBlitz}
                  disabled={isBlitzDisabled}
                />
              </span>
            </CustomTooltip>
          </LabelStyle>{" "}
          {blitz && hasHorns ? `(${t("skill:horns", "__horns")})` : null}
        </div>
        <div>
          {!hasFend && hasFrenzy && (
            <div>{t("t:always follow frenzy", "__always follow frenzy")}</div>
          )}
          {hasFend && (
            <div>{t("t:Cant follow fend", "__Cant follow fend")}</div>
          )}
          {/* Fend = parade : Annule la possibilité pour son adversaire de le poursuivre  */}
          {!hasFrenzy && !hasFend && (
            <>
              <LabelStyle>
                <span>{t("t:Follow if stand", "__Follow if stand")}</span>
                <Checkbox
                  isOn={follow}
                  handleToggle={() => onChangeFollow("stand")}
                />
              </LabelStyle>
              <LabelStyle>
                <span>{t("t:Follow if fall", "__Follow if fall")}</span>
                <Checkbox
                  isOn={followFall}
                  handleToggle={() => onChangeFollow("fall")}
                />
              </LabelStyle>
              <LabelStyle>
                <span>{t("t:Follow all", "__Follow all")}</span>
                <Checkbox
                  onColor={"#538fbe"}
                  isOn={followAll}
                  handleToggle={handleFollowAll}
                />
              </LabelStyle>
            </>
          )}
          {hasFoulAppearance && (
            <LabelStyle>
              <span>
                {t(
                  "t:reroll block FoulAppearance",
                  "__reroll block FoulAppearance"
                )}
              </span>
              <Checkbox
                onColor={"#538fbe"}
                isOn={rerollFA}
                handleToggle={() => onChangeRerollFA(!rerollFA)}
              />
            </LabelStyle>
          )}
        </div>
      </>
    );
  }
);

export default BlockOptions;
