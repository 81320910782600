import { getPlayerDice } from "enefel/dice";
import React from "react";
import styled from "styled-components";
import { DiceBlock } from "./dice/DiceBlock";

const Dice = styled.div`
  background-color: #eaeaea;
  opacity: ${(props) => (props.chosen ? "1" : "0.4")};
  filter: ${(props) =>
    props.chosen ? (props.isAttacker ? "none" : "invert(1)") : "none"};
  border-radius: 20%;
  border: max(1px, 0.1em) outset #f5f5f5;
  display: inline-flex;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin: 1px;
`;

const IconDiceBlock = React.memo(
  ({ number, size = 24, chosen = true, isAttacker = true }) => {
    return (
      <Dice chosen={chosen} isAttacker={isAttacker} width={size} height={size}>
        <DiceBlock dice={getPlayerDice(null)} value={+number} />
      </Dice>
    );
  }
);
export default IconDiceBlock;
