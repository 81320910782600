import React, { memo } from "react";
import styled from "styled-components/macro";

import { getCareerKeyFromPlayer, getPlayerAvatar } from "enefel/race";
import { getSkillDisplayedWithCarrer } from "enefel/skill";
import { useTranslation } from "react-i18next";
import palette from "../../styles/palette";
import DateFrom from "../Date/DateFrom";
import AvatarShow from "../Player/Avatar/AvatarShow";
import PlayerStats from "../Player/PlayerStats";
import Career from "../Race/Career";
import Race from "../Race/Race";
import Skills from "../Skill/Skills";
import { H2 } from "../Title";
import PlayerLevel from "../Player/PlayerLevel";

const PlayerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const PlayerContentWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem 0 0;
`;
const TopRight = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const TooltipPlayer = memo(({ player }) => {
  const { t } = useTranslation();
  return (
    <PlayerWrapper>
      <TopRight>
        <PlayerLevel player={player} />
      </TopRight>
      <div>
        <H2 color={palette.primary.contrastText}>{player.name}</H2>
      </div>
      <PlayerContentWrapper>
        {getPlayerAvatar(player) && (
          <div>
            <AvatarShow player={player} avatarFile={getPlayerAvatar(player)} />
          </div>
        )}
        <div>
          <Race careerId={getCareerKeyFromPlayer(player)} />
          <br />
          <Career
            careerId={getCareerKeyFromPlayer(player)}
            skills={
              player?.skills?.filter((s) =>
                getSkillDisplayedWithCarrer().includes(s.skill_id)
              ) || []
            }
          />
          <br />
          <br />
          <div>
            <strong>{player.experience}</strong> {t("t:SPP", "__SPP")}
          </div>
          <br />
          <PlayerStats
            player={player}
            forPlayer={false}
            gfi={true}
            colorCarac={"white"}
          />
          <Skills
            playerId={player.id}
            skills={player.skills}
            careerId={getCareerKeyFromPlayer(player)}
          />
        </div>
      </PlayerContentWrapper>
      <div>
        <DateFrom title={t("t:Connected", "__Connected")}>
          {player.connectedAt}
        </DateFrom>
      </div>
    </PlayerWrapper>
  );
});

export default TooltipPlayer;
