import gql from "graphql-tag";
import { GameRaw } from "./Fragments";

export const ACTION_APOTHECARY = gql`
  mutation actionApothecary($playerId: ID!, $targetId: ID!) {
    actionApothecary(playerId: $playerId, targetId: $targetId) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_SPY = gql`
  mutation actionSpy($playerId: ID!) {
    actionSpy(playerId: $playerId) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_END_TURN = gql`
  mutation actionEndTurn($playerId: ID!) {
    actionEndTurn(playerId: $playerId) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_FOUL = gql`
  mutation actionFoul(
    $playerIdFrom: ID!
    $playerIdTo: ID!
    $withChainsaw: Boolean
  ) {
    actionFoul(
      playerIdFrom: $playerIdFrom
      playerIdTo: $playerIdTo
      withChainsaw: $withChainsaw
    ) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_VOMIT = gql`
  mutation actionVomit($playerIdFrom: ID!, $playerIdTo: ID!) {
    actionVomit(playerIdFrom: $playerIdFrom, playerIdTo: $playerIdTo) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_HYPNOTIC_GAZE = gql`
  mutation actionHypnoticGaze($playerIdFrom: ID!, $playerIdTo: ID!) {
    actionHypnoticGaze(playerIdFrom: $playerIdFrom, playerIdTo: $playerIdTo) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_BLOCK = gql`
  mutation actionBlock(
    $playerIdFrom: ID!
    $playerIdTo: ID!
    $dices: Int
    $order: [DiceBlock]
    $pushs: [Point]
    $follow: Boolean
    $followFall: Boolean
    $blitz: Boolean
    $pushsFrenzy: [Point]
    $orderFrenzy: [DiceBlock]
  ) {
    actionBlock(
      playerIdFrom: $playerIdFrom
      playerIdTo: $playerIdTo
      dices: $dices
      order: $order
      pushs: $pushs
      follow: $follow
      followFall: $followFall
      blitz: $blitz
      pushsFrenzy: $pushsFrenzy
      orderFrenzy: $orderFrenzy
    ) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_CHAINSAW = gql`
  mutation actionChainsaw(
    $playerIdFrom: ID!
    $playerIdTo: ID!
    $blitz: Boolean
  ) {
    actionChainsaw(
      playerIdFrom: $playerIdFrom
      playerIdTo: $playerIdTo
      blitz: $blitz
    ) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_FACEUP = gql`
  mutation actionFaceUp($playerId: ID!) {
    actionFaceUp(playerId: $playerId) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;
export const ACTION_STANDUP = gql`
  mutation actionStandup($playerId: ID!) {
    actionStandup(playerId: $playerId) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_MOVE = gql`
  mutation actionMove($playerId: ID!, $x: Int, $y: Int, $fumble: Boolean) {
    actionMove(playerId: $playerId, x: $x, y: $y, fumble: $fumble) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;
export const ACTION_JUMP = gql`
  mutation actionJump($playerId: ID!, $x: Int, $y: Int) {
    actionJump(playerId: $playerId, x: $x, y: $y) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_TRANSMISSION = gql`
  mutation actionTransmission($playerIdFrom: ID!, $playerIdTo: ID!) {
    actionTransmission(playerIdFrom: $playerIdFrom, playerIdTo: $playerIdTo) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_PASS = gql`
  mutation actionPass(
    $playerIdFrom: ID!
    $x: Int
    $y: Int
    $rerollOnlyFumble: Boolean
  ) {
    actionPass(
      playerIdFrom: $playerIdFrom
      x: $x
      y: $y
      rerollOnlyFumble: $rerollOnlyFumble
    ) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;

export const ACTION_THROW_TEAM_MATE = gql`
  mutation actionThrowTeamMate(
    $playerIdFrom: ID!
    $playerIdTarget: ID!
    $x: Int
    $y: Int
    $rerollOnlyFumble: Boolean
  ) {
    actionThrowTeamMate(
      playerIdFrom: $playerIdFrom
      playerIdTarget: $playerIdTarget
      x: $x
      y: $y
      rerollOnlyFumble: $rerollOnlyFumble
    ) {
      ...gameRaw
    }
  }
  ${GameRaw}
`;
