import React from "react";
import styled from "styled-components";
import { PLAYER_STATUS } from "enefel/status";

import KO from "../Tooltip/KO";
import SendOff from "../Tooltip/SendOff";
import Casuality from "../Tooltip/Casuality";
import CustomTooltip from "../../../../Interface/Tooltip";
import BoneHead from "../Tooltip/BoneHead";
import { TILE_SIZE } from "../../Stadium";

const WrapperCasuality = styled.div`
  height: 10px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 10px;
  z-index: 1;

  & > *:first-child {
    position: absolute;
    background: red;
    height: 100%;
    width: 50%;
    left: 25%;
    top: 0px;
  }
  & > *:last-child {
    position: absolute;
    top: 25%;
    left: 0px;
    background: red;
    width: 100%;
    height: 50%;
  }
`;

const WrapperSendOff = styled.div`
  background-color: red;
  border-radius: 2px;
  border: 1px solid black;
  height: 10px;
  left: 1px;
  position: absolute;
  top: 6px;
  width: 8px;
  z-index: 1;
`;

const WrapperKO = styled.div`
  color: red;
  font-size: 0.6em;
  font-weight: bold;
  height: 10px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 10px;
  z-index: 1;
`;

const WrapperBoneHead = styled.div`
  color: yellow;
  font-size: 0.8em;
  font-weight: bold;
  height: 10px;
  left: 0px;
  position: absolute;
  text-align: center;
  top: 0;
  width: ${(props) => props.width}px;
  z-index: 1;

  span:nth-child(1) {
    animation: blinker 2s infinite 0s;
  }
  span:nth-child(2) {
    animation: blinker 2s infinite 0.5s;
  }
  span:nth-child(3) {
    animation: blinker 2s infinite 1s;
  }

  @keyframes blinker {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }
`;

const CurrentPlayer = styled.div`
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 40%;
  border-radius: 50%;
  border: 2px solid #de1616;
  z-index: -1;
`;

const SendOffMarker = React.memo(() => {
  return (
    <CustomTooltip placement="top" title={<SendOff />}>
      <WrapperSendOff />
    </CustomTooltip>
  );
});

const KOMarker = React.memo(() => {
  return (
    <CustomTooltip placement="top" title={<KO />}>
      <WrapperKO>KO</WrapperKO>
    </CustomTooltip>
  );
});

const CasualityMarker = React.memo(() => {
  return (
    <CustomTooltip placement="top" title={<Casuality />}>
      <WrapperCasuality>
        <div></div>
        <div></div>
      </WrapperCasuality>
    </CustomTooltip>
  );
});

const BoneHeadMarker = React.memo(() => {
  return (
    <CustomTooltip placement="top" title={<BoneHead />}>
      <WrapperBoneHead width={TILE_SIZE}>
        <span>*</span>
        <span>*</span>
        <span>*</span>
      </WrapperBoneHead>
    </CustomTooltip>
  );
});

const PlayerMarker = React.memo(({ status, state, isCurrent }) => {
  return (
    <>
      {isCurrent && <CurrentPlayer className="current_player" />}
      {status === PLAYER_STATUS.SENDOFF && <SendOffMarker />}
      {status === PLAYER_STATUS.KO && <KOMarker />}
      {status === PLAYER_STATUS.CASUALTY && <CasualityMarker />}
      {status === PLAYER_STATUS.BONE_HEAD && <BoneHeadMarker />}
    </>
  );
});

export default PlayerMarker;
