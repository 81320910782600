import palette from "../../styles/palette";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const CustomTooltip = styled(
  ({ className, backgroundColor, disableInteractive = true, ...props }) => (
    <Tooltip
      disableInteractive={disableInteractive}
      {...props}
      classes={{ popper: className }}
      backgroundcolor={backgroundColor}
    />
  )
)(({ theme, backgroundColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor ? backgroundColor : null,
    backgroundImage: backgroundColor
      ? null
      : `radial-gradient(
      circle,
      ${palette.primary.main} 0%,
      ${palette.primary.dark} 100%
    )`,
    boxShadow: theme.shadows[1],
    color: "white",
    padding: "0.3rem",
  },
}));

export default CustomTooltip;
