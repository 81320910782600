import { hasStatusFall, hasStatusInjury } from "enefel/status";
import React from "react";
import styled from "styled-components";
import publicPath from "../../../../../helpers/publicPath";

const Wrapper = styled.div`
  background-image: ${`url("${publicPath(`tile/weapon/tentacle.gif`)}")`};
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  height: 50%;
  width: 100%;
`;

const Tentacle = React.memo(({ player }) => {
  if (!player) {
    return null;
  }
  const isFall = hasStatusFall(player) || hasStatusInjury(player);
  if (isFall) {
    return null;
  }
  return <Wrapper />;
});

export default Tentacle;
