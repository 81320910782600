import gql from "graphql-tag";

export const ItemRaw = gql`
  fragment itemRaw on Item {
    equiped
    icon
    id
    maxQuantity
    price
    rarity
    state
    type
    x
    y
  }
`;

export const PlayerItemRaw = gql`
  fragment playerItemRaw on PlayerItem {
    createdAt
    equiped
    id
    info
    item_id
    player_id
    quantity
    state
    updatedAt
    item {
      ...itemRaw
    }
  }
  ${ItemRaw}
`;

export const UserBadgeRaw = gql`
  fragment userBadgeRaw on UserBadge {
    user_id
    badge_id
    quantity
    visible
  }
`;

export const CardsRaw = gql`
  fragment cardsRaw on Card {
    id
    number
    type
    serie
    rarity
    data
    totalSerie
  }
`;

export const UserCardsRaw = gql`
  fragment userCardsRaw on UserCard {
    userId
    cardId
    quantity
    rarity
    card {
      ...cardsRaw
    }
  }
  ${CardsRaw}
`;

export const UserItemRaw = gql`
  fragment userItemRaw on UserItem {
    id
    info
    item_id
    quantity
    state
  }
`;

export const StatRaw = gql`
  fragment statRaw on Stat {
    player_id
    game_id
    team_id
    aF
    aS
    b
    b1
    b2
    b3
    baa
    bad
    baF
    baS
    bm2
    bm3
    br1
    br2
    br3
    br4
    br5
    cbh
    cd
    cli
    crbh
    crd
    crli
    crsh
    crsi
    csh
    csi
    dF
    dS
    exp
    faa
    fad
    fF
    fr
    fS
    fso
    fu
    g
    gd
    gfi1F
    gfi1S
    gl
    gp
    gv
    hoF
    hoS
    ic
    iF
    ikd
    iko
    iS
    jf
    js
    m
    pc
    pF
    pfu
    pi
    pnc
    pp
    ppc
    r
    rb
    rb1
    rb2
    rb3
    rbaF
    rbaS
    rbm2
    rbm3
    rbr1
    rbr2
    rbr3
    rbr4
    rbr5
    ric
    rikd
    riko
    su
    t
    tbF
    tbrF
    tbrS
    tbS
    td
  }
`;

export const PlayerRaw = gql`
  fragment playerRaw on Player {
    avatar
    avatarDefault
    AG
    aging
    AV
    current_AG
    current_AV
    current_gfi
    current_MA
    current_ST
    createdAt
    connectedAt
    date_next_action
    can_auto_standup
    experience
    experienceCoach
    gameId
    icon_id
    id
    # use in enefel/can...
    isCoach
    MA
    moral
    name
    order
    po
    pop
    n
    tempCarac
    race_id
    skills {
      skill_id
      date_next
      info
      active
      duration
      data
    }
    coachSkills {
      available
      date
      info
      max
      skill_id
    }
    ST
    status
    state
    teamId
    updatedAt
    ni
    reroll_counter
    can_reroll
    can_reroll_no_to
    can_reroll_rebound
    has_action
    can_jump
    pc
    isRookie
    quote
    speak
  }
`;

export const TeamRaw = gql`
  fragment teamRaw on Team {
    canChampionship
    coach_id
    color1
    color2
    createdAt
    date_next_blitz
    date_next_foul
    date_next_pass
    date_next_reroll
    date_next_transmission
    description
    discordId
    elo
    game_id
    id
    isOpen
    league_id
    name
    po
    pop
    rerollCoach
    stadium_name
    state
    stadium_zone_s
    stadium_state_s
    stadium_type_s
    stadium_zone_m
    stadium_state_m
    stadium_type_m
    stadium_zone_l
    stadium_state_l
    stadium_type_l
  }
`;

export const GameRaw = gql`
  fragment gameRaw on Game {
    ball_player_id
    ball_x
    ball_y
    date_end
    date_start
    date_kickoff
    fan1
    fan2
    id
    inducements
    league_id
    score1
    score2
    stadium_height
    stadium_state
    stadium_type
    stadium_width
    stadium_zone
    status
    isFriendly
    type
    createdAt
    reroll1
    reroll2
    elo1
    elo2
    meteo
    last_td
    hasHalfTime
    storeSize
    team1 {
      ...teamRaw
      players {
        ...playerRaw
        x
        y
      }
    }
    team2 {
      ...teamRaw
      players {
        ...playerRaw
        x
        y
      }
    }
  }
  ${PlayerRaw}
  ${TeamRaw}
`;
