import React from "react";
import { TILE_SIZE } from "../../Stadium";
import styled from "styled-components";
import { coordinatesConvertorX, coordinatesConvertorY } from "enefel/pitch";
import palette from "../../../../../styles/palette";
import { useRecoilValue } from "recoil";
import { focusState } from "../../../atoms/mouseState";
import isDev from "../../../../../helpers/devDetect";

const CoordinateStyle = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.isFocused ? palette.secondary.dark : "transparent"};
  color: white;
  display: flex;
  height: ${(props) => props.size + "px"};
  justify-content: center;
  left: ${(props) => props.x * props.size + "px"};
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  width: ${(props) => props.size + "px"};
  pointer-events: none;
`;

const Coordinate = React.memo(({ x, y, isFocused, data, display }) => {
  return (
    <CoordinateStyle
      data-coordinate={data}
      size={TILE_SIZE}
      x={x}
      y={y}
      isFocused={isFocused}
    >
      {display}
    </CoordinateStyle>
  );
});

export const StadiumCoordinatesX = React.memo(({ stadium_width }) => {
  const focus = useRecoilValue(focusState);
  const focusX = focus ? focus.x : null;
  let coordinates = [];
  for (let x = 0; x < stadium_width; x++) {
    coordinates.push(
      <Coordinate
        key={"coordinate-x-" + x}
        data={"x-" + x}
        x={x}
        y={-1}
        isFocused={x === focusX}
        display={coordinatesConvertorX(x, isDev())}
      ></Coordinate>
    );
  }
  return coordinates;
});

export const StadiumCoordinatesY = React.memo(({ stadium_height }) => {
  const focus = useRecoilValue(focusState);
  const focusY = focus ? focus.y : null;
  let coordinates = [];
  // Outer loop to create parent
  for (let y = 0; y < stadium_height; y++) {
    coordinates.push(
      <Coordinate
        key={"coordinate-y-" + y}
        data={"y-" + y}
        x={-1}
        y={y}
        isFocused={y === focusY}
        display={coordinatesConvertorY(y, isDev())}
      ></Coordinate>
    );
  }
  return coordinates;
});
