import React, { useContext, useCallback, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  getNumberPlayerZone,
  getScrimmageTile,
  getLateralATile,
  getLateralBTile,
  getCenterTile,
  getTile,
} from "enefel/pitch";
import { RootContext } from "../../../RootContext";
import styled from "styled-components";
import { TILE_SIZE } from "../Stadium";
import { SET_UP_POSITION } from "../../../../query/TeamQueries";
import { GAME_SET_UP } from "../../../../query/GameQueries";
import QueryError from "../../../Error/QueryError";
import { currentGameState } from "../../atoms/gameState";
import { useRecoilValue } from "recoil";
import { hasStatusOnField, PLAYER_STATUS } from "enefel/status";

export const SetupTileWrapper = styled.div`
  background-color: #00ffff;
  cursor: pointer;
  height: ${(props) => props.size + "px"};
  left: ${(props) => props.x * props.size + "px"};
  opacity: 0.5;
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  width: ${(props) => props.size + "px"};
  z-index: 500;
`;

const SetUp = React.memo(
  ({ side, isSetUpVisible }) => {
    const { currentPlayer, updateCurrentPlayer } = useContext(RootContext);
    const game = useRecoilValue(currentGameState);
    const team = side === 1 ? game.team1 : game.team2;

    const { data, startPolling } = useQuery(GAME_SET_UP, {
      variables: {
        id: team.id,
      },
    });
    useEffect(() => {
      startPolling(3000);
    }, [startPolling]);
    const MIN_SCRIMMAGE = 3;
    const MAX_LATERAL = 2;
    const [setUpPosition, { loading, error }] = useMutation(SET_UP_POSITION);
    const playersByPosition = {};
    // TODO : factorize with back canPosition and MIN_SCRIMMAGE/MAX_LATERAL from stadium/league?
    const scrimmage = getScrimmageTile(side);
    const laterala = getLateralATile(side);
    const lateralb = getLateralBTile(side);
    const center = getCenterTile(side);
    const setUp = useCallback(
      (x, y) => {
        setUpPosition({
          variables: {
            playerId: currentPlayer.id,
            x,
            y,
          },
        }).then(({ data }) => {
          const player = data.setUpPosition.players.find(
            (p) => p.id === currentPlayer.id
          );
          if (player) {
            updateCurrentPlayer(player);
          }
        });
      },
      [setUpPosition, currentPlayer, updateCurrentPlayer]
    );
    if (!data?.team?.players) {
      return null;
    }
    const players = data.team.players;

    const { playerScrimmage, playerLateralA, playerLateralB } =
      getNumberPlayerZone(side, players, game);

    players.forEach((p) => {
      playersByPosition[p.x + "-" + p.y] = p;
    });

    if (
      !currentPlayer ||
      (!hasStatusOnField(currentPlayer) &&
        currentPlayer.status !== PLAYER_STATUS.RESERVE)
    ) {
      return null;
    }
    const currentTile = getTile(game, currentPlayer?.x, currentPlayer?.y);

    const canPosition = (targetPlayer, targetTile) => {
      const isOnField = hasStatusOnField(currentPlayer);
      if (targetPlayer) {
        if (!(isOnField || currentPlayer.status === PLAYER_STATUS.RESERVE)) {
          return false;
        }
        if (targetPlayer.order <= currentPlayer.order) {
          return false;
        }
      } else {
        if (!isOnField) {
          return false;
        }
        if (
          currentTile === scrimmage &&
          targetTile !== scrimmage &&
          playerScrimmage <= MIN_SCRIMMAGE
        ) {
          return false;
        }

        if (
          currentTile !== laterala &&
          targetTile === laterala &&
          playerLateralA >= MAX_LATERAL
        ) {
          return false;
        }

        if (
          currentTile !== lateralb &&
          targetTile === lateralb &&
          playerLateralB >= MAX_LATERAL
        ) {
          return false;
        }

        if (
          targetTile !== scrimmage &&
          targetTile !== laterala &&
          targetTile !== lateralb &&
          targetTile !== center
        ) {
          return false;
        }
      }
      return true;
    };

    const getTiles = () => {
      const tiles = [];
      for (let y = 0; y < game.stadium_height; y++) {
        for (let x = 0; x < game.stadium_width; x++) {
          const targetTile = getTile(game, x, y);
          const player = playersByPosition[x + "-" + y];

          if (canPosition(player, targetTile)) {
            tiles.push(
              <SetupTileWrapper
                key={"setup" + x + "-" + y}
                x={x}
                y={y}
                size={TILE_SIZE}
                onClick={setUp.bind(null, x, y)}
                data-setup-position={x + "-" + y}
              >
                {"\u00A0"}
              </SetupTileWrapper>
            );
          }
        }
      }
      return tiles;
    };
    if (isSetUpVisible === false) {
      return null;
    }
    return (
      <>
        {error && <QueryError error={error} />}
        {/* TODO: position loading (absolute?) */}
        {loading ? null : getTiles()}
      </>
    );
  },
  (prev, next) => {
    return (
      prev.isSetUpVisible === next.isSetUpVisible && prev.side === next.side
    );
  }
);
export default SetUp;
