import { hasStatusFall, hasStatusInjury } from "enefel/status";
import React from "react";
import styled from "styled-components";
import publicPath from "../../../../../helpers/publicPath";
// import { getRandomNumber } from "enefel/random";

const Wrapper = styled.div`
  background-image: ${`url("${publicPath(`tile/weapon/foulAppearance.gif`)}")`};
  background-size: cover;
  top: 50%;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 1;
`;

const FoulAppearance = React.memo(({ player }) => {
  if (!player) {
    return null;
  }
  const isFall = hasStatusFall(player) || hasStatusInjury(player);
  if (isFall) {
    return null;
  }

  return null; //<Wrapper />;
});

export default FoulAppearance;
