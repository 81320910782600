import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../Interface/Button";

const BlockValidation = React.memo(
  ({ onValidateBlock, blitz, attackerChoose, dices, isGfi }) => {
    const { t } = useTranslation();
    let text = "";
    text += blitz ? t("t:Blitz", "__Blitz") : t("t:Block", "__Block");
    text +=
      " " +
      (!attackerChoose ? t("t:versus", "__versus") : t("t:with", "__with"));
    text += " " + dices;
    text += " " + (dices > 1 ? t("t:dices", "__dices") : t("t:dice", "__dice"));
    text += isGfi ? " (" + t("t:gfi", "__gfi") + ")" : "";

    return (
      <div>
        <br />
        <Button
          onClick={onValidateBlock}
          critical={attackerChoose}
          warning={!attackerChoose}
        >
          {text}
        </Button>
      </div>
    );
  }
);

export default BlockValidation;
