import { getCurrentRange, ranges } from "enefel/improvement";
import { memo } from "react";
import styled from "styled-components";
import CustomTooltip from "../Interface/Tooltip";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  position: relative;
  height: ${(props) => (400 * props.widthPx) / 320}px;
  width: ${(props) => props.widthPx}px;
  background: black;
  border: 0.3cqmin outset #d7d7d7;
  border-radius: 10px 10px 200px 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2cqmin;
  position: relative;
  overflow: hidden;
  user-select: none;

  span {
    position: relative;
    filter: drop-shadow(1px 0 0 #360202) drop-shadow(-1px 0 0 #360202)
      drop-shadow(0 1px 0 #360202) drop-shadow(0 -1px 0 #360202);
    color: white;
  }
  .wave {
    background-color: red;
    position: absolute;
    height: 150%;
    width: 200%;
    border-radius: 38%;
    left: -50%;
    transform: rotate(360deg);
    transition: all 5s ease;
    top: ${(props) => (props.level / 5.7) * 100}%;
    box-shadow: inset 0 0 2cqmin rgba(0, 0, 0, 0.5);
  }
  .wave2 {
    background-color: rgb(255 85 90);
    position: absolute;
    height: 150%;
    width: 200%;
    border-radius: 38%;
    left: -50%;
    transform: rotate(360deg);
    transition: all 3s ease;
    top: ${(props) => (props.level / 6) * 100}%;
  }

  &:hover {
    .wave {
      animation: wave 40s linear infinite;
    }
    .wave2 {
      animation: wave 50s linear infinite;
    }
  }
  @keyframes wave {
    0% {
      transform: rotate(0deg);
      border-radius: 38%;
    }
    100% {
      transform: rotate(3600deg);
      border-radius: 50%;
    }
  }
`;
const PlayerLevel = memo(({ player, widthPx = 25 }) => {
  const { t } = useTranslation();
  if (!player || player.experience == null) {
    return null;
  }
  const level = getCurrentRange(player.experience);

  return (
    <CustomTooltip
      placement="top"
      title={t("t:level description", "__level description")}
    >
      <Wrapper widthPx={widthPx} level={ranges.length + 1 - level}>
        <div className="wave2"></div>
        <div className="wave"></div>
        <span>{level}</span>
      </Wrapper>
    </CustomTooltip>
  );
});
export default PlayerLevel;
