import React, { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { DicesWrapper } from "./DiceOrder";
import DiceOrderContent from "./DiceOrderContent";
import bp from "../../../../../styles/breakpoint";
const DiceOrderSortable = React.memo(
  ({ order, onChange, attacker, defender }) => {
    const [orders, setOrders] = useState(order);
    const [isMobile, setIsMobile] = useState(
      window.matchMedia(`(max-width: ${bp.md})`).matches
    );

    useEffect(() => {
      onChange(orders.map((o) => ({ value: o.value, isReroll: o.isReroll })));
    }, [orders, onChange]);

    function handleToogleReroll(index) {
      const newOrders = [...orders];
      newOrders[index].isReroll = !newOrders[index].isReroll;
      setOrders(newOrders);
    }

    const handleLeft = (index) => {
      if (index <= 0) {
        return;
      }
      // change two elements in order, index and index - 1
      let newOrder = [...orders];
      let temp = newOrder[index];
      newOrder[index] = newOrder[index - 1];
      newOrder[index - 1] = temp;
      setOrders(newOrder);
    };

    const handleRight = (index) => {
      if (index >= orders.length - 1) {
        return;
      }

      // change two elements in order, index and index + 1
      let newOrder = [...orders];
      let temp = newOrder[index];
      newOrder[index] = newOrder[index + 1];
      newOrder[index + 1] = temp;
      setOrders(newOrder);
    };

    useEffect(() => {
      const mediaQuery = window.matchMedia(`(max-width: ${bp.md})`);
      const handleResize = () => { 
        setIsMobile(mediaQuery.matches);
      };

      mediaQuery.addListener(handleResize);
      return () => mediaQuery.removeListener(handleResize);
    }, []);

    return (
      <>
        {isMobile ? (
          <div style={DicesWrapper}>
            {orders.map((dice, index) => {
              return (
                <DiceOrderContent
                  key={dice.value}
                  value={dice.value}
                  index={index + 1}
                  isReroll={dice.isReroll}
                  onToogle={() => handleToogleReroll(index)}
                  attacker={attacker}
                  defender={defender}
                  onLeft={handleLeft}
                  onRight={handleRight}
                />
              );
            })}
          </div>
        ) : (
          <ReactSortable
            list={orders}
            setList={setOrders}
            animation={150}
            ghostClass={"blue-background-class"}
            style={DicesWrapper}
          >
            {orders.map((dice, index) => {
              return (
                <DiceOrderContent
                  key={dice.value}
                  value={dice.value}
                  index={index + 1}
                  isReroll={dice.isReroll}
                  onToogle={() => handleToogleReroll(index)}
                  attacker={attacker}
                  defender={defender}
                  onLeft={handleLeft}
                  onRight={handleRight}
                />
              );
            })}
          </ReactSortable>
        )}
      </>
    );
  }
);

export default DiceOrderSortable;
