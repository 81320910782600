import React from "react";

import { TableWrapper, Table, Thead, Th, HeaderTr, Td, Tr } from "./styles";

const ReactTableContent = ({
  getTableBodyProps,
  getTableProps,
  headerGroups,
  hidezero,
  prepareRow,
  rows,
  separators,
  hasHeader = true,
}) => {
  return (
    <TableWrapper>
      <Table {...getTableProps()}>
        <Thead visible={hasHeader}>
          {headerGroups.map((headerGroup) => (
            <HeaderTr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ▼" : " ▲") : ""}
                  </span>
                </Th>
              ))}
            </HeaderTr>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      separators={separators}
                      alphaValue={hidezero && +cell.value === 0}
                    >
                      {cell.value === 0 && cell.column.id !== "pop"
                        ? "-"
                        : cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

export default ReactTableContent;
