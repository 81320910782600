import { isCoach } from "enefel";
import { useTranslation } from "react-i18next";
import React from "react";

import CustomTooltip from "../Interface/Tooltip";
import { AiFillStar } from "react-icons/ai";

const PlayerIsCoach = React.memo(({ player, team }) => {
  const { t } = useTranslation();
  return isCoach(player, team) ? (
    <CustomTooltip placement="top" title={t("t:Coach", "__Coach")}>
      <span>
        <AiFillStar style={{ color: "yellow" }} />
      </span>
    </CustomTooltip>
  ) : null;
});

export default PlayerIsCoach;
