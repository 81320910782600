import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";
import styled from "styled-components";
import { paths } from "../../path";
import palette from "../../styles/palette";
import A from "../A";
import Unread from "../Team/Chat/Unread";
import { Align } from "./Menu";

const Wrapper = styled.div``;

const MenuItemCustom = styled(MenuItem)`
  margin: 0;
  padding: 0;
  & > * {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 6px 12px;
  }
`;

const Text = styled.div`
  align-items: baseline;
  color: white;
  display: flex;
  font-weight: bold;
  opacity: 0.8;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity 0.3s ease;
  align-items: center;
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: underline;
  }
`;
const SubMenu = ({ currentPlayer }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <Text
        onClick={handleClick}
        onMouseOver={handleClick}
        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
      >
        <BsThreeDots />
      </Text>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <MenuItemCustom onClick={handleClose}>
          <A to={paths.skills} big={true} color={palette.primary.main}>
            {t("t:Skills", "__Skills")}
          </A>
        </MenuItemCustom>
        <MenuItemCustom onClick={handleClose}>
          <A to={paths.careers} big={true} color={palette.primary.main}>
            {t("t:Careers", "__Careers")}
          </A>
        </MenuItemCustom>
        <MenuItemCustom onClick={handleClose}>
          <A to={paths.hallOfFame} big={true} color={palette.primary.main}>
            {t("t:hall of fame", "__hall of fame")}
          </A>
        </MenuItemCustom>
        <MenuItemCustom onClick={handleClose}>
          <A to={paths.chats} big={true} color={palette.primary.main}>
            <Align>
              <span>{t("t:Chats", "__Chats")}</span>
              {currentPlayer &&
                currentPlayer.chats &&
                currentPlayer.chats.some((chat) => chat.hasNewMessages) && (
                  <Unread />
                )}
            </Align>
          </A>
        </MenuItemCustom>
        {/* <MenuItemCustom onClick={handleClose}>
          <ExternalLink
            href={t("t:rule url", "__rule url")}
            target={"_blank"}
            rel="noopener noreferrer"
            color={palette.primary.main}
          >
            {t("t:wiki", "__wiki")}
          </ExternalLink>
        </MenuItemCustom> */}
      </Menu>
    </Wrapper>
  );
};

export default SubMenu;
