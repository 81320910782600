import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

const Wrapper = styled.div`
  display: inline-block;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;
const Loading = React.memo(({ visible = true }) => {
  return (
    <Wrapper visible={visible}>
      <CircularProgress color="secondary" size={30} />
    </Wrapper>
  );
});

export default Loading;
