import React, { useEffect, useState } from "react";
import { TILE_SIZE } from "../../Stadium/Stadium";
import {
  availableSquaresToPushBack,
  isSquareValidToPushBack,
  getBlockAssistance,
  getPlayerOnCell,
} from "enefel";
import { hasSkill, SKILL_NAMES } from "enefel/skill";
import styled from "styled-components";
import IconPush from "../../../Icon/action/IconPush";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentGameState, gameState, pushsState } from "../../atoms/gameState";
import { getMissingPush } from "../Outside/Block";
import { isFrenzyAvailable } from "enefel/block";

const getPointKey = (p1, p2) => {
  return p1.x + " " + p1.y + " " + p2.x + " " + p2.y;
};

const getPushSquares = (attacker, defender, game, players, previousPushs) => {
  let squares = {};
  function calculSquares(p1, p2) {
    let availables = availableSquaresToPushBack(p1, p2);
    availables = availables.filter((square, index) => {
      return isSquareValidToPushBack(
        availables,
        index,
        players,
        game.stadium_width,
        game.stadium_height,
        previousPushs
      );
    });
    squares[getPointKey(p1, p2)] = availables;
    availables
      .filter((square) => getPlayerOnCell(square.x, square.y, players))
      .forEach((square) => {
        calculSquares(p2, square);
      });
  }

  calculSquares(attacker, defender);
  return squares;
};

const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: ${(props) => props.size + "px"};
  justify-content: center;
  left: ${(props) => props.x * props.size + "px"};
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  width: ${(props) => props.size + "px"};
  z-index: 1555;

  span {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      .bg {
        fill: grey;
      }
    }
  }

  input {
    display: none;
  }

  input:checked {
    background-color: yellow;

    :checked + span {
      svg {
        .bg {
          fill: white;
        }
      }
    }
  }
`;

const Push = React.memo(({ squares, level, onPushSelection, isFrenzy }) => {
  if (!squares) {
    return null;
  }
  const handleClickPush = (square, level) => {
    onPushSelection(square, level);
  };
  return squares.map((square) => {
    return (
      <Label
        key={square.x + "-" + square.y}
        size={TILE_SIZE}
        x={square.x}
        y={square.y}
      >
        <input
          name={isFrenzy ? "frenzy-block" + level : "block" + level}
          type="radio"
          onChange={() => handleClickPush(square, level)}
        />
        <span>
          <IconPush direction={square.d} isFrenzy={isFrenzy} />
        </span>
      </Label>
    );
  });
});

const Pushs = React.memo(
  ({ attacker, defender, players, isFrenzy = false }) => {
    const game = useRecoilValue(currentGameState);
    const setGameState = useSetRecoilState(gameState);
    const [pushs, setPushs] = useState([]);

    let previousPushs = useRecoilValue(pushsState);
    previousPushs = isFrenzy ? previousPushs : [];
    const list = [];
    const all = [
      { x: attacker.x, y: attacker.y },
      { x: defender.x, y: defender.y },
      ...pushs,
    ];

    const squares = getPushSquares(
      attacker,
      defender,
      game,
      players,
      previousPushs
    );

    const handlePushSelection = (square, level) => {
      if (
        pushs[level] &&
        pushs[level].x === square.x &&
        pushs[level].y === square.y
      ) {
        return;
      }
      const newPushs = pushs.slice(0, level + 1);
      newPushs[level] = square;
      setPushs(newPushs);
      if (isFrenzy) {
        setGameState((s) => ({ ...s, pushsFrenzy: newPushs }));
      } else {
        setGameState((s) => ({ ...s, pushs: newPushs }));
      }
    };

    for (let i = 1; i < all.length; ++i) {
      if (squares[getPointKey(all[i - 1], all[i])]) {
        list.push(
          <Push
            key={getPointKey(all[i - 1], all[i])}
            squares={squares[getPointKey(all[i - 1], all[i])]}
            level={i - 1}
            onPushSelection={handlePushSelection}
            isFrenzy={isFrenzy}
          />
        );
      }
    }
    return list;
  }
);

const AssistanceMarker = styled.div`
  border-radius: 100px;
  height: 10px;
  left: ${(props) => props.x * props.size + "px"};
  position: absolute;
  top: ${(props) => props.y * props.size + "px"};
  width: 10px;
  z-index: 15;
  color: ${(props) => props.color};
  font-size: 0.8em;
  font-weight: bold;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
`;
const Assistance = React.memo(({ attacker, defender, players }) => {
  const attackerAssistances = getBlockAssistance(
    attacker,
    defender,
    players,
    true
  );
  const defenderAssistances = getBlockAssistance(defender, attacker, players);
  const list = [];
  attackerAssistances.forEach((player) => {
    list.push(
      <AssistanceMarker
        border={hasSkill(player, SKILL_NAMES.GUARD) ? "blue" : "black"}
        color={"chartreuse"}
        key={player.id}
        size={TILE_SIZE}
        x={player.x}
        y={player.y}
      >
        +1
      </AssistanceMarker>
    );
  });
  defenderAssistances.forEach((player) => {
    list.push(
      <AssistanceMarker
        border={hasSkill(player, SKILL_NAMES.GUARD) ? "blue" : "black"}
        color={"red"}
        key={player.id}
        size={TILE_SIZE}
        x={player.x}
        y={player.y}
      >
        +1
      </AssistanceMarker>
    );
  });
  return list;
});

const FrenzyBlock = React.memo(({ attacker, defender, players }) => {
  const pushs = useRecoilValue(pushsState);
  const [isFirstPushChosen, setIsFirstPushChosen] = useState(false);

  useEffect(() => {
    const missing = getMissingPush(pushs);
    setIsFirstPushChosen(pushs && pushs.length > 0 && missing === null);
  }, [pushs]);

  return (
    <>
      <Assistance attacker={attacker} defender={defender} players={players} />

      {isFirstPushChosen ? (
        <Pushs
          attacker={defender}
          defender={pushs[0]}
          players={players}
          isFrenzy={true}
        />
      ) : (
        <Pushs attacker={attacker} defender={defender} players={players} />
      )}
    </>
  );
});

const NormalBlock = React.memo(({ attacker, defender, players }) => {
  return (
    <>
      <Assistance attacker={attacker} defender={defender} players={players} />
      <Pushs attacker={attacker} defender={defender} players={players} />
    </>
  );
});

const Block = React.memo(({ attacker, defender, players }) => {
  let hasFrenzy = isFrenzyAvailable(attacker, defender);

  return hasFrenzy ? (
    <FrenzyBlock attacker={attacker} defender={defender} players={players} />
  ) : (
    <NormalBlock attacker={attacker} defender={defender} players={players} />
  );
});
export default Block;
