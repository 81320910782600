import { PLAYER_STAT } from "enefel";
import { useTranslation } from "react-i18next";
import CustomTooltip from "../Interface/Tooltip";
import React from "react";

const StatHeader = React.memo(({ field }) => {
  const { t } = useTranslation("stat");
  function getStatsText() {
    switch (field) {
      case PLAYER_STAT.EXPERIENCE:
        return [
          t("stat:EXPERIENCEShort", "__EXPERIENCEShort"),
          t("stat:EXPERIENCE", "__EXPERIENCE"),
        ];
      case PLAYER_STAT.BLITZ:
        return [
          t("stat:BLITZShort", "__BLITZShort"),
          t("stat:BLITZ", "__BLITZ"),
        ];
      case PLAYER_STAT.BLOCK_1D:
        return [
          t("stat:BLOCK_1DShort", "__BLOCK_1DShort"),
          t("stat:BLOCK_1D", "__BLOCK_1D"),
        ];
      case PLAYER_STAT.BLOCK_2D:
        return [
          t("stat:BLOCK_2DShort", "__BLOCK_2DShort"),
          t("stat:BLOCK_2D", "__BLOCK_2D"),
        ];
      case PLAYER_STAT.BLOCK_3D:
        return [
          t("stat:BLOCK_3DShort", "__BLOCK_3DShort"),
          t("stat:BLOCK_3D", "__BLOCK_3D"),
        ];
      case PLAYER_STAT.BLOCK_ARMOR_FAIL:
        return [
          t("stat:BLOCK_ARMOR_FAILShort", "__BLOCK_ARMOR_FAILShort"),
          t("stat:BLOCK_ARMOR_FAIL", "__BLOCK_ARMOR_FAIL"),
        ];
      case PLAYER_STAT.BLOCK_ARMOR_SUCCESS:
        return [
          t("stat:BLOCK_ARMOR_SUCCESSShort", "__BLOCK_ARMOR_SUCCESSShort"),
          t("stat:BLOCK_ARMOR_SUCCESS", "__BLOCK_ARMOR_SUCCESS"),
        ];
      case PLAYER_STAT.BLOCK_ASSISTANCE_ATTACK:
        return [
          t(
            "stat:BLOCK_ASSISTANCE_ATTACKShort",
            "__BLOCK_ASSISTANCE_ATTACKShort"
          ),
          t("stat:BLOCK_ASSISTANCE_ATTACK", "__BLOCK_ASSISTANCE_ATTACK"),
        ];
      case PLAYER_STAT.BLOCK_ASSISTANCE_DEFENSE:
        return [
          t(
            "stat:BLOCK_ASSISTANCE_DEFENSEShort",
            "__BLOCK_ASSISTANCE_DEFENSEShort"
          ),
          t("stat:BLOCK_ASSISTANCE_DEFENSE", "__BLOCK_ASSISTANCE_DEFENSE"),
        ];
      case PLAYER_STAT.BLOCK_M2D:
        return [
          t("stat:BLOCK_M2DShort", "__BLOCK_M2DShort"),
          t("stat:BLOCK_M2D", "__BLOCK_M2D"),
        ];
      case PLAYER_STAT.BLOCK_M3D:
        return [
          t("stat:BLOCK_M3DShort", "__BLOCK_M3DShort"),
          t("stat:BLOCK_M3D", "__BLOCK_M3D"),
        ];
      case PLAYER_STAT.BLOCK_RESULT_1:
        return [
          t("stat:BLOCK_RESULT_1Short", "__BLOCK_RESULT_1Short"),
          t("stat:BLOCK_RESULT_1", "__BLOCK_RESULT_1"),
        ];
      case PLAYER_STAT.BLOCK_RESULT_2:
        return [
          t("stat:BLOCK_RESULT_2Short", "__BLOCK_RESULT_2Short"),
          t("stat:BLOCK_RESULT_2", "__BLOCK_RESULT_2"),
        ];
      case PLAYER_STAT.BLOCK_RESULT_3:
        return [
          t("stat:BLOCK_RESULT_3Short", "__BLOCK_RESULT_3Short"),
          t("stat:BLOCK_RESULT_3", "__BLOCK_RESULT_3"),
        ];
      case PLAYER_STAT.BLOCK_RESULT_4:
        return [
          t("stat:BLOCK_RESULT_4Short", "__BLOCK_RESULT_4Short"),
          t("stat:BLOCK_RESULT_4", "__BLOCK_RESULT_4"),
        ];
      case PLAYER_STAT.BLOCK_RESULT_5:
        return [
          t("stat:BLOCK_RESULT_5Short", "__BLOCK_RESULT_5Short"),
          t("stat:BLOCK_RESULT_5", "__BLOCK_RESULT_5"),
        ];
      case PLAYER_STAT.DODGE_FAIL:
        return [
          t("stat:DODGE_FAILShort", "__DODGE_FAILShort"),
          t("stat:DODGE_FAIL", "__DODGE_FAIL"),
        ];
      case PLAYER_STAT.DODGE_SUCCESS:
        return [
          t("stat:DODGE_SUCCESSShort", "__DODGE_SUCCESSShort"),
          t("stat:DODGE_SUCCESS", "__DODGE_SUCCESS"),
        ];
      case PLAYER_STAT.FACEUP:
        return [
          t("stat:FACEUPShort", "__FACEUPShort"),
          t("stat:FACEUP", "__FACEUP"),
        ];
      case PLAYER_STAT.FOUL_ASSISTANCE_ATTACK:
        return [
          t(
            "stat:FOUL_ASSISTANCE_ATTACKShort",
            "__FOUL_ASSISTANCE_ATTACKShort"
          ),
          t("stat:FOUL_ASSISTANCE_ATTACK", "__FOUL_ASSISTANCE_ATTACK"),
        ];
      case PLAYER_STAT.FOUL_ASSISTANCE_DEFENSE:
        return [
          t(
            "stat:FOUL_ASSISTANCE_DEFENSEShort",
            "__FOUL_ASSISTANCE_DEFENSEShort"
          ),
          t("stat:FOUL_ASSISTANCE_DEFENSE", "__FOUL_ASSISTANCE_DEFENSE"),
        ];
      case PLAYER_STAT.FOUL_FAIL:
        return [
          t("stat:FOUL_FAILShort", "__FOUL_FAILShort"),
          t("stat:FOUL_FAIL", "__FOUL_FAIL"),
        ];
      case PLAYER_STAT.FOUL_RECEIVE:
        return [
          t("stat:FOUL_RECEIVEShort", "__FOUL_RECEIVEShort"),
          t("stat:FOUL_RECEIVE", "__FOUL_RECEIVE"),
        ];
      case PLAYER_STAT.FOUL_SENDOFF:
        return [
          t("stat:FOUL_SENDOFFShort", "__FOUL_SENDOFFShort"),
          t("stat:FOUL_SENDOFF", "__FOUL_SENDOFF"),
        ];
      case PLAYER_STAT.FOUL_SUCCESS:
        return [
          t("stat:FOUL_SUCCESSShort", "__FOUL_SUCCESSShort"),
          t("stat:FOUL_SUCCESS", "__FOUL_SUCCESS"),
        ];
      case PLAYER_STAT.GAME_DRAW:
        return [
          t("stat:GAME_DRAWShort", "__GAME_DRAWShort"),
          t("stat:GAME_DRAW", "__GAME_DRAW"),
        ];
      case PLAYER_STAT.GAME_LOSS:
        return [
          t("stat:GAME_LOSSShort", "__GAME_LOSSShort"),
          t("stat:GAME_LOSS", "__GAME_LOSS"),
        ];
      case PLAYER_STAT.GAME_PLAYED:
        return [
          t("stat:GAME_PLAYEDShort", "__GAME_PLAYEDShort"),
          t("stat:GAME_PLAYED", "__GAME_PLAYED"),
        ];
      case PLAYER_STAT.GAME_VICTORY:
        return [
          t("stat:GAME_VICTORYShort", "__GAME_VICTORYShort"),
          t("stat:GAME_VICTORY", "__GAME_VICTORY"),
        ];
      case PLAYER_STAT.GAME:
        return [t("stat:GAMEShort", "__GAMEShort"), t("stat:GAME", "__GAME")];
      case PLAYER_STAT.GO_FOR_IT_1_FAIL:
        return [
          t("stat:GO_FOR_IT_1_FAILShort", "__GO_FOR_IT_1_FAILShort"),
          t("stat:GO_FOR_IT_1_FAIL", "__GO_FOR_IT_1_FAIL"),
        ];
      case PLAYER_STAT.GO_FOR_IT_1_SUCCESS:
        return [
          t("stat:GO_FOR_IT_1_SUCCESSShort", "__GO_FOR_IT_1_SUCCESSShort"),
          t("stat:GO_FOR_IT_1_SUCCESS", "__GO_FOR_IT_1_SUCCESS"),
        ];

      case PLAYER_STAT.HANDING_OFF_FAIL:
        return [
          t("stat:HANDING_OFF_FAILShort", "__HANDING_OFF_FAILShort"),
          t("stat:HANDING_OFF_FAIL", "__HANDING_OFF_FAIL"),
        ];
      case PLAYER_STAT.HANDING_OFF_SUCCESS:
        return [
          t("stat:HANDING_OFF_SUCCESSShort", "__HANDING_OFF_SUCCESSShort"),
          t("stat:HANDING_OFF_SUCCESS", "__HANDING_OFF_SUCCESS"),
        ];
      case PLAYER_STAT.INJURY_CASUALTY:
        return [
          t("stat:INJURY_CASUALTYShort", "__INJURY_CASUALTYShort"),
          t("stat:INJURY_CASUALTY", "__INJURY_CASUALTY"),
        ];
      case PLAYER_STAT.INJURY_KNOCKED_DOWN:
        return [
          t("stat:INJURY_KNOCKED_DOWNShort", "__INJURY_KNOCKED_DOWNShort"),
          t("stat:INJURY_KNOCKED_DOWN", "__INJURY_KNOCKED_DOWN"),
        ];
      case PLAYER_STAT.INJURY_KO:
        return [
          t("stat:INJURY_KOShort", "__INJURY_KOShort"),
          t("stat:INJURY_KO", "__INJURY_KO"),
        ];
      case PLAYER_STAT.INTERCEPTION_FAIL:
        return [
          t("stat:INTERCEPTION_FAILShort", "__INTERCEPTION_FAILShort"),
          t("stat:INTERCEPTION_FAIL", "__INTERCEPTION_FAIL"),
        ];
      case PLAYER_STAT.INTERCEPTION_SUCCESS:
        return [
          t("stat:INTERCEPTION_SUCCESSShort", "__INTERCEPTION_SUCCESSShort"),
          t("stat:INTERCEPTION_SUCCESS", "__INTERCEPTION_SUCCESS"),
        ];
      case PLAYER_STAT.MOVE:
        return [t("stat:MOVEShort", "__MOVEShort"), t("stat:MOVE", "__MOVE")];
      case PLAYER_STAT.PASS_COMPLETED:
        return [
          t("stat:PASS_COMPLETEDShort", "__PASS_COMPLETEDShort"),
          t("stat:PASS_COMPLETED", "__PASS_COMPLETED"),
        ];
      case PLAYER_STAT.PASS_FAIL:
        return [
          t("stat:PASS_FAILShort", "__PASS_FAILShort"),
          t("stat:PASS_FAIL", "__PASS_FAIL"),
        ];
      case PLAYER_STAT.PASS_FUMBLE:
        return [
          t("stat:PASS_FUMBLEShort", "__PASS_FUMBLEShort"),
          t("stat:PASS_FUMBLE", "__PASS_FUMBLE"),
        ];
      case PLAYER_STAT.PASS_INTERCEPTED:
        return [
          t("stat:PASS_INTERCEPTEDShort", "__PASS_INTERCEPTEDShort"),
          t("stat:PASS_INTERCEPTED", "__PASS_INTERCEPTED"),
        ];
      case PLAYER_STAT.PASS_NOT_COMPLETED:
        return [
          t("stat:PASS_NOT_COMPLETEDShort", "__PASS_NOT_COMPLETEDShort"),
          t("stat:PASS_NOT_COMPLETED", "__PASS_NOT_COMPLETED"),
        ];
      case PLAYER_STAT.PASS_PERFECT:
        return [
          t("stat:PASS_PERFECTShort", "__PASS_PERFECTShort"),
          t("stat:PASS_PERFECT", "__PASS_PERFECT"),
        ];
      case PLAYER_STAT.PASS_PERFECT_COMPLETED:
        return [
          t(
            "stat:PASS_PERFECT_COMPLETEDShort",
            "__PASS_PERFECT_COMPLETEDShort"
          ),
          t("stat:PASS_PERFECT_COMPLETED", "__PASS_PERFECT_COMPLETED"),
        ];
      case PLAYER_STAT.RECEIVE_ARMOR_FAIL:
        return [
          t("stat:RECEIVE_ARMOR_FAILShort", "__RECEIVE_ARMOR_FAILShort"),
          t("stat:RECEIVE_ARMOR_FAIL", "__RECEIVE_ARMOR_FAIL"),
        ];
      case PLAYER_STAT.RECEIVE_ARMOR_SUCCESS:
        return [
          t("stat:RECEIVE_ARMOR_SUCCESSShort", "__RECEIVE_ARMOR_SUCCESSShort"),
          t("stat:RECEIVE_ARMOR_SUCCESS", "__RECEIVE_ARMOR_SUCCESS"),
        ];
      case PLAYER_STAT.RECEIVE_BLITZ:
        return [
          t("stat:RECEIVE_BLITZShort", "__RECEIVE_BLITZShort"),
          t("stat:RECEIVE_BLITZ", "__RECEIVE_BLITZ"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_1D:
        return [
          t("stat:RECEIVE_BLOCK_1DShort", "__RECEIVE_BLOCK_1DShort"),
          t("stat:RECEIVE_BLOCK_1D", "__RECEIVE_BLOCK_1D"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_2D:
        return [
          t("stat:RECEIVE_BLOCK_2DShort", "__RECEIVE_BLOCK_2DShort"),
          t("stat:RECEIVE_BLOCK_2D", "__RECEIVE_BLOCK_2D"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_3D:
        return [
          t("stat:RECEIVE_BLOCK_3DShort", "__RECEIVE_BLOCK_3DShort"),
          t("stat:RECEIVE_BLOCK_3D", "__RECEIVE_BLOCK_3D"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_ARMOR_FAIL:
        return [
          t(
            "stat:RECEIVE_BLOCK_ARMOR_FAILShort",
            "__RECEIVE_BLOCK_ARMOR_FAILShort"
          ),
          t("stat:RECEIVE_BLOCK_ARMOR_FAIL", "__RECEIVE_BLOCK_ARMOR_FAIL"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_ARMOR_SUCCESS:
        return [
          t(
            "stat:RECEIVE_BLOCK_ARMOR_SUCCESSShort",
            "__RECEIVE_BLOCK_ARMOR_SUCCESSShort"
          ),
          t(
            "stat:RECEIVE_BLOCK_ARMOR_SUCCESS",
            "__RECEIVE_BLOCK_ARMOR_SUCCESS"
          ),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_M2D:
        return [
          t("stat:RECEIVE_BLOCK_M2DShort", "__RECEIVE_BLOCK_M2DShort"),
          t("stat:RECEIVE_BLOCK_M2D", "__RECEIVE_BLOCK_M2D"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_M3D:
        return [
          t("stat:RECEIVE_BLOCK_M3DShort", "__RECEIVE_BLOCK_M3DShort"),
          t("stat:RECEIVE_BLOCK_M3D", "__RECEIVE_BLOCK_M3D"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_RESULT_1:
        return [
          t(
            "stat:RECEIVE_BLOCK_RESULT_1Short",
            "__RECEIVE_BLOCK_RESULT_1Short"
          ),
          t("stat:RECEIVE_BLOCK_RESULT_1", "__RECEIVE_BLOCK_RESULT_1"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_RESULT_2:
        return [
          t(
            "stat:RECEIVE_BLOCK_RESULT_2Short",
            "__RECEIVE_BLOCK_RESULT_2Short"
          ),
          t("stat:RECEIVE_BLOCK_RESULT_2", "__RECEIVE_BLOCK_RESULT_2"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_RESULT_3:
        return [
          t(
            "stat:RECEIVE_BLOCK_RESULT_3Short",
            "__RECEIVE_BLOCK_RESULT_3Short"
          ),
          t("stat:RECEIVE_BLOCK_RESULT_3", "__RECEIVE_BLOCK_RESULT_3"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_RESULT_4:
        return [
          t(
            "stat:RECEIVE_BLOCK_RESULT_4Short",
            "__RECEIVE_BLOCK_RESULT_4Short"
          ),
          t("stat:RECEIVE_BLOCK_RESULT_4", "__RECEIVE_BLOCK_RESULT_4"),
        ];
      case PLAYER_STAT.RECEIVE_BLOCK_RESULT_5:
        return [
          t(
            "stat:RECEIVE_BLOCK_RESULT_5Short",
            "__RECEIVE_BLOCK_RESULT_5Short"
          ),
          t("stat:RECEIVE_BLOCK_RESULT_5", "__RECEIVE_BLOCK_RESULT_5"),
        ];
      case PLAYER_STAT.RECEIVE_INJURY_CASUALTY:
        return [
          t(
            "stat:RECEIVE_INJURY_CASUALTYShort",
            "__RECEIVE_INJURY_CASUALTYShort"
          ),
          t("stat:RECEIVE_INJURY_CASUALTY", "__RECEIVE_INJURY_CASUALTY"),
        ];
      case PLAYER_STAT.RECEIVE_INJURY_KNOCKED_DOWN:
        return [
          t(
            "stat:RECEIVE_INJURY_KNOCKED_DOWNShort",
            "__RECEIVE_INJURY_KNOCKED_DOWNShort"
          ),
          t(
            "stat:RECEIVE_INJURY_KNOCKED_DOWN",
            "__RECEIVE_INJURY_KNOCKED_DOWN"
          ),
        ];
      case PLAYER_STAT.RECEIVE_INJURY_KO:
        return [
          t("stat:RECEIVE_INJURY_KOShort", "__RECEIVE_INJURY_KOShort"),
          t("stat:RECEIVE_INJURY_KO", "__RECEIVE_INJURY_KO"),
        ];
      case PLAYER_STAT.STANDUP:
        return [
          t("stat:STANDUPShort", "__STANDUPShort"),
          t("stat:STANDUP", "__STANDUP"),
        ];
      case PLAYER_STAT.JUMP_SUCCESS:
        return [
          t("stat:JUMP_SUCCESSShort", "__JUMP_SUCCESSShort"),
          t("stat:JUMP_SUCCESS", "__JUMP_SUCCESS"),
        ];
      case PLAYER_STAT.JUMP_FAIL:
        return [
          t("stat:JUMP_FAILShort", "__JUMP_FAILShort"),
          t("stat:JUMP_FAIL", "__JUMP_FAIL"),
        ];
      case PLAYER_STAT.TAKE_BALL_FAIL:
        return [
          t("stat:TAKE_BALL_FAILShort", "__TAKE_BALL_FAILShort"),
          t("stat:TAKE_BALL_FAIL", "__TAKE_BALL_FAIL"),
        ];
      case PLAYER_STAT.TAKE_BALL_REBOND_FAIL:
        return [
          t("stat:TAKE_BALL_REBOND_FAILShort", "__TAKE_BALL_REBOND_FAILShort"),
          t("stat:TAKE_BALL_REBOND_FAIL", "__TAKE_BALL_REBOND_FAIL"),
        ];
      case PLAYER_STAT.TAKE_BALL_REBOND_SUCCESS:
        return [
          t(
            "stat:TAKE_BALL_REBOND_SUCCESSShort",
            "__TAKE_BALL_REBOND_SUCCESSShort"
          ),
          t("stat:TAKE_BALL_REBOND_SUCCESS", "__TAKE_BALL_REBOND_SUCCESS"),
        ];
      case PLAYER_STAT.TAKE_BALL_SUCCESS:
        return [
          t("stat:TAKE_BALL_SUCCESSShort", "__TAKE_BALL_SUCCESSShort"),
          t("stat:TAKE_BALL_SUCCESS", "__TAKE_BALL_SUCCESS"),
        ];
      case PLAYER_STAT.TD:
        return [t("stat:TDShort", "__TDShort"), t("stat:TD", "__TD")];
      case PLAYER_STAT.TURNOVER:
        return [
          t("stat:TURNOVERShort", "__TURNOVERShort"),
          t("stat:TURNOVER", "__TURNOVER"),
        ];

      case PLAYER_STAT.CASUALTY_BADLY_HURT:
        return [
          t("stat:CASUALTY_BADLY_HURTShort", "__TDShort"),
          t("stat:CASUALTY_BADLY_HURT", "__CASUALTY_BADLY_HURT"),
        ];

      case PLAYER_STAT.CASUALTY_DEAD:
        return [
          t("stat:CASUALTY_DEADShort", "__CASUALTY_DEADShort"),
          t("stat:CASUALTY_DEAD", "__CASUALTY_DEAD"),
        ];

      case PLAYER_STAT.CASUALTY_LASTING_INJURY:
        return [
          t(
            "stat:CASUALTY_LASTING_INJURYShort",
            "__CASUALTY_LASTING_INJURYShort"
          ),
          t("stat:CASUALTY_LASTING_INJURY", "__CASUALTY_LASTING_INJURY"),
        ];

      case PLAYER_STAT.CASUALTY_RECEIVE_BADLY_HURT:
        return [
          t(
            "stat:CASUALTY_RECEIVE_BADLY_HURTShort",
            "__CASUALTY_RECEIVE_BADLY_HURTShort"
          ),
          t(
            "stat:CASUALTY_RECEIVE_BADLY_HURT",
            "__CASUALTY_RECEIVE_BADLY_HURT"
          ),
        ];

      case PLAYER_STAT.CASUALTY_RECEIVE_DEAD:
        return [
          t("stat:CASUALTY_RECEIVE_DEADShort", "__CASUALTY_RECEIVE_DEADShort"),
          t("stat:CASUALTY_RECEIVE_DEAD", "__CASUALTY_RECEIVE_DEAD"),
        ];

      case PLAYER_STAT.CASUALTY_RECEIVE_LASTING_INJURY:
        return [
          t(
            "stat:CASUALTY_RECEIVE_LASTING_INJURYShort",
            "__CASUALTY_RECEIVE_LASTING_INJURYShort"
          ),
          t(
            "stat:CASUALTY_RECEIVE_LASTING_INJURY",
            "__CASUALTY_RECEIVE_LASTING_INJURY"
          ),
        ];

      case PLAYER_STAT.CASUALTY_RECEIVE_SERIOUS_INJURY:
        return [
          t(
            "stat:CASUALTY_RECEIVE_SERIOUS_INJURYShort",
            "__CASUALTY_RECEIVE_SERIOUS_INJURYShort"
          ),
          t(
            "stat:CASUALTY_RECEIVE_SERIOUS_INJURY",
            "__CASUALTY_RECEIVE_SERIOUS_INJURY"
          ),
        ];

      case PLAYER_STAT.CASUALTY_RECEIVE_SERIOUSLY_HURT:
        return [
          t(
            "stat:CASUALTY_RECEIVE_SERIOUSLY_HURTShort",
            "__CASUALTY_RECEIVE_SERIOUSLY_HURTShort"
          ),
          t(
            "stat:CASUALTY_RECEIVE_SERIOUSLY_HURT",
            "__CASUALTY_RECEIVE_SERIOUSLY_HURT"
          ),
        ];

      case PLAYER_STAT.CASUALTY_SERIOUS_INJURY:
        return [
          t(
            "stat:CASUALTY_SERIOUS_INJURYShort",
            "__CASUALTY_SERIOUS_INJURYShort"
          ),
          t("stat:CASUALTY_SERIOUS_INJURY", "__CASUALTY_SERIOUS_INJURY"),
        ];

      case PLAYER_STAT.CASUALTY_SERIOUSLY_HURT:
        return [
          t(
            "stat:CASUALTY_SERIOUSLY_HURTShort",
            "__CASUALTY_SERIOUSLY_HURTShort"
          ),
          t("stat:CASUALTY_SERIOUSLY_HURT", "__CASUALTY_SERIOUSLY_HURT"),
        ];

      case PLAYER_STAT.REROLL:
        return [
          t("stat:REROLLShort", "__REROLLShort"),
          t("stat:REROLL", "REROLL"),
        ];
      default:
        break;
    }
  }
  const [short, full] = getStatsText();

  return (
    <CustomTooltip placement="top" title={full}>
      <span>{short}</span>
    </CustomTooltip>
  );
});

export default StatHeader;
