import React from "react";
import styled from "styled-components";
import Copyright from "./Copyright";
import Discord from "./Discord";
import Facebook from "./Facebook";

const FooterWrapper = styled.div`
  align-items: center;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  a {
    color: white;
    margin: 0 0.5em;
  }

  img {
    width: 20px;
    height: 20px;
  }

  & > *:first-child {
    margin-right: 1em;
  }
`;
const NoWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
`;

const SmallFooter = () => {
  return (
    <FooterWrapper>
      <Copyright />
      <NoWrap>
        <Facebook />
        <Discord />
      </NoWrap>
    </FooterWrapper>
  );
};
export default SmallFooter;
